import {pdfAPI} from './axios';
const FileDownload = require('js-file-download');
export const pdfCreate = (url, params) => new Promise((resolve, reject) => {
    pdfAPI({
        url: url,
        method: 'POST',
        data: {
           ...params
        },
        responseType: 'arraybuffer',
    }).then(async response => {
        resolve(response)
    }).catch(error => {
        reject(error);
    });
});
