import * as React from 'react';
import { Formik, Form, Field} from 'formik';
import TextField from '@material-ui/core/TextField';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
    },
    submitButton: {
        margin: theme.spacing(1),
    },
    selectLabel: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top left'
    },
    select: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),

    }
}));


const Schema = Yup.object().shape({
    internalOrderId: Yup.string().required('Required'),
});

export default function FactoryForm(props) {
    const classes = useStyles();

    const handleUpdate = (field, value) => {
        props.handleAdditionalOrderDataChange({
           [field] : value
        });
    };

    React.useEffect(() => {
        document.addEventListener("keydown", function (event) {

            if (event.keyCode === 9 && event.target.nodeName === "INPUT") {
                var form = event.target.form;
                var index = Array.prototype.indexOf.call(form, event.target);
                form.elements[index + 2].focus();
                event.preventDefault();
            }
        });
    }, []);
    return (
        <Formik
            enableReinitialize
            initialValues={{
                internalOrderId: props.additionalOrderData.internalOrderId
            }}
            validationSchema={Schema}

        >
            {({ values, handleChange, setFieldValue, setTouched, errors, submitForm, isSubmitting }) => (
                <Form className={classes.root} autoComplete="off">
                    <Grid container spacing={1}>
                        <Grid container item xs={12}>
                            <Field
                                component={TextField}
                                id="internalOrderId"
                                name="internalOrderId"
                                type="text"
                                value={values.internalOrderId}
                                label="Job ID"
                                variant="outlined"
                                fullWidth
                                onChange={e => {
                                    handleChange(e);
                                    handleUpdate("internalOrderId", e.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}