import {knackAPI} from './axios';

export const findRecord = (objectKey, filters) => new Promise((resolve, reject) => {
    knackAPI.get(`/objects/${objectKey}/records?filters=${encodeURIComponent(JSON.stringify(filters))}`).then(async response => {
        // console.log(response);
        resolve(response)
    }).catch(error => {
        reject(error);
    });
});

export const saveRecord = (objectKey, objectId, data) => new Promise((resolve, reject) => {
    // console.log(data)
    knackAPI.put(`/objects/${objectKey}/records/${objectId}`, data).then(async response => {
        // console.log(response);
        resolve(response)
    }).catch(error => {
        reject(error);
    });
});
