import React from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import OrderView from "./views/orderView";
import {createTheme, makeStyles} from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import Error404 from "./views/Error404";
import PrivateRoute from "./components/PrivateRoute";
import { MsalProvider } from "@azure/msal-react";
import HomePage from "./views/home";
const theme = createTheme({
    palette: {
        primary: {
            main: '#141414',
        },
        secondary: {
            main: '#dc0000',
        },
    }
});

const useStyles = makeStyles((theme) => ({
    snackbarSuccess: {
        backgroundColor: '#141414'
    }
}));

function App(props) {
  const classes = useStyles();
  return (
      <MsalProvider instance={props.pca}>
          <React.Fragment>
              <ThemeProvider theme={theme}>
                  <SnackbarProvider
                      anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                      }}
                      maxSnack={1}
                      classes={{
                          variantSuccess: classes.snackbarSuccess
                      }}
                  >
                      <Router>
                          <Switch>
                              <PrivateRoute exact path="/order/:id">
                                  <OrderView/>
                              </PrivateRoute>
                              <PrivateRoute exact path="/">
                                  <HomePage />
                              </PrivateRoute>
                              <Route path="/404" render={() => <Error404 errorName={'404 ERROR'}/>}  />
                              <Route path="*" render={() => <Error404 errorName={'404 ERROR'}/>}  />
                          </Switch>
                      </Router>
                  </SnackbarProvider>
              </ThemeProvider>
          </React.Fragment>
      </MsalProvider>
  );
}

export default App;
