import React from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {CircularProgress, makeStyles} from '@material-ui/core';
import {jsx} from '@emotion/react';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: 'white',
    },
}));

export default function LoadingPage() {
    const classes = useStyles();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (

        <div className={classes.root}>
            <CircularProgress
                thickness={5}
                size={isMobile ? 75 : 100}
                disableShrink
            />
        </div>
    );
};