import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import SelectField from "./selectField";
import Grid from "@material-ui/core/Grid";
import {Form} from "formik";
import {FormControlLabel} from "@material-ui/core";
import Switch from "@material-ui/core/Switch/Switch";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    }

}));

const config = {
  positionOptions: [
      'Equal',
      'Custom'
  ]
};
export default function PanelWidthFields(props) {
    const classes = useStyles();
    const [newPanelWidth, setNewPanelWidth] = React.useState([]);
    const [errorList, setErrorList] = React.useState([]);
    const handleMetaChange = (name, value) =>{
        if(typeof props.handleMetaChange !== "undefined"){
            props.handleMetaChange(name, value);
        }
    };
    const handlePositionOptionChange = (event) => {
        props.onChange("panelWidthOption", event.target.checked? 'Custom': 'Equal')
    };
    React.useEffect(() => {
        if(props.configuration.split('').filter(c => c === 'T').length === 0){
            props.onChange("panelWidthOption", 'Equal')
        }
    }, [props.configuration]);
    const handlePanelWidthChange = (event) => {
        if(!event.target.value || event.target.value === ""){
            setErrorList([...errorList.filter(n => n.name !== event.target.name), {name: event.target.name, message: 'Required'}])
        }
        else {
            setErrorList([...errorList.filter(n => n.name !== event.target.name)])
        }
        setNewPanelWidth([...newPanelWidth.filter(n => n.name !== event.target.name), {name: event.target.name, value:  event.target.value}])
    };

    React.useEffect(() => {
        if(props.panelWidth) {
            setNewPanelWidth(props.panelWidth.split(',').map((pw, index) => ({name: `T${index + 1}`, value: pw})))
        }
        else {
            setNewPanelWidth([])
        }
    }, [props.panelWidth]);

    React.useEffect(() => {
        if(props.panelWidthOption === 'Equal' && props.drawing && props.drawing.meta.hasOwnProperty('customWidthPosition') && props.drawing.meta.customWidthPosition !== ""){
            setNewPanelWidth(props.drawing.meta.customWidthPosition.split(',').map((pw, index) => ({name: `T${index + 1}`, value: pw})))
        }
    }, [props.drawing]);

    React.useEffect(() => {
        let n = newPanelWidth.sort((a, b) => (a.name > b.name) ? 1 : -1).map(pw => pw.value).join(',');
        if(n !== props.panelWidth){
            props.onChange("panelWidth", n)
        }
    }, [newPanelWidth]);

    React.useEffect(() => {
        //console.log(props.panelWidthOption)
        if(props.panelWidthOption) {
            setErrorList([])
        }
        if (props.panelWidthOption === 'Equal') {
            setNewPanelWidth([])
        }

    }, [props.panelWidthOption]);

    return (
        <Grid container spacing={0}>
            <Grid container item xs={12}>
                <FormControlLabel
                    name="panel-width-option"
                    value="bottom"
                    control={<Switch color="primary" checked={props.panelWidthOption !== 'Equal'} onChange={handlePositionOptionChange} disabled={props.disabled? true : (props.configuration.split('').filter(c => c === 'T').length === 0)}/>}
                    label={<Typography className={classes.formControlLabel}>Custom Panel Width</Typography>}
                    labelPlacement="start"
                />
            </Grid>
            {props.panelWidthOption !== 'Equal' && props.configuration.split('').filter(c => c === 'T').length > 0?
                <React.Fragment>
                    {[...Array(props.configuration.split('').filter(c => c === 'T').length).keys()].map(n =>
                        <Grid key={`panel-width-grid-${n}`} container item xs={12}>
                            <TextField
                                key={`panel-pos-${n}`}
                                id={`panel-pos-${n}`}
                                label={`T${n+1}`}
                                variant="outlined"
                                type="number"
                                name={`T${n+1}`}
                                onFocus={() => handleMetaChange(`T${n+1}`, true)}
                                onBlur={() => handleMetaChange(`T${n+1}`, false)}
                                error={errorList.filter(pw => pw.name === `T${n+1}`).length > 0}
                                value={newPanelWidth.filter(pw => pw.name === `T${n+1}`).length > 0 ? newPanelWidth.filter(pw => pw.name === `T${n+1}`)[0].value : ""}
                                helperText={errorList.filter(pw => pw.name === `T${n+1}`).length > 0 ? "Required" : ""}
                                fullWidth
                                disabled={props.disabled}
                                InputProps={{ inputProps: { min: 0, max: 6000 } }}
                                onChange={handlePanelWidthChange}
                            />
                        </Grid>
                    )}
                </React.Fragment>
            :null}
        </Grid>
    )
}
