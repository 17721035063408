class SVGElement{
    constructor(){
        this.highlightColour = 'rgb(239 255 136 / 87%)';
        this.highlightClass = 'highlight'
    }
    draw(id, element){
        switch(element.type) {
            case 'line':
                return (this.createLineElement(id, element));
            case 'rect':
                return (this.createRectElement(id, element));
            case 'ellipse':
                return (this.createEllipseElement(id, element));
            case 'circle':
                return (this.createCircleElement(id, element));
            case 'path':
                return (this.createPathElement(id, element));
            case 'text':
                return (this.createTextElement(id, element));
            default:
                return ''
        }
    }
    drawShape(id, element, shape){
        switch(shape) {
            case 'line':
                return (this.createLineElement(id, element));
            case 'rect':
                return (this.createRectElement(id, element));
            case 'ellipse':
                return (this.createEllipseElement(id, element));
            case 'circle':
                return (this.createCircleElement(id, element));
            case 'path':
                return (this.createPathElement(id, element));
            case 'text':
                return (this.createTextElement(id, element));
            default:
                return ''
        }
    }
    getOption(element, key){
        if(element.hasOwnProperty('options') && element.options.hasOwnProperty(key)){
            return element.options[key]
        }
        return null;
    }
    createLineElement(id, line) {
        let element = document.createElementNS("http://www.w3.org/2000/svg", "line");
        element.setAttribute('id', `line-${id}`);
        element.setAttribute('x1', line.X1);
        element.setAttribute('y1', line.Y1);
        element.setAttribute('x2', line.X2);
        element.setAttribute('y2', line.Y2);
        element.setAttribute('opacity', 'undefined');
        element.setAttribute('fill-opacity', 'null');
        element.setAttribute('stroke-opacity', 'null');
        element.setAttribute('stroke-dasharray', this.getOption(line, 'strokeDasharray')? this.getOption(line, 'strokeDasharray').toString() : 'null');
        element.setAttribute('stroke-width', this.getOption(line, 'strokeWidth')? this.getOption(line, 'strokeWidth').toString() : 'null');
        element.setAttribute('fill', '#fff');
        element.setAttribute('stroke', this.getOption(line, 'stroke')? this.getOption(line, 'stroke').toString() : '#000');
        if(this.getOption(line, 'transform')){
            element.setAttribute('transform', `${this.getOption(line, 'transform')}`);
        }
        if(this.getOption(line, 'transformOrigin')){
            element.setAttribute('transform-origin', `${this.getOption(line, 'transformOrigin')}`);
        }
        return element;
    }
    createTextElement(id, text) {
        let element = document.createElementNS("http://www.w3.org/2000/svg", "text");
        element.setAttribute('id', `text-${id}`);
        element.setAttribute('x', text.X);
        element.setAttribute('y', text.Y);
        element.setAttribute('class', text.options.cls);
        if(this.getOption(text, 'transform')){
            element.setAttribute('transform', `${this.getOption(text, 'transform')}`);
        }
        element.innerHTML = text.text;
        return element;
    }
    createRectElement( id, rect) {
        let element = document.createElementNS("http://www.w3.org/2000/svg", "rect");
        element.setAttribute('id', `rect-${id}`);
        element.setAttribute('x', rect.X);
        element.setAttribute('y', rect.Y);
        element.setAttribute('width', rect.W);
        element.setAttribute('height', rect.H);
        element.setAttribute('opacity', 'undefined');
        element.setAttribute('fill-opacity', this.getOption(rect, 'highlight')? "1" :'null');
        element.setAttribute('stroke-opacity', 'null');
        element.setAttribute('stroke-dasharray', 'null');
        element.setAttribute('stroke-width', rect.hasOwnProperty('strokeWidth')? rect.strokeWidth :'null');
        element.setAttribute('fill', this.getOption(rect, 'fill') ? this.getOption(rect, 'highlight')? 'url(#diagonalHatchHighlight)' : 'url(#diagonalHatch)': "transparent");
        element.setAttribute("class", this.getOption(rect, 'highlight')? `${this.highlightClass}_rect` : "");
        element.setAttribute('stroke', this.getOption(rect, 'noStroke') ? null: '#000');
        return element
    }
    createEllipseElement( id, ellipse) {
        let element = document.createElementNS("http://www.w3.org/2000/svg", "ellipse");
        element.setAttribute('id', `ellipse-${id}`);
        element.setAttribute('cx', ellipse.CX);
        element.setAttribute('cy', ellipse.CY);
        element.setAttribute('rx', ellipse.RX);
        element.setAttribute('ry', ellipse.RY);
        element.setAttribute('style', `fill:transparent;stroke:#000`);
        element.setAttribute('transform', `rotate(5,${ellipse.CX},${ellipse.CY})`);
        element.setAttribute('fill', "transparent");
        element.setAttribute('fill-opacity', '0.1');
        // element.setAttribute('stroke-opacity', 'null');
        // element.setAttribute('stroke-dasharray', 'null');
        // element.setAttribute('stroke-width', 'null');
        // element.setAttribute('stroke', '#000');
        return element
    }
    createCircleElement( id, circle) {
        let element = document.createElementNS("http://www.w3.org/2000/svg", "circle");
        element.setAttribute('id', `circle-${id}`);
        element.setAttribute('cx', circle.CX);
        element.setAttribute('cy', circle.CY);
        element.setAttribute('r', circle.R);
        element.setAttribute('style', `fill:white;stroke:#000`);
        // element.setAttribute('fill-opacity', 'null');
        // element.setAttribute('stroke-opacity', 'null');
        // element.setAttribute('stroke-dasharray', 'null');
        element.setAttribute('stroke-width', 1);
        element.setAttribute('stroke', '#000');
        return element
    }
    createPathElement( id, path) {
        let d = '';
        for(const element of path.path){
            for(const [key, value] of Object.entries(element)) {
                if (key === 'm') {
                    d += `${key} ${value.X} ${value.Y} `;
                } else if( ['h','v'].includes(key)) {
                    d += `${key} ${value} `;
                }
            }
        }
        let element = document.createElementNS("http://www.w3.org/2000/svg", "path");
        element.setAttribute('id', `path-${id}`);
        element.setAttribute('d', d);
        element.setAttribute('opacity', 'undefined');
        element.setAttribute('fill-opacity', 'null');
        element.setAttribute('stroke-opacity', 'null');
        element.setAttribute('stroke-dasharray', 'null');
        element.setAttribute('stroke-width', this.getOption(path,'strokeWidth')? this.getOption(path,'strokeWidth'): 'null');
        element.setAttribute('fill', '#fff');
        element.setAttribute('stroke', '#000');
        if(this.getOption(path, 'fill')){
            element.setAttribute('fill',  this.getOption(path, 'highlight')? 'url(#diagonalHatchHighlight)' : 'url(#diagonalHatch)');
        }
        else {
            element.setAttribute('fill', this.getOption(path, 'highlight')? `#ffee33`: "transparent");
        }
        return element
    }

}
export {
    SVGElement
}