import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {withStyles} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import GetAppIcon from '@material-ui/icons/GetApp';
import FactoryForm from "../factoryForm";

const StyledMenuItem = withStyles((theme) => ({
    root: {
    },
}))(MenuItem);

export default function DownloadFactoryDrawings(props) {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleDownload = () => {
       handleClose();
       props.handleButtonClick(props.buttonName);
    };
    React.useEffect(() => {
        props.handleClose();
    }, [open]);

    return (
        <div>
            <StyledMenuItem onClick={handleClickOpen}>
                {props.buttonLabel}
            </StyledMenuItem>
            <Dialog
                open={open}
                scroll="body"
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{props.buttonLabel}</DialogTitle>
                <DialogContent>
                   <FactoryForm {...props} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDownload}
                        color="primary"
                        variant="contained"
                        startIcon={<GetAppIcon/>}
                        disabled={!props.additionalOrderData.internalOrderId || props.additionalOrderData.internalOrderId === ""}
                    >
                        Download
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
