
export const svgCrop = (svg, drawing) => {
    svg.removeAttribute('viewBox');
    let top = drawing.measurements.top.sort((a, b) => (a.Y1 > b.Y1) ? 1 : -1)[0].Y1 - 75;
    let bottom = drawing.views.frontView.outerRect.Y + drawing.views.frontView.outerRect.H + 75;
    let left = drawing.measurements.side.sort((a, b) => (a.X1 > b.X1) ? 1 : -1)[0].X1 - 50;
    let right = drawing.measurements.side.sort((a, b) => (a.X1 > b.X1) ? -1 : 1)[0].X1 + 50;
    //console.log(right)
    // svg.setAttribute('width', 3000);
    // svg.setAttribute('height', 3000);
    svg.setAttribute('width', '100%');
    svg.setAttribute('preserveAspectRatio', 'xMidYMid meet');
    svg.setAttribute('viewBox', `${(left/2).toFixed(2)} ${top.toFixed(2)} ${(right).toFixed(2)} ${(bottom).toFixed(2)}`);
    return svg;
};