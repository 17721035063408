import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import { useHistory } from "react-router-dom";


const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            // width: '25ch',
        },
    },
    card: {
        minWidth: 100,
        marginTop: theme.spacing(20),
        padding: theme.spacing(2),
        textAlign: 'center'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
    },
    cardAction: {
        textAlign: 'center',
        justifyContent: 'center'
    },
    logoDiv: {
        marginBottom: theme.spacing(2),
    }
}));

export default function HomePage(props) {
    const classes = useStyles();
    const history = useHistory();
    const [value, setValue] = React.useState(null);
    const [error, setError] = React.useState(false);
    const handleChange = (e) => {
        setValue(e.target.value);
        setError(false);
    };
    const handleClick = () => {
        if(value){
            history.push(`/order/${value}`);
        }
        else {
            setError(true);
        }

    };
    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="sm">
                <Card className={classes.card}>
                    <CardContent >
                        <div className={classes.logoDiv}>
                            <img src="/logo-1.png" alt="logo" width="100"/>
                        </div>
                        <TextField
                            fullwidth
                            id="outlined-number"
                            label="Order Number"
                            type="number"
                            variant="outlined"
                            error={error}
                            helperText={error? 'Required' : null}
                            onChange={handleChange}
                        />
                    </CardContent>
                    <CardActions className={classes.cardAction}>
                        <Button size="large" variant="contained" color="primary" onClick={handleClick}>GO</Button>
                    </CardActions>
                </Card>
            </Container>
        </React.Fragment>
    );
}