import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {withStyles} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import EditForm from "../editForm";
import {ShutterConfig, fetchSupplierConfig} from "../config";

const StyledMenuItem = withStyles((theme) => ({
    root: {
    },
}))(MenuItem);

export default function AddShutterDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [shutter, setShutter] = React.useState(null);
    const [supplierConfig, setSupplierConfig] = React.useState(null);
    const handleClickOpen = () => {
        props.handleMenuClose();
        setOpen(true);
    };

    React.useEffect(() => {
        if(open) {
            fetchSupplierConfig(props.order.product).then((out) => setSupplierConfig(out))
        }
    },[open]);

    React.useEffect(() => {
        if(open && supplierConfig) {
            setShutter({
                name: "",
                width: "",
                height: "",
                colour: "",
                hingeColour: "",
                frameStyle: "",
                midrails: 0,
                configuration: "",
                midrailPositions: "Evenly Spaced",
                panelWidthOption: "Equal",
                panelWidth: "",
                standardDeduction: true,
                splitTiltRod: false,
                customFrameStyle: {
                  top: "",
                  bottom: "",
                  left: "",
                  right: ""
                },
                splitTiltRodZones: {
                    Z1: false,
                    Z2: false
                },
                notes: '',
                config: {...ShutterConfig, ...supplierConfig},
            });
        }
    }, [open, supplierConfig]);

    const handleClose = (even, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            setOpen(false);
        }
    };

    const handleCurrentShutterChange = (s) => {
        setShutter(s)
    };

    const handleSubmit = (e) => {
        let ids = props.shutters.map(s => s._id).sort((a, b) => (a > b) ? -1 : 1);
        let id = ids[0];
        props.handleAddShutter({
            ...shutter,
            _id: id+1
        });
        setOpen(false);
    };

    return (
        <div>
            <StyledMenuItem onClick={handleClickOpen}>
                <AddCircleOutlineIcon color="primary" />
                &nbsp; Add Shutter
            </StyledMenuItem>
            <Dialog
                open={open && supplierConfig}
                scroll="body"
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Add Shutter</DialogTitle>
                <DialogContent>
                    {shutter && <EditForm editMode={true} formType="Add" shutter={shutter} drawing={null} handleCurrentShutterChange={handleCurrentShutterChange}/>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary" variant="contained">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
