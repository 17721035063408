import {FormControl , FormHelperText, FormControlLabel, makeStyles} from "@material-ui/core";
import Switch from "@material-ui/core/Switch/Switch";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {Field} from "formik";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    }

}));

export default function SwitchField(props) {
    const classes = useStyles();
    const handleChange = (e) => {
      props.onChange(e);
    };
    return (
        <FormControl>
        <FormControlLabel
            name="panel-width-option"
            value="bottom"
            control={<Switch disabled={props.disabled} color="primary" name={props.name} checked={props.value} onChange={handleChange}/>}
            label={<Typography className={classes.formControlLabel}>{props.label}</Typography>}
            labelPlacement="start"
        />
        <FormHelperText variant="filled">{props.helperText}</FormHelperText>
        </FormControl>
    )
}
