//frame sill frame or none
//Any frame none or sill

import {findRecord} from "../api/orders";

export const ShutterConfig = {
    "zoomVariable": 1,
    "canvasWidthExtension": 1000,
    "canvasHeightExtension": 700
};

export const defaultConfig = {
    material: 'PVC',
    tiltRod: 'Hidden',
    frame: {
        "Z Frame": {
            lip: 18.8,
            standardDeduction: 1.6,
            frameWidth: 40.8,
            frameBottomCut: 0,

        },
        "Z Frame With Sill": {
            lip:18.8,
            standardDeduction: 1.6,
            frameWidth: 40.8,
            frameBottomCut: 0
        },
        "Sill Frame": {
            lip:0,
            standardDeduction: 1.6,
            frameWidth: 10,
            frameBottomCut: 18.8,
        },
        "No Frame": {
            lip:0,
            standardDeduction: 1.6,
            frameWidth: 0,
            frameBottomCut: 0,
            panelTopBottomGap: 2.4,
            panelLeftRightGap: 0.9,
        },
        "SMALL L": {
            lip: 0,
            standardDeduction: 1.6,
            frameWidth: 22,
            frameBottomCut: 0,

        },
        "Inside L Frame":{
            lip: 0,
            standardDeduction: 1.6,
            frameWidth: 22,
            frameBottomCut: 0
        },
        "Outside L Frame":{
            lip: 0,
            standardDeduction: 0,
            frameWidth: 22,
            frameBottomCut: 0
        }
    },
    frameStyles: [
        {
            name: 'Z Frame',
            factoryName: 'SMALL Z',
            width: 56.8,
            thickness: 50.8,
            cutListLip: 37.2,
            selectable: true
        },
        {
            name: 'Sill Frame',
            factoryName: 'Sill',
            width: 19,
            thickness: 50.8,
            cutListLip: 0,
            selectable: false
        },
        {
            name: 'SMALL L',
            factoryName: 'SMALL L',
            width: 38,
            thickness: 50.8,
            cutListLip: 0,
            selectable: true
        },
        {
            name: 'Inside L Frame',
            factoryName: 'Inside L Frame',
            width: 38,
            thickness: 50.8,
            cutListLip: 0,
            selectable: true
        },
        {
            name: 'Outside L Frame',
            factoryName: 'Outside L Frame',
            width: 38,
            thickness: 36.5,
            cutListLip: 0,
            selectable: true
        },
        {
            name: 'No Frame',
            factoryName: 'No Frame',
            width: 0,
            thickness: 0,
            cutListLip: 0,
            selectable: true
        }
    ],
    hiddenStile: {
        width: 50.8,
        thickness: 27
    },
    rails: {
        thickness: 20,
        mid: {
            width: 85.5
        }
    },
    louvers: {
        width: 89,
        thickness: 12
    },
    tpost: {
        width: 76.5,
        thickness: 20
    },
    colours: [
        {
            name: 'Snow',
            factoryName: 'Bright White'
        },
        {
            name: 'Bright',
            factoryName: 'Snow'
        },
    ],
    hingeColours: [
        'To Match',
        'Stainless Steel'
    ],
    configuration: [
        'L',
        'R',
        'L-DR',
        'L-DRTR',
        'LTL-DR',
        'LTLTR',
        'LTRTR',
        'LTLTL',
        'RTRTR',
        'LTLTRTR',
        'LTL-DRTR',
        'L-DRTL-DR',
        'L-DRTL-DRTL',
        'L-DRTL-DRTR',
        'LTL-DRTL-DR',
        'RTL-DRTL-DR',
        'L-DRTL-DRTL-DR',
        'LTL-DRTL-DRTR'
    ],
    customFrameOptions: [
        {
            name: 'None',
            value: 'No Frame'
        },
        {
            name: 'Sill Frame',
            value: 'Sill Frame'
        },
    ],
    customFrameDefaultOption: 'Yes',
    sideViewWidth: 20,
    sideViewRelativePosition: 250,
    topViewWidth: 20,
    topViewRelativePosition: 300,
    topViewStileWidthExtension: 2,
    topViewDMoldWidth: 6,
    stileWidth: 51.55,
    tPostLip: 10,
    topRailMinWidth: 85,
    topRailMaxWidth: 148,
    bottomRailMinWidth: 85,
    bottomRailMaxWidth: 148,
    topRailLip: 8.5,
    bottomRailLip: 8.5,
    midRailWidth: 76.2,
    midRailLip: 8.5,
    panelTopBottomGap: 4,
    panelLeftRightGap: 3,
    tPostWidth: 30,
    louverWidth: 89,
    louverDistance: 76.2,
    topHingeDistance: 76.2,
    bottomHingeDistance: 76.2,
    hingeLength: 76,
    maxHingeDistance: 635,
    controlScrewRadius: 10,
    tiltRodDistance: 70,
    tiltRodOverlap: 20
};

let defaultOverrideFunctions = {
    hingeDistanceOverrideFunction: (railWidth) => {
        return 76.2
    }
    //change this to return topHingeDistance/bottomHingeDistance if there is no override
};


export const fetchSupplierConfig = (product_id) => {
    return findRecord('object_51', {"match":"and","rules":[{"field":"field_1301","operator":"is","value": product_id}]}).then((response) => {
        return JSON.parse(response.data.records[0].field_1299_raw)
    }).catch((e) => {
        console.log("Error in supplier config, returning default config")
        console.log(e);
        return {
            ...defaultConfig,
            name: "DefaultSupplier"
        }
    });
}

export const fetchSupplierConfigList = () => {
    return findRecord('object_51', {"match":"and","rules":[{"field":"field_1301","operator":"is not blank"},{"field":"field_1299","operator":"is not blank"}]}).then((response) => {
        return [...response.data.records.map(x => [x.field_767, x.field_1301])]
    }).catch((e) => {
        console.log(e);
    });
}
export const supplierOverrides = (supplier) => {
  let suppliers = {
      viewscape: {
          ...defaultOverrideFunctions,
          hingeDistanceOverrideFunction: (railWidth) => {
              if(railWidth <= 132){
                  return 85;
              }
              else {
                  return 110;
              }
          }
      },
      jht: {
          ...defaultOverrideFunctions
      }
  };

  if(!suppliers.hasOwnProperty(supplier)){
      return {...defaultOverrideFunctions}
  }
  return suppliers[supplier]
};