import * as React from 'react';
import { Formik, Form, Field, useFormikContext} from 'formik';
import { TextField, Switch } from 'formik-material-ui';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import SelectField from "./order/form/selectField";
import MidRailFields from "./order/form/midRail";
import PanelWidthFields from "./order/form/panelWidth";
import SwitchField from "./order/form/switchField";
import TiltRodSystem from "./order/form/tiltRodSystem";
import Typography from '@material-ui/core/Typography';
import FrameStyleField from "./order/form/frameStyleField";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
    },
    submitButton: {
        margin: theme.spacing(1),
    },
    selectLabel: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top left'
    },
    select: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),

    }
}));


const Schema = Yup.object().shape({
    name: Yup.string().required('Required'),
    width: Yup.number().positive().required('Required'),
    height: Yup.number().positive().required('Required'),
    frameStyle: Yup.string().required('Required'),
    midrails: Yup.number().nullable(),
    configuration: Yup.string().required('Required'),
    midrailPositions: Yup.object().nullable(),
    panelWidthOption: Yup.string().nullable(),
    panelWidth: Yup.string().nullable(),
    standardDeduction: Yup.boolean().nullable(),
    splitTiltRod: Yup.boolean().nullable(),
    splitTiltRodZones: Yup.object().nullable(),
    notes: Yup.string().nullable(),
});
const config = {

    panelWidthOption: [
         null,
        'Equal',
        'Custom'
    ]
};
export default function EditForm(props) {
    const classes = useStyles();

    const handleUpdate = (field, value) => {
        props.handleCurrentShutterChange({
           ...props.shutter,
           [field] : value
        });
    };

    const handleUpdateBulk = (values) => {
        props.handleCurrentShutterChange({
            ...props.shutter,
            ...values
        });
    };

    React.useEffect(() => {
        document.addEventListener("keydown", function (event) {

            if (event.keyCode === 9 && event.target.nodeName === "INPUT") {
                var form = event.target.form;
                var index = Array.prototype.indexOf.call(form, event.target);
                form.elements[index + 2].focus();
                event.preventDefault();
            }
        });
    }, []);

    return (
        <Formik
            enableReinitialize
            initialValues={{
                name: props.shutter.name,
                width: props.shutter.width,
                height: props.shutter.height,
                colour: props.shutter.colour,
                hingeColour: props.shutter.hingeColour,
                frameStyle: props.shutter.frameStyle,
                midrails: props.shutter.midrails,
                configuration: props.shutter.configuration,
                midrailPositions: props.shutter.midrailPositions,
                panelWidthOption: props.shutter.panelWidthOption,
                panelWidth: props.shutter.panelWidth,
                standardDeduction: props.shutter.standardDeduction,
                notes: props.shutter.notes,
                splitTiltRod: props.shutter.splitTiltRod,
                splitTiltRodZones: props.shutter.splitTiltRodZones,
            }}
            validationSchema={Schema}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
            }}
        >
            {({ values, handleChange, setFieldValue, setTouched, errors, submitForm, isSubmitting }) => (
                <Form className={classes.root} autoComplete="off">
                    {/*{errors.name ? (*/}
                    {/*    <div>{errors}</div>*/}
                    {/*) : null}*/}
                    <Grid container spacing={1}>
                        <Grid container item xs={12}>
                            <Field
                                component={TextField}
                                name="name"
                                type="text"
                                label="Name"
                                variant="outlined"
                                fullWidth
                                disabled={!props.editMode}
                                onChange={e => {
                                    handleChange(e);
                                    handleUpdate("name", e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid container item xs={6}>
                            <Field
                                component={TextField}
                                name="width"
                                type="number"
                                label="Width"
                                variant="outlined"
                                disabled={!props.editMode}
                                InputProps={{ inputProps: { min: 0, max: 6000 } }}
                                fullWidth
                                onChange={e => {
                                    handleChange(e);
                                    handleUpdate("width", parseFloat(e.target.value));
                                }}
                            />
                        </Grid>
                        <Grid container item xs={6}>
                            <Field
                                component={TextField}
                                name="height"
                                type="number"
                                label="Height"
                                variant="outlined"
                                fullWidth
                                disabled={!props.editMode}
                                InputProps={{ inputProps: { min: 0, max: 6000 } }}
                                onChange={e => {
                                    handleChange(e);
                                    handleUpdate("height", parseFloat(e.target.value));
                                }}
                            />
                        </Grid>

                        <Grid container item xs={12}>
                            <Field
                                component={SelectField}
                                id="colour"
                                name="colour"
                                label="Colour"
                                disabled={!props.editMode}
                                options={props.shutter.config.colours.map(c =>
                                    ({
                                        value: c.name,
                                        display: <Typography display="inline">{c.name}
                                        <Typography variant="caption" display="inline" style={{fontSize: '11px', color: 'grey'}}> {props.shutter.config.name}:{c.factoryName}</Typography>
                                        </Typography>
                                    })
                                )}
                                value={values.colour}
                                onChange={e => {
                                    handleChange(e);
                                    setFieldValue("colour", e.target.value);
                                    setTouched("colour");
                                    handleUpdate("colour", e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid container item xs={12}>
                            <Field
                                component={SelectField}
                                id="hingeColour"
                                name="hingeColour"
                                label="Hinge Colour"
                                disabled={!props.editMode}
                                options={props.shutter.config.hingeColours.map(c => ({value: c, display: c}))}
                                value={values.hingeColour}
                                onChange={e => {
                                    handleChange(e);
                                    setFieldValue("hingeColour", e.target.value);
                                    setTouched("hingeColour");
                                    handleUpdate("hingeColour", e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid container item xs={12}>
                            <Field
                                component={FrameStyleField}
                                id="frameStyle"
                                name="frameStyle"
                                label="Frame Style"
                                disabled={!props.editMode}
                                options={props.shutter.config.frameStyles.filter(f => f.selectable).map(f => ({value: f.name, display: f.name}))}
                                customFrameOptions={[{value: props.shutter.frameStyle, display: props.shutter.frameStyle}, ...props.shutter.config.customFrameOptions.map(o => ({value: o.value, display: o.name}))]}
                                frameStyleIsCustom={props.shutter.frameStyleIsCustom}
                                customFrameStyle={props.shutter.customFrameStyle}
                                value={values.frameStyle}
                                onChange={(fields, values) => {
                                    // eslint-disable-next-line array-callback-return
                                    fields.map(field => {
                                        setFieldValue(field, values[field], false);
                                        setTouched(field);
                                    });
                                    handleUpdateBulk(values);
                                }}
                            />
                        </Grid>
                        <Grid container item xs={12}>
                            <Field
                                component={SwitchField}
                                name="standardDeduction"
                                label="Standard Deduction"
                                disabled={!props.editMode}
                                value={values.standardDeduction}
                                helperText={props.shutter.frameStyle && props.shutter.frameStyle !== ""? <Typography variant="caption" paragraph={false}>{`${props.shutter.config.frame[props.shutter.frameStyle].standardDeduction * 2}mm`}</Typography>: null}
                                onChange={e => {
                                    handleChange(e);
                                    setFieldValue("standardDeduction", e.target.checked);
                                    setTouched("standardDeduction");
                                    handleUpdate("standardDeduction", e.target.checked);
                                }}
                            />
                        </Grid>
                        <Grid container item xs={12}>
                            <Field
                                component={SelectField}
                                name="configuration"
                                label="Configuration"
                                options={props.shutter.config.configuration.map(c => ({value: c, display: c}))}
                                value={values.configuration}
                                disabled={!props.editMode}
                                onChange={e => {
                                    // handleChange(e);
                                    setFieldValue("configuration", e.target.value);
                                    setTouched("configuration");
                                    handleUpdate("configuration", e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid container item xs={12}>
                            <Field
                                component={MidRailFields}
                                midrails={values.midrails}
                                midrailPositions={values.midrailPositions}
                                drawing={props.drawing}
                                height={values.height}
                                frameStyle={values.frameStyle}
                                config={props.shutter.config}
                                handleMetaChange={props.handleMetaChange}
                                standardDeduction={values.standardDeduction}
                                formType={props.formType}
                                disabled={!props.editMode}
                                onChange={(field, value) => {
                                    setFieldValue(field, value, false);
                                    setTouched(field);
                                    handleUpdate(field, value);
                                }}
                            />
                        </Grid>
                        <Grid container item xs={12}>
                            <Field
                                component={PanelWidthFields}
                                configuration={values.configuration}
                                drawing={props.drawing}
                                panelWidthOption={values.panelWidthOption}
                                handleMetaChange={props.handleMetaChange}
                                panelWidth={values.panelWidth}
                                disabled={!props.editMode}
                                onChange={(field, value) => {
                                    setFieldValue(field, value, false);
                                    setTouched(field);
                                    //console.log(field, value);
                                    handleUpdate(field, value);
                                }}
                            />
                        </Grid>
                        {props.formType !== 'Add' &&
                            <Grid container item xs={12}>
                                <Field
                                    component={TiltRodSystem}
                                    configuration={values.configuration}
                                    splitTiltRod={values.splitTiltRod}
                                    splitTiltRodZones={values.splitTiltRodZones}
                                    midrails={values.midrails || 0}
                                    drawing={props.drawing}
                                    handleMetaChangeBulk={props.handleMetaChangeBulk}
                                    disabled={!props.editMode}
                                    onChange={(field, value) => {
                                        setFieldValue(field, value, false);
                                        setTouched(field);
                                        //console.log(field, value);
                                        handleUpdate(field, value);
                                    }}
                                />
                            </Grid>
                        }
                        <Grid container item xs={12}>
                            <Field
                            component={TextField}
                            multiline
                            rows={3}
                            name="notes"
                            type="text"
                            label="Notes"
                            variant="outlined"
                            fullWidth
                            disabled={!props.editMode}
                            onChange={e => {
                                handleChange(e);
                                handleUpdate("notes", e.target.value);
                            }}
                        />
                        </Grid>

                    </Grid>
                </Form>
            )}
        </Formik>
    );
}