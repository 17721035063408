import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import SelectField from "./selectField";
import Grid from "@material-ui/core/Grid";
import {Field, Form} from "formik";
import {FormControlLabel} from "@material-ui/core";
import Switch from "@material-ui/core/Switch/Switch";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    formControlLabel: {
        fontSize: '16px',
        transform: 'scale(0.75)'
    },
    collapse: {
        width: '100%'
    }

}));

export default function FrameStyleField(props) {
    const classes = useStyles();

    return (
        <Grid container spacing={0}>
            <Grid container item xs={12}>
                <Grid container item xs={8} justify="center">
                   <SelectField
                       id="frameStyle"
                       name="frameStyle"
                       label="Frame Style"
                       disabled={props.disabled}
                       options={props.options}
                       value={props.value}
                       onChange={e =>{
                           let c = {
                               frameStyle:  e.target.value,
                               customFrameStyle: {
                                   top: e.target.value,
                                   bottom: e.target.value,
                                   left: e.target.value,
                                   right: e.target.value,
                               },
                               mountStyle:  e.target.value.toLowerCase().includes("outside")? "Outside": "Inside",
                           };
                           props.onChange(["frameStyle", "customFrameStyle", "mountStyle"], c);
                       }}
                   />
                </Grid>
                <Grid container item xs={4} justify="center">
                    <FormControlLabel
                        name="frameStyleIsCustom"
                        value="bottom"
                        control={<Checkbox
                            color="primary"
                            name="frameStyleIsCustom"
                            checked={props.frameStyleIsCustom}
                            disabled={props.disabled}
                            onChange={e =>{
                                let c = {
                                    frameStyleIsCustom: e.target.checked,
                                    customFrameStyle: {
                                        top: props.value,
                                        bottom: props.value,
                                        left: props.value,
                                        right: props.value,
                                    }
                                };
                                props.onChange(["frameStyleIsCustom", "customFrameStyle"], c);
                            }}
                        />}
                        label={<Typography className={classes.formControlLabel}>Custom</Typography>}
                        labelPlacement="bottom"
                    />
                </Grid>
            </Grid>
                <Collapse in={props.frameStyleIsCustom} className={classes.collapse}>
                        {Object.keys(props.customFrameStyle).map(k =>
                            <Grid key={`custom-frame-style-${k}`} container item xs={12}>
                                <SelectField
                                    key={`f-style-${k}`}
                                    id={`f-style-${k}`}
                                    label={k.toUpperCase()}
                                    variant="outlined"
                                    type="number"
                                    options={props.customFrameOptions}
                                    name={k}
                                    value={props.customFrameStyle[k]}
                                    fullWidth
                                    disabled={props.disabled}
                                    size="small"
                                    onChange={e =>{
                                        let c = {
                                            customFrameStyle: {
                                                ...props.customFrameStyle,
                                                [k]: e.target.value
                                            }
                                        };
                                        props.onChange(["customFrameStyle"], c);
                                    }}
                                />
                            </Grid>
                        )}
                </Collapse>
        </Grid>
    )
}
