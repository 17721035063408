import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import {pdfCreate} from "../../api/pdfDownload";
import {Shutter} from "../shutter";
import {draw} from "../drawSVG";
import {StyledMenu, StyledMenuItem} from "./navSelect";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {viewConfigOverrides} from "./utils";
import DownloadFactoryDrawings from "./downloadFactoryDrawings";
import LabelCSV from "./labelCSV";
const FileDownload = require('js-file-download');


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: '#fff',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    disabledButton: {
        color: '#fff'
    }
}));

export function DownloadDrawingButton(props) {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const timer = React.useRef();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);
    const getShutterData = (shutter, view) => {
        const d = new Shutter({
            config: viewConfigOverrides(view, shutter.config),
            view: view,
            ...shutter
        }).draw();
        let svg = draw(shutter, d, viewConfigOverrides(view, shutter.config), view, true);
        let encodedData = window.btoa(svg.outerHTML);
        let blob = `data:image/svg+xml;base64,${encodedData}`;
        return {
            ...shutter,
            image: blob,
            frameConfiguration: d.frameConfiguration,
            tPosts: d.tPosts,
            ...(view === 'factory' && {
                cutList: d.cutList,
                labels: d.labels,
                internalOrderId: props.additionalOrderData.internalOrderId
            })
        }
    };
    const handleLoading = () => {
        handleClose();
    };
    const handleFinishLoading = () => {
        handleClose();
        setSuccess(true);
        setLoading(false);
    };
    const handleButtonClick = async (view) => {
        handleClose();
        if (!loading) {
            setSuccess(false);
            setLoading(true);
            let extra = {};
            let url;
            if (['allLabels', 'itemLabel'].includes(view)){
                url = '/labels';
            } else if (['order_form'].includes(view)) {
                url = '/order-form';
            } else if (['customer'].includes(view)){
                url = '/customer-drawing'
            } else {
                url = '/'
            }
            if(['itemLabel'].includes(view)){
                extra = {
                    item: props.currentShutter._id
                }
            }
            if(['order_form'].includes(view)){
                extra = {
                    order: props.order
                }
            }
            let filePrefix = view;
            view = ['allLabels', 'itemLabel', 'order_form'].includes(view)? 'factory' : view;
            let shutterData = props.shutters.filter(shutter => !shutter.deleted).map(shutter => {return getShutterData(shutter, view)});
            //console.log(props.shutters);
            // console.log(JSON.stringify(shutterData));

            let fileResponse = await pdfCreate(url, {shutters: shutterData, ...extra}).then(response => {
                let blob = new Blob([response.data]);
                setLoading(false);
                FileDownload(blob, `${props.shuttersOrderId}-${filePrefix}.pdf`);
            }).catch(error => {
                setLoading(false);
                alert(error);
            });
        }
    };

    return (
        <div className={classes.wrapper}>
            <Button
                color="inherit"
                endIcon={<ArrowDropDownIcon/>}
                disabled={loading}
                onClick={handleClick}
                classes={{ disabled: classes.disabledButton }}
            >
             <React.Fragment>Download</React.Fragment>
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            <StyledMenu
                id="download-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <StyledMenuItem onClick={()=>handleButtonClick('order_form')}>Order Form</StyledMenuItem>
                <DownloadFactoryDrawings {...props} handleButtonClick={handleButtonClick} handleClose={handleClose} buttonName="factory" buttonLabel="Factory Drawings" />
                {/*<StyledMenuItem onClick={()=>handleButtonClick('detailed_customer')}>Simple Factory Drawings</StyledMenuItem>*/}
                <StyledMenuItem onClick={()=>handleButtonClick('customer')}>Customer Drawings</StyledMenuItem>
                <DownloadFactoryDrawings {...props} handleButtonClick={handleButtonClick} handleClose={handleClose} buttonName="allLabels" buttonLabel="All Labels" />
                <DownloadFactoryDrawings {...props} handleButtonClick={handleButtonClick} handleClose={handleClose} buttonName="itemLabel" buttonLabel="Item Labels" />
                <LabelCSV {...props} handleButtonClick={handleButtonClick} handleClose={handleClose} getShutterData={getShutterData} handleFinishLoading={handleFinishLoading} handleLoading={handleLoading} buttonName="labelCSV" buttonLabel="All Labels CSV"/>
            </StyledMenu>

        </div>
    )
}