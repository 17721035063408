import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    }

}));

export default function SelectField(props) {
    const classes = useStyles();

    const handleChange = (event) => {
        props.onChange(event)
    };
    return (

            <TextField
                id={props.id}
                select
                name={props.name}
                label={props.label}
                value={props.value}
                onChange={handleChange}
                helperText={props.helperText}
                className={classes.textField}
                variant="outlined"
                fullWidth={true}
                disabled={props.disabled}
                size={props.size}
            >
                {props.options && props.options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.display}
                    </MenuItem>
                ))}
            </TextField>
    )
}
