import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import {List, ListItem} from "@material-ui/core";


export default function SupplierPopup(props) {
    const [currentSelectedSupplier, setCurrentSelectedSupplier] = React.useState(null);


    const handleSubmit = (value) => {
        props.onClose(currentSelectedSupplier);
    };

    const handleChange = (event) => {
        setCurrentSelectedSupplier(event.target.value);
    }

    return (
        <Dialog open={props.open}>
            <DialogTitle>Select Supplier</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    The selected supplier's config will be loaded from Knack, replacing any existing config.
                </DialogContentText>
                <FormControl fullWidth>
                    <InputLabel id="supplier-select-label">Supplier</InputLabel>
                    <Select
                        labelId="supplier-select-label"
                        id="supplier-select"
                        value={currentSelectedSupplier}
                        label="Supplier"
                        onChange={handleChange}
                    >
                        {props.supplierList.map((supplier => (
                            <MenuItem value={supplier[1]}>{supplier[0]}</MenuItem>
                        )))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit}>Submit</Button>
                <Button onClick={props.onCancel}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}
