import {defaultConfig, supplierOverrides} from "./config";
import _ from "lodash"
import arraySort from "array-sort";
import {determineVisibility} from "./order/utils";


class Measurements {
    constructor(){
        this.measurements = []
    }
    add({ id, name, line, display, vertical}){
        this.measurements.push({
            id: id,
            name: name,
            X1: line.X1,
            Y1: line.Y1,
            X2: line.X2,
            Y2: line.Y2,
            measurement: !vertical ? Math.abs(line.X2 -  line.X1) : Math.abs( line.Y2 - line.Y1),
            display: display
        })
    }

}
class Shutter {
    constructor({
                    config,
                    width,
                    height,
                    midrailPositions,
                    configuration,
                    view,
                    ...rest
                }) {
        this.view = view;
        this.config = config;
        this.standardDeduction = rest.standardDeduction? (config.frame[rest.frameStyle].standardDeduction*2) : 0;
        this.width = config.zoomVariable * (parseFloat(width) - this.standardDeduction + (config.frame[rest.frameStyle].lip*2));
        this.drop = config.zoomVariable * (parseFloat(height) - this.standardDeduction + (config.frame[rest.frameStyle].lip*2));
        this.width1 = config.zoomVariable * (parseFloat(width) - this.standardDeduction);
        this.drop1 = config.zoomVariable * (parseFloat(height) - this.standardDeduction);
        this.frameWidth = config.frame[rest.frameStyle].frameWidth;
        this.midrails = rest.midrails;
        let mp = midrailPositions;
        this.midrailsEvenlySpaced = midrailPositions === 'Evenly Spaced' && this.midrails > 0;
        if(midrailPositions === 'Evenly Spaced' && rest.midrails > 0){
            mp = [];
            let value = 0;
            for (const n of [...Array(this.midrails).keys()]) {
                value += (this.drop-(config.frame[rest.frameStyle].lip*2))/(this.midrails+1);
                mp.push(
                    {name: `M${n+1}` , value: value, critical: n===0 }
                )
            }

        }
        this.midrailPositions = mp;
        this.customFrameStyle = rest.customFrameStyle;
        this.frameStyleDictionary = {};
        this.frameInnerCoordinates = {};
        this.frameWithoutLipCoordinates= {};
        this.framePanelCoordinates= {};
        this.frameOuterCoordinates = {};
        this.configuration = configuration;
        this.drawing = {};
        this.frontView = {};
        this.topView = {};
        this.sideView = {};
        this.measurements = {};
        this.frameStyle = rest.frameStyle;
        this.panelWidthOption = rest.panelWidthOption;
        this.panelWidth = rest.panelWidth && rest.panelWidth !== "" ? rest.panelWidth.split(',').filter(p => p !== "") : [];
        this.customConfiguration = [];
        this.splitTiltRod = rest.splitTiltRod;
        this.splitTiltRodZones = rest.splitTiltRodZones;

        this.meta = {};
        this.metaView = rest.hasOwnProperty('meta') ? rest.meta : {};
        this.frameConfiguration = {};
        this.tPosts = [];
        this.cutList = {};
        this.labels = [];
        this.frameFunctions = {
          "Z Frame": {
              top: this.designZFrameTopView,
              side: this.designZFrameSideView
          },
        "Z Frame With Sill": {
            top: this.designZFrameTopView,
            side: this.designZFrameWithSillSideView
        },
        "Sill Frame": {
            top: this.designSillFrameTopView,
            side: this.designSillFrameSideView
        },
        "SMALL L": {
          top: this.designLFrameTopView,
          side: this.designLFrameSideView
        },
        "Inside L Frame": {
            top: this.designLFrameTopView,
            side: this.designLFrameSideView
        },
        "Outside L Frame": {
            top: this.designLFrameTopView,
            side: this.designLFrameSideView
        },
        "No Frame": {
                top: this.designNoFrame,
                side: this.designNoFrame
        }
        };
    }

    draw(){
        const [startX, startY] = this.prepareCanvas();
        this.validateShutter();
        this.validateConfig();
        this.drawFrames(startX, startY);
        this.drawPanels();
        this.drawSideView();
        this.drawTopView();
        this.drawMeasurements();
        this.getMeta();
        this.generateCutList();
        this.generateLabels();
        this.getFrameConfiguration();
        this.getTPosts();
        const drawing = {
            views: {
                frontView: this.frontView,
                sideView: this.sideView,
                topView: this.topView
            },
            measurements: this.measurements,
            meta: this.meta,
            cutList: this.cutList,
            labels: this.labels,
            frameConfiguration: this.frameConfiguration,
            tPosts: this.tPosts,
        };
        return drawing
    }
    validateConfig(){
        for (const [key, value] of Object.entries(defaultConfig)) {
            if(!this.config.hasOwnProperty(key) || (this.config.hasOwnProperty(key) && (!this.config[key] || this.config[key] === ''))){
                throw new Error(`${_.startCase(key)} config is invalid`)
            }
        }
    }
    validateShutter(){
        if(!this.width || this.width === ''){
            throw new Error("Width is invalid")
        }
        if(!this.drop || this.drop === ''){
            throw new Error("Height is invalid")
        }
        if(!this.frameStyle || this.frameStyle === ''){
            throw new Error("Frame Style is invalid")
        }
        if(!this.configuration || this.configuration === ''){
            throw new Error("Configuration is invalid")
        }
        // if(this.panelWidth.length === 0 && this.panelWidthOption === 'Custom'){
        //     throw new Error("Custom panel T post positions are invalid")
        // }
        if(Array.isArray(this.midrailPositions) && (this.midrailPositions.length > 0)){
            let prev = null;
            this.midrailPositions.sort((a, b) => (a.name > b.name) ? 1 : -1).map(function (p) {
                if(!p.value){
                    throw new Error("Midrail Position Invalid")
                }
                if(prev && parseFloat(prev.value) >= parseFloat(p.value)){
                    throw new Error("Midrail Position error")
                }
                prev = p
            });

        }
        if(this.midrails > 0 && (!Array.isArray(this.midrailPositions) || (Array.isArray(this.midrailPositions) && this.midrailPositions.length === 0))){
            throw new Error("Midrail Position Invalid")
        }

    }
    qualityCheck() {
        let warnings = [];
        let top_rail_height = this.measurements.side.find(m => m.name === "top_rail_height").measurement;
        let bottom_rail_height = this.measurements.side.find(m => m.name === "bottom_rail_height").measurement;
        if(top_rail_height > this.config.topRailMaxWidth){
            warnings.push({
                component: "topRailHeight",
                message : "Top rail height is greater than the max"
            });
        }
        if(top_rail_height < this.config.topRailMinWidth){
            warnings.push({
                component: "topRailHeight",
                message: "Top rail height is less than the min"
            });
        }
        if(bottom_rail_height > this.config.bottomRailMaxWidth){
            warnings.push({
                component: "bottomRailHeight",
                message: "Bottom rail height is greater than the max"
            });
        }
        if(bottom_rail_height < this.config.bottomRailMinWidth){
            warnings.push({
                component: "bottomRailHeight",
                message: "Bottom rail height is less than the min"
            });
        }
        return warnings
    }
    getMeta() {
        this.meta = {
            ...this.meta,
            midRailPos: this.frontView.panels.filter(p => p.type === 'panel')[0].rails.midPos.map(p => ({...p, value: parseFloat(p.value) % 1 !==0 ? parseFloat(p.value).toFixed(1) : parseFloat(p.value)}))
        }
    }
    prepareCanvas(){
        const canvasWidth = this.width + this.config.canvasWidthExtension;
        const canvasHeight = this.drop + (this.panelWidthOption === 'Custom' ? this.config.canvasHeightExtension : this.config.canvasHeightExtension) + 200;
        const centerX = (canvasWidth/2) - 100;
        const centerY = (canvasHeight/2) + 100;
        const startX = centerX - (this.width/2);
        const startY = centerY - (this.drop/2);
        return [startX, startY]
    }
    getFrameConfiguration(){
        for(const [key, value] of Object.entries(this.customFrameStyle)){
            let val = value;
            switch (value) {
                case 'No Frame':
                    val = 'No';
                    break;
                case 'Sill Frame':
                    val = 'Sill';
                    break;
                default:
                    val = 'Yes'
            }
            this.frameConfiguration[key] = val;
        }
    }
    getTPosts () {
        this.tPosts = this.measurements.top.filter(m => m.name === "tPost").map(m => this.roundMeasurement(m.measurement)).sort((a, b) => (a.value > b.value) ? 1 : -1)
    }
    designFrameHorizontal(frameStyle, customFrameStyle, location, start, dimensions, invert){
        let x = 1;
        let fs = customFrameStyle;
        if(invert){
            x = -1;
        }
        let frameWidth = this.config.frame[fs].frameWidth;
        let width = dimensions.width;
        let dropReduction = 0;

        let r = [{
                X1: start.X,
                Y1: start.Y + dropReduction * x,
                X2: start.X + width,
                Y2: start.Y + dropReduction * x,
                type: 'line',
                options: {
                    strokeWidth: 1,
                    ...(fs === 'No Frame' && {stroke: '#fff'})
                }
            },
            {
                X1: start.X + frameWidth,
                Y1: start.Y + (dropReduction * x) + (frameWidth * x),
                X2: start.X + width - frameWidth,
                Y2: start.Y + (dropReduction * x) + (frameWidth * x),
                type: 'line',
                options: {
                    strokeWidth: 1,
                    ...(fs === 'No Frame' && {stroke: '#fff'})
                }
            },

        ];
        this.frameOuterCoordinates = {
            ...this.frameOuterCoordinates,
            [location]: {
                X: r[0].X1,
                Y: r[0].Y1
            },
        };
        this.frameInnerCoordinates = {
            ...this.frameInnerCoordinates,
            [location]: {
                X: r[1].X1,
                Y: r[1].Y1
            },
        };
        this.frameWithoutLipCoordinates = {
            ...this.frameWithoutLipCoordinates,
            [location]: {
                X: r[0].X1,
                Y: r[0].Y1 + this.config.frame[fs].lip * x
            },
        };
        this.framePanelCoordinates = {
            ...this.framePanelCoordinates,
            [location]: {
                X: r[1].X1,
                Y: r[1].Y1 + (this.config.frame[fs].hasOwnProperty('panelTopBottomGap') ? this.config.frame[fs].panelTopBottomGap * x : this.config.panelTopBottomGap * x)
            },
        };
        return r;
    }
    designFrameVertical(frameStyle, customFrameStyle, location, start, dimensions, invert){
        let x = 1;
        let fs = customFrameStyle;
        if(invert){
            x = -1;
        }
        let frameWidth = this.config.frame[fs].frameWidth;
        let drop = dimensions.drop;
        let widthReduction = 0;
        let r = [
            {
            X1: start.X + widthReduction * x,
            Y1: start.Y,
            X2: start.X + widthReduction * x,
            Y2: start.Y + drop,
            type: 'line',
            options: {
                strokeWidth: 1,
                ...(fs === 'No Frame' && {stroke: '#fff'})
            }
        },
            {
                X1: start.X + (widthReduction * x) + (frameWidth * x),
                Y1: start.Y + frameWidth,
                X2: start.X + + (widthReduction * x) + (frameWidth * x),
                Y2: start.Y + drop - frameWidth ,
                type: 'line',
                options: {
                    strokeWidth: 1,
                    ...(fs === 'No Frame' && {stroke: '#fff'})
                }
            }
        ];
        this.frameOuterCoordinates = {
            ...this.frameOuterCoordinates,
            [location]: {
                X: r[0].X1,
                Y: r[0].Y1
            },
        };
        this.frameInnerCoordinates = {
            ...this.frameInnerCoordinates,
            [location]: {
                X: r[1].X1,
                Y: r[1].Y1
            },
        };
        this.frameWithoutLipCoordinates = {
            ...this.frameWithoutLipCoordinates,
            [location]: {
                X: r[0].X1 + this.config.frame[fs].lip * x,
                Y: r[0].Y1
            },
        };
        this.framePanelCoordinates = {
            ...this.framePanelCoordinates,
            [location]: {
                X: r[1].X1 + (this.config.frame[fs].hasOwnProperty('panelLeftRightGap') ? this.config.frame[fs].panelLeftRightGap * x : this.config.panelLeftRightGap * x),
                Y: r[1].Y1
            },
        };
        return r;
    }
    connectTopAdjacentFrames(horizontalFrame, verticalFrame, horizontalCompare, verticalCompare, invert){
        let connectors = [];
        if(horizontalCompare.X !== verticalCompare.X){
            if(horizontalCompare.X > verticalCompare.X && !invert){
                connectors.push({
                    X1:  verticalFrame[1].X1,
                    Y1:  verticalFrame[1].Y1,
                    X2:  verticalFrame[0].X1,
                    Y2:  verticalFrame[0].Y1,
                    type: 'line',
                    options: {
                        strokeWidth: 1
                    }
                });
                connectors.push({
                    X1:  horizontalFrame[1].X1,
                    Y1:  horizontalFrame[1].Y1,
                    X2:  verticalFrame[1].X1,
                    Y2:  horizontalFrame[1].Y1,
                    type: 'line',
                    options: {
                        strokeWidth: 1
                    }
                })
            }
            if(horizontalCompare.X < verticalCompare.X && invert){
                connectors.push({
                    X1:  verticalFrame[1].X1,
                    Y1:  verticalFrame[1].Y1,
                    X2:  verticalFrame[0].X1,
                    Y2:  verticalFrame[0].Y1,
                    type: 'line',
                    options: {
                        strokeWidth: 1
                    }
                });
                connectors.push({
                    X1:  horizontalFrame[1].X1,
                    Y1:  horizontalFrame[1].Y1,
                    X2:  verticalFrame[1].X1,
                    Y2:  horizontalFrame[1].Y1,
                    type: 'line',
                    options: {
                        strokeWidth: 1
                    }
                })
            }
            if(horizontalCompare.X > verticalCompare.X && invert){
                connectors.push({
                    X1:  horizontalFrame[1].X2,
                    Y1:  horizontalFrame[1].Y2,
                    X2:  horizontalFrame[0].X2,
                    Y2:  horizontalFrame[0].Y2,
                    type: 'line',
                    options: {
                        strokeWidth: 1
                    }
                });
                connectors.push({
                    X1:  verticalFrame[1].X2,
                    Y1:  verticalFrame[1].Y2,
                    X2:  verticalFrame[1].X1,
                    Y2:  horizontalFrame[1].Y1,
                    type: 'line',
                    options: {
                        strokeWidth: 1
                    }
                })
            }
        }
        else {
            connectors.push({
                X1:  verticalFrame[1].X1,
                Y1:  verticalFrame[1].Y1,
                X2:  verticalFrame[0].X1,
                Y2:  verticalFrame[0].Y1,
                type: 'line',
                options: {
                    strokeWidth: 1
                }
            });
        }
        if(horizontalCompare.Y !== verticalCompare.Y){
            if(horizontalCompare.Y < verticalCompare.Y && !invert){
                connectors.push({
                    X1:  horizontalFrame[1].X1,
                    Y1:  horizontalFrame[1].Y1,
                    X2:  horizontalFrame[0].X1,
                    Y2:  horizontalFrame[0].Y1,
                    type: 'line',
                    options: {
                        strokeWidth: 1
                    }
                });
                connectors.push({
                    X1:  verticalFrame[1].X1,
                    Y1:  verticalFrame[1].Y1,
                    X2:  verticalFrame[1].X1,
                    Y2:  horizontalFrame[1].Y1,
                    type: 'line',
                    options: {
                        strokeWidth: 1
                    }
                })
            }
        }
        if(verticalFrame[1].X1 === verticalFrame[0].X1){
            connectors.push({
                X1:  horizontalFrame[0].X1,
                Y1:  horizontalFrame[0].Y1,
                X2:  horizontalFrame[0].X1,
                Y2:  horizontalFrame[1].Y1,
                type: 'line',
                options: {
                    strokeWidth: 1
                }
            })
        }
        if(verticalFrame[1].X2 === verticalFrame[0].X2){
            connectors.push({
                X1:  horizontalFrame[0].X2,
                Y1:  horizontalFrame[0].Y2,
                X2:  horizontalFrame[0].X2,
                Y2:  horizontalFrame[1].Y2,
                type: 'line',
                options: {
                    strokeWidth: 1
                }
            })
        }
        if(horizontalFrame[1].X1 === horizontalFrame[0].X1){
            connectors.push({
                X1:  verticalFrame[0].X1,
                Y1:  verticalFrame[0].Y1,
                X2:  verticalFrame[1].X1,
                Y2:  verticalFrame[0].Y1,
                type: 'line',
                options: {
                    strokeWidth: 1
                }
            })
        }
        if(horizontalFrame[1].X2 === horizontalFrame[0].X2){
            connectors.push({
                X1:  verticalFrame[0].X2,
                Y1:  verticalFrame[0].Y2,
                X2:  verticalFrame[1].X2,
                Y2:  verticalFrame[0].Y2,
                type: 'line',
                options: {
                    strokeWidth: 1
                }
            })
        }
        return connectors
    }
    connectBottomAdjacentFrames(horizontalFrame, verticalFrame, horizontalCompare, verticalCompare, invert){
        let connectors = [];
        if(horizontalCompare.X !== verticalCompare.X){
            if(horizontalCompare.X > verticalCompare.X && !invert){
                connectors.push({
                    X1:  verticalFrame[1].X1,
                    Y1:  verticalFrame[1].Y1,
                    X2:  verticalFrame[0].X1,
                    Y2:  verticalFrame[0].Y1,
                    type: 'line',
                    options: {
                        strokeWidth: 1
                    }
                });
                connectors.push({
                    X1:  horizontalFrame[1].X1,
                    Y1:  horizontalFrame[1].Y1,
                    X2:  verticalFrame[1].X1,
                    Y2:  horizontalFrame[1].Y1,
                    type: 'line',
                    options: {
                        strokeWidth: 1
                    }
                })
            }
            if(horizontalCompare.X < verticalCompare.X && invert){
                connectors.push({
                    X1:  verticalFrame[1].X2,
                    Y1:  verticalFrame[1].Y2,
                    X2:  verticalFrame[0].X2,
                    Y2:  verticalFrame[0].Y2,
                    type: 'line',
                    options: {
                        strokeWidth: 1
                    }
                });
                connectors.push({
                    X1:  horizontalFrame[1].X1,
                    Y1:  horizontalFrame[1].Y1,
                    X2:  verticalFrame[1].X1,
                    Y2:  horizontalFrame[1].Y1,
                    type: 'line',
                    options: {
                        strokeWidth: 1
                    }
                })
            }
            if(horizontalCompare.X > verticalCompare.X && invert){
                connectors.push({
                    X1:  horizontalFrame[1].X2,
                    Y1:  horizontalFrame[1].Y2,
                    X2:  horizontalFrame[0].X2,
                    Y2:  horizontalFrame[0].Y2,
                    type: 'line',
                    options: {
                        strokeWidth: 1
                    }
                });
                connectors.push({
                    X1:  verticalFrame[1].X2,
                    Y1:  verticalFrame[1].Y2,
                    X2:  verticalFrame[1].X1,
                    Y2:  horizontalFrame[1].Y1,
                    type: 'line',
                    options: {
                        strokeWidth: 1
                    }
                })
            }
        }
        else {
            connectors.push({
                X1:  verticalFrame[1].X2,
                Y1:  verticalFrame[1].Y2,
                X2:  verticalFrame[0].X2,
                Y2:  verticalFrame[0].Y2,
                type: 'line',
                options: {
                    strokeWidth: 1
                }
            });
        }
        if(horizontalCompare.Y !== verticalCompare.Y){
            if(horizontalCompare.Y < verticalCompare.Y && !invert){
                connectors.push({
                    X1:  verticalFrame[1].X2,
                    Y1:  verticalFrame[1].Y2,
                    X2:  verticalFrame[0].X2,
                    Y2:  verticalFrame[0].Y2,
                    type: 'line',
                    options: {
                        strokeWidth: 1
                    }
                });
                connectors.push({
                    X1:  verticalFrame[1].X2,
                    Y1:  verticalFrame[1].Y2,
                    X2:  verticalFrame[1].X2,
                    Y2:  horizontalFrame[1].Y1,
                    type: 'line',
                    options: {
                        strokeWidth: 1
                    }
                })
            }
            if(horizontalCompare.Y > verticalCompare.Y && !invert){
                connectors.push({
                    X1:  horizontalFrame[1].X1,
                    Y1:  horizontalFrame[1].Y1,
                    X2:  horizontalFrame[0].X1,
                    Y2:  horizontalFrame[0].Y1,
                    type: 'line',
                    options: {
                        strokeWidth: 1
                    }
                });
                connectors.push({
                    X1:  verticalFrame[1].X1,
                    Y1:  verticalFrame[1].Y1,
                    X2:  verticalFrame[1].X1,
                    Y2:  horizontalFrame[1].Y1,
                    type: 'line',
                    options: {
                        strokeWidth: 1
                    }
                })
            }

        }
        if(verticalFrame[1].X1 === verticalFrame[0].X1){
            connectors.push({
                X1:  horizontalFrame[0].X1,
                Y1:  horizontalFrame[0].Y1,
                X2:  horizontalFrame[0].X1,
                Y2:  horizontalFrame[1].Y1,
                type: 'line',
                options: {
                    strokeWidth: 1
                }
            })
        }
        if(verticalFrame[1].X2 === verticalFrame[0].X2){
            connectors.push({
                X1:  horizontalFrame[0].X2,
                Y1:  horizontalFrame[0].Y2,
                X2:  horizontalFrame[0].X2,
                Y2:  horizontalFrame[1].Y2,
                type: 'line',
                options: {
                    strokeWidth: 1
                }
            })
        }
        if(horizontalFrame[1].X1 === horizontalFrame[0].X1){
            connectors.push({
                X1:  verticalFrame[0].X1,
                Y1:  verticalFrame[0].Y1,
                X2:  verticalFrame[1].X1,
                Y2:  verticalFrame[0].Y1,
                type: 'line',
                options: {
                    strokeWidth: 1
                }
            })
        }
        if(horizontalFrame[1].X2 === horizontalFrame[0].X2){
            connectors.push({
                X1:  verticalFrame[0].X2,
                Y1:  verticalFrame[0].Y2,
                X2:  verticalFrame[1].X2,
                Y2:  verticalFrame[0].Y2,
                type: 'line',
                options: {
                    strokeWidth: 1
                }
            })
        }
        return connectors
    }
    connectFrames(topFrame, bottomFrame, leftFrame, rightFrame){
        let topLeft = this.connectTopAdjacentFrames(topFrame, leftFrame, {X: topFrame[1].X1, Y: topFrame[1].Y1} , {X: leftFrame[1].X1, Y: leftFrame[1].Y1},false);
        let topRight = this.connectTopAdjacentFrames(topFrame, rightFrame, {X: topFrame[1].X2, Y: topFrame[1].Y2} , {X: rightFrame[1].X1, Y: rightFrame[1].Y1},true);
        let bottomLeft = this.connectBottomAdjacentFrames(bottomFrame, leftFrame, {X: bottomFrame[1].X1, Y: bottomFrame[1].Y1} , {X: leftFrame[1].X2, Y: leftFrame[1].Y2},false);
        let bottomRight = this.connectBottomAdjacentFrames(bottomFrame, rightFrame, {X: bottomFrame[1].X2, Y: bottomFrame[1].Y2} , {X: rightFrame[1].X2, Y: rightFrame[1].Y2},true);
        return [...topLeft, ...topRight, ...bottomLeft, ...bottomRight]
    }
    calculateWidthDrop(){
        let width = this.width1;
        let drop = this.drop1;
        for(const [key, value] of Object.entries(this.customFrameStyle)){
            let fs = value;
            if(['top', 'bottom'].includes(key)){
                drop += this.config.frame[fs].lip;
            }
            if(['left', 'right'].includes(key)){
                width += this.config.frame[fs].lip;
            }
            this.frameStyleDictionary = {
                ...this.frameStyleDictionary,
                [key]: fs
            }
        }
        return {
            width: width,
            drop: drop
        }
    }
    drawFrames(startX, startY) {
        // const outerRectX = startX;
        // const outerRectY = startY;
        // const outerRectW = this.width;
        // const outerRectH = this.drop - this.config.frame[this.frameStyle].frameBottomCut;;
        let dimensions = this.calculateWidthDrop();
        //Left Right Frame?
        let topFrame = this.designFrameHorizontal(this.frameStyle, this.customFrameStyle.top, 'top', {X: startX, Y: startY}, dimensions, false);
        let bottomFrame = this.designFrameHorizontal(this.frameStyle, this.customFrameStyle.bottom, 'bottom', {X: startX, Y: startY + dimensions.drop}, dimensions, true);
        let leftFrame = this.designFrameVertical(this.frameStyle, this.customFrameStyle.left, 'left', {X: startX, Y: startY}, dimensions, false);
        let rightFrame = this.designFrameVertical(this.frameStyle, this.customFrameStyle.right, 'right', {X: startX + dimensions.width, Y: startY}, dimensions, true);
        let connectors = this.connectFrames(topFrame, bottomFrame, leftFrame, rightFrame);
        let frames = [...topFrame, ...bottomFrame, ...leftFrame, ...rightFrame, ...connectors];


        // for(const [key, value] of Object.entries(this.customFrameStyle)){
        //     if(['top', 'bottom'].includes(key)){
        //
        //     }
        //     else {
        //
        //     }
        // }
        // const innerRectX = startX + this.frameWidth;
        // const innerRectY = startY + this.frameWidth;
        // const innerRectW = this.width - this.frameWidth*2;
        // const innerRectH = this.drop - this.frameWidth*2 + this.config.frame[this.frameStyle].panelHeightIncrease;

        const outerRectX = this.frameOuterCoordinates.left.X;
        const outerRectY = this.frameOuterCoordinates.top.Y;
        const outerRectW = this.frameOuterCoordinates.right.X - this.frameOuterCoordinates.left.X;
        const outerRectH = this.frameOuterCoordinates.bottom.Y - this.frameOuterCoordinates.top.Y;

        const innerRectX = this.frameInnerCoordinates.left.X;
        const innerRectY = this.frameInnerCoordinates.top.Y;
        const innerRectW = this.frameInnerCoordinates.right.X - this.frameInnerCoordinates.left.X;
        const innerRectH = this.frameInnerCoordinates.bottom.Y - this.frameInnerCoordinates.top.Y;
        this.frontView = {
            frames: frames,
            outerRect: {
                X: outerRectX,
                Y: outerRectY,
                W: outerRectW,
                H: outerRectH
            },
            innerRect: {
                X: innerRectX,
                Y: innerRectY,
                W: innerRectW,
                H: innerRectH
            }
        }

    }
    drawPanels(){
        let configuration = this.configuration.replaceAll('-','').split('');
        let panels = [];
        let tPosts = configuration.filter(c => c === 'T');
        let dMolds = configuration.filter(c => c === 'D');
        let tId = 1;
        let totalPanels = configuration.filter(c => !['D', 'T'].includes(c)).length;

        if(this.panelWidthOption !== 'Custom' || this.panelWidth.length === 0) {

            // let pX = this.frontView.innerRect.X + this.config.panelLeftRightGap;
            // let pY = this.frontView.innerRect.Y + this.config.panelTopBottomGap;
            let pX = this.framePanelCoordinates.left.X;
            let pY = this.framePanelCoordinates.top.Y;
            // let pW = (this.frontView.innerRect.W - (this.config.tPostWidth * tPosts.length) - (this.config.panelLeftRightGap * 2) - (this.config.panelLeftRightGap * dMolds.length) - ((this.config.panelLeftRightGap * 2) * tPosts.length)) / totalPanels;
            let pW = ((this.framePanelCoordinates.right.X - this.framePanelCoordinates.left.X) - (this.config.tPostWidth * tPosts.length) - (this.config.panelLeftRightGap * dMolds.length) - ((this.config.panelLeftRightGap * 2) * tPosts.length)) / totalPanels;
            let pH = (this.framePanelCoordinates.bottom.Y - this.framePanelCoordinates.top.Y);
            let panelComponentCount = 1;
            for (const panelComponent of configuration) {
                if (panelComponent === 'L') {
                    panels.push(this.designPanel(panelComponentCount, pX, pY, pW, pH, 'L'));
                    pX = pX + pW + this.config.panelLeftRightGap;
                } else if (panelComponent === 'R') {
                    panels.push(this.designPanel(panelComponentCount, pX, pY, pW, pH, 'R'));
                    pX = pX + pW + this.config.panelLeftRightGap;
                } else if (panelComponent === 'D') {
                    panels.push(this.designDMold(panelComponentCount, pX - this.config.panelLeftRightGap, pY, pW, pH));
                } else if (panelComponent === 'T') {
                    panels.push(this.designTPost(panelComponentCount, tId, pX, pY, this.config.tPostWidth * this.config.zoomVariable, pH));
                    let location = parseFloat(pX-this.frameWithoutLipCoordinates.left.X + this.config.tPostWidth/2)
                    this.customConfiguration.push({
                        layout: panelComponent,
                        ...{location: location},
                        panels: panelComponentCount
                    });
                    pX = pX + this.config.tPostWidth * this.config.zoomVariable + this.config.panelLeftRightGap;
                    tId += 1;

                }
                panelComponentCount += 1;
            }
        }
        else {
            let pX = this.frameWithoutLipCoordinates.left.X;
            let pY = this.framePanelCoordinates.top.Y;
            let pH = (this.framePanelCoordinates.bottom.Y - this.framePanelCoordinates.top.Y);
            let pW = 0;
            let panelComponentCount = 0;
            let customConfiguration = [];
            let c = 0;
            let p = 0;
            for (const panelComponent of configuration) {
                customConfiguration.push({
                    layout: panelComponent,
                    ...(['T'].includes(panelComponent) && {location: parseFloat(this.panelWidth[c])}),
                    panels: p
                });

                if(['T'].includes(panelComponent)){
                    c += 1
                }
                if(panelComponent === 'T'){
                    p=0
                }
                if(['L', 'R'].includes(panelComponent)){
                    p += 1
                }
            }
            this.customConfiguration = customConfiguration;
            let tCount = 0;
            let previousTPost = null;
            let tPostInnerWidth = 0;
            panelComponentCount += 1;
            let tId = 1;
            for (const panelComponent of customConfiguration.filter(p => p.layout === 'T')) {
                panels.push(this.designTPost(panelComponentCount,tId, pX + panelComponent.location - (this.config.tPostWidth / 2), pY, this.config.tPostWidth, pH));
                let tPostLocationX = pX + panelComponent.location - (this.config.tPostWidth / 2);
                tPostInnerWidth = tPostLocationX - (this.framePanelCoordinates.left.X + (tPostInnerWidth > 0 ? tPostInnerWidth + this.config.tPostWidth : 0));
                let cfgTmp = this.configuration.replaceAll('-', '').split('T');
                let cfg = cfgTmp[tCount].split('');
                tCount += 1;
                let dMolds = cfg.filter(c => c === 'D');
                let totalPanels = cfg.filter(c => !['D', 'T'].includes(c)).length;
                let X = tPostLocationX - tPostInnerWidth + (tId > 1 ? this.config.panelLeftRightGap: 0);
                let W = (tPostInnerWidth - (this.config.panelLeftRightGap + (tId > 1 ? this.config.panelLeftRightGap: 0)) - (this.config.panelLeftRightGap * dMolds.length)) / totalPanels;
                for (const pc of cfg) {
                    if (pc === 'L') {
                        panels.push(this.designPanel(panelComponentCount, X, pY, W, pH, 'L'));
                        X = X + W + this.config.panelLeftRightGap;
                    } else if (pc === 'R') {
                        panels.push(this.designPanel(panelComponentCount, X, pY, W, pH, 'R'));
                        X = X + W + this.config.panelLeftRightGap;
                    } else if (pc === 'D') {
                        panels.push(this.designDMold(panelComponentCount, X - this.config.panelLeftRightGap, pY, W, pH));
                    }
                    panelComponentCount += 1;
                }
                previousTPost = panelComponent;
                tId += 1;
            }

            let cfgTmp = this.configuration.replaceAll('-', '').split('T');
            let cfg = cfgTmp[cfgTmp.length-1].split('');
            let dMolds = cfg.filter(c => c === 'D');
            let totalPanels = cfg.filter(c => !['D', 'T'].includes(c)).length;

            let X = this.frameWithoutLipCoordinates.left.X + previousTPost.location + (this.config.tPostWidth / 2) + this.config.panelLeftRightGap;
            let W = (this.framePanelCoordinates.right.X - (this.config.panelLeftRightGap * dMolds.length) - X) / totalPanels;
            for (const pc of cfg) {
                if (pc === 'L') {
                    panels.push(this.designPanel(panelComponentCount, X, pY, W, pH, 'L'));
                    X = X + W + this.config.panelLeftRightGap;
                } else if (pc === 'R') {
                    panels.push(this.designPanel(panelComponentCount, X, pY, W, pH, 'R'));
                    X = X + W + this.config.panelLeftRightGap;
                } else if (pc === 'D') {
                    panels.push(this.designDMold(panelComponentCount, X - this.config.panelLeftRightGap, pY, W, pH));
                }
            }
        }
        this.meta = {
            ...this.meta,
            customWidthPosition: this.customConfiguration.filter(cc => cc.layout === 'T').map(cc => parseFloat(cc.location).toFixed(1)).join(',')
        };
        this.frontView = {
            ...this.frontView,
            panels: panels
        }
    }
    designPanel(id, X, Y, W, H, layout) {
        let stileWidth = this.config.stileWidth;

        let midRailPos = Array.isArray(this.midrailPositions) ? this.midrailPositions.sort((a, b) => (a.value > b.value) ? 1 : -1): [];
        let stileLX1 = X + stileWidth;
        let stileLY1 = Y;
        let stileLX2 = stileLX1;
        let stileLY2 = stileLY1 + H;

        let stileRX1 = X + W - stileWidth;
        let stileRY1 = Y;
        let stileRX2 = stileRX1;
        let stileRY2 = stileRY1 + H;

        let stile = {
            left: {
                X1: stileLX1,
                Y1: stileLY1,
                X2: stileLX2,
                Y2: stileLY2,
                type: 'line'
            },
            right: {
                X1: stileRX1,
                Y1: stileRY1,
                X2: stileRX2,
                Y2: stileRY2,
                type: 'line'
            },
        };

        let [rails, louvers, louverCount, controlScrews] =  this.computeRailsLouvers(id, midRailPos, stile, Y, H, layout, 1, 1, null, null);


        let hinges = [];
        if(layout === 'L'){
           hinges = [...this.computeHinges(X, Y, H, rails)]
        }
        if(layout === 'R'){
           hinges = [...this.computeHinges(X+W, Y, H, rails)]
        }

        let [tiltRods, louverCountNew] = [...this.designTiltRods(id, louvers, layout)];

        let zones = [...this.designZones(id, rails)];
        return {
            id: id,
            outerRect: {
                X: X,
                Y: Y,
                W: W,
                H: H,
                type: 'rect'
            },
            stile : stile,
            rails: rails,
            louvers: louvers,
            hinges: hinges,
            controlScrews: controlScrews,
            tiltRods: tiltRods,
            louverCounts: louverCountNew,
            zones: zones,
            layout: layout,
            type: 'panel'
        }
    }

    designZones(id, rails){
        let zones = [];
        let zone = 1;
        if(rails.mid.length > 0){
            let midRails = rails.mid.sort((a, b) => (a.Y > b.Y) ? 1 : -1);
            zones.push({
                X: rails.top.X1,
                Y: rails.top.Y1,
                W: rails.top.X2 - rails.top.X1,
                H: midRails[0].Y - rails.top.Y1,
                type: 'rect',
                options:{
                    noStroke: true,
                    ...(this.metaView.hasOwnProperty(`Z1`) && this.metaView[`Z1`] && {
                        highlight: true
                    })
                }
            });
            zones.push({
                X: midRails[midRails.length-1].X,
                Y: midRails[midRails.length-1].Y + this.config.midRailWidth,
                W: rails.bottom.X2 - rails.bottom.X1,
                H:  rails.bottom.Y1 - midRails[midRails.length-1].Y - this.config.midRailWidth,
                type: 'rect',
                options:{
                    noStroke: true,
                    ...(this.metaView.hasOwnProperty(`Z2`) && this.metaView[`Z2`] && {
                        highlight: true
                    })
                }
            });
        }
        else {
            zones.push({
                X: rails.top.X1,
                Y: rails.top.Y1,
                W: rails.top.X2 - rails.top.X1,
                H:  rails.bottom.Y1 - rails.top.Y1,
                type: 'rect',
                options:{
                    noStroke: true,
                    ...(this.metaView.hasOwnProperty(`Z1`) && this.metaView[`Z1`] && {
                        highlight: true
                    })
                }
            });
        }
        return zones
    }

    computeRailsLouvers(id, midRailPos, stile, Y, H, layout, cntUp, cntDwn , upPos, downPos) {
        let midRails = [];
        let topRailWidth = this.config.topRailMinWidth * this.config.zoomVariable;
        let bottomRailWidth = this.config.topRailMaxWidth * this.config.zoomVariable;
        let midRailWidth = this.config.midRailWidth * this.config.zoomVariable;
        for (const pos of midRailPos) {
            let relativePos = pos.value * this.config.zoomVariable;
            let rectX = stile.left.X1;
            let rectY = this.frameOuterCoordinates.bottom.Y - relativePos - (midRailWidth / 2);
            let rectW = stile.right.X1 - stile.left.X1;
            let rectH = midRailWidth;
            midRails.push({
                X: rectX,
                Y: rectY,
                W: rectW,
                H: rectH,
                type: 'rect',
                options: {
                    fill: true,
                    ...(this.metaView.hasOwnProperty(pos.name) && this.metaView[pos.name] && {
                            highlight: true
                    })
                }
            });
        }
        midRailPos = midRailPos.map(mp => ({...mp, value: parseFloat(mp.value)}));
        midRails = midRails.sort((a, b) => (a.Y > b.Y) ? 1 : -1); //sort top to bottom
        let louvers = [];
        let louverCount = [];
        let controlScrews = [];
        if (midRails.length > 0) {
            let mCount = 1;
            let previousMidRail = midRails[0];
            for (const midRail of midRails) {
                let midRailTopZoneHeight = mCount > 1 ? (midRail.Y - Y) - (previousMidRail.Y + previousMidRail.H - Y) : (midRail.Y - Y) - this.config.topRailMinWidth - this.config.louverWidth / 2;
                let noOfLouversToTop = Math.round((midRailTopZoneHeight) / this.config.louverDistance);
                let [topLouvers, topLouversCount, topLouversControlScrews] = this.designLouver(id, mCount, noOfLouversToTop, stile.left.X1, midRail.Y, stile.right.X2, midRail.Y, layout, true, true);
                louvers = [...louvers, ...topLouvers];
                louverCount = [...louverCount, ...topLouversCount];
                controlScrews = [...controlScrews, ...topLouversControlScrews];
                previousMidRail = midRail;
                mCount += 1;
            }
            let noOfLouversToBottom = Math.abs(Math.round(((Y + H) - (previousMidRail.Y + previousMidRail.H) - this.config.louverWidth / 2 - this.config.bottomRailMinWidth) / this.config.louverDistance));
            let [bottomLouvers, bottomLouversCount, bottomLouversControlScrews] = this.designLouver(id, mCount, noOfLouversToBottom, stile.left.X1, previousMidRail.Y + previousMidRail.H, stile.right.X2, previousMidRail.Y + previousMidRail.H, layout, false, true);
            louvers = [...louvers, ...bottomLouvers];
            louverCount = [...louverCount, ...bottomLouversCount];
            controlScrews = [...controlScrews, ...bottomLouversControlScrews];

        } else {
            let noOfLouversToBottom = Math.round(((H) - this.config.louverWidth / 2 - this.config.bottomRailMinWidth - this.config.topRailMinWidth) / this.config.louverDistance);
            let railHeight = (H - ((noOfLouversToBottom) * this.config.louverDistance)) / 2;
            let [bottomLouvers, bottomLouversCount, bottomLouversControlScrews] = this.designLouver(id, 1, noOfLouversToBottom, stile.left.X1, Y + railHeight, stile.right.X2, Y + railHeight, layout, false, false);
            louvers = [...louvers, ...bottomLouvers];
            louverCount = [...louverCount, ...bottomLouversCount];
            controlScrews = [...controlScrews, ...bottomLouversControlScrews];
        }
        let topLouver = louvers.sort((a, b) => (a.Y1 > b.Y2) ? 1 : -1)[0];
        let bottomLouver = louvers.sort((a, b) => (a.Y1 > b.Y2) ? -1 : 1)[0];

        let topRailX1 = stile.left.X1;
        let topRailY1 = stile.left.Y1 + topRailWidth + (topLouver.Y1 - (stile.left.Y1 + topRailWidth)) - (midRails.length === 0 ? this.config.louverDistance : 0);
        let topRailX2 = stile.right.X1;
        let topRailY2 = topRailY1;

        let bottomRailX1 = stile.left.X1;
        let bottomRailY1 = stile.left.Y2 - bottomRailWidth - ((stile.left.Y2 - bottomRailWidth) - bottomLouver.Y1);
        let bottomRailX2 = stile.right.X1;
        let bottomRailY2 = bottomRailY1;

        let rails = {
            top: {
                X1: topRailX1,
                Y1: topRailY1,
                X2: topRailX2,
                Y2: topRailY2,
                type: 'line'
            },
            bottom: {
                X1: bottomRailX1,
                Y1: bottomRailY1,
                X2: bottomRailX2,
                Y2: bottomRailY2,
                type: 'line'
            },
            mid: midRails,
            midPos: midRailPos
        };
        if (midRailPos.length > 0) {
            let ok, newMidRailPos = null;
            if (cntUp < 20 && cntUp % 2 !== 0 ){
                if(!upPos){
                    upPos = midRailPos
                }
                [ok, newMidRailPos] = this.checkMidRailPosition(upPos, rails, louvers, louverCount,  Y, H, true);
                upPos = newMidRailPos;
            }
            else if (cntDwn < 20 && cntDwn % 2 === 0) {
                if(!downPos){
                    downPos = midRailPos
                }
                [ok, newMidRailPos] = this.checkMidRailPosition(downPos, rails, louvers, louverCount,  Y, H, false);
                downPos = newMidRailPos;
            }

            cntDwn += 1;
            cntUp += 1;
            if((ok || (!ok && (cntUp < 10 || cntDwn < 10))) && midRails === 1){
                if((rails.top.Y1 - Y) !== (Y + H - rails.bottom.Y1) && !midRailPos[0].critical){
                    let tr = (rails.top.Y1 - Y);
                    let br = (Y + H - rails.bottom.Y1);
                    let tb = (tr + br)/2;
                    let val = 0;
                    if(tb > tr){
                        val = (tb-tr)*-1;
                    }
                    else{
                        val = tb-br;
                    }

                    [ok, newMidRailPos] = this.checkMidRailPosition([{...midRailPos[0], value : midRailPos[0].value + val }], rails, louvers, louverCount,  Y, H, false);
                    return this.computeRailsLouvers(id, newMidRailPos, stile, Y, H, layout, cntUp, cntDwn);
                }
            }
            if (!ok && (cntUp < 10 || cntDwn < 10)) {
                return this.computeRailsLouvers(id, newMidRailPos, stile, Y, H, layout, cntUp, cntDwn, upPos, downPos);
            }

        }
        return [rails, louvers, louverCount, controlScrews]


    }

    checkMidRailPosition(midRailPos, rails, louvers, louverCount, Y, H, up) {
        let x = 1;
        if (up) {
            x = -1
        }
        // if(this.midrailsEvenlySpaced && this.midrails === 1){
        //     let diff = Math.abs((rails.top.Y1 - Y) - (Y + H - rails.bottom.Y1))/2;
        //     if(louverCount[0].text !== louverCount[1].text){
        //         let newMidRailPos = [{
        //             ...midRailPos[0],
        //             value: midRailPos[0].value - diff*x
        //         }];
        //         return [false, newMidRailPos]
        //     }
        // }
        if (midRailPos.length === 1) {
                if ((rails.top.Y1 - Y) > this.config.topRailMaxWidth) {
                    let diff = this.config.topRailMaxWidth - (rails.top.Y1 - Y);
                    let newMidRailPos = [{
                        ...midRailPos[0],
                        value: midRailPos[0].value - (diff*x)
                    }];
                    return [false, newMidRailPos]
                }
                if ((Y + H - rails.bottom.Y1 ) > this.config.bottomRailMaxWidth) {
                    let diff = this.config.bottomRailMaxWidth - (Y + H - rails.bottom.Y1);
                    let val = midRailPos[0].value - (diff*x);
                    let newMidRailPos = [{
                        ...midRailPos[0],
                        value: val
                    }];
                    return [false, newMidRailPos]
                }
        }
        // if (midRailPos.length > 1) {
        //     if ((rails.top.Y1 - Y) > this.config.topRailMaxWidth) {
        //         let newMidRailPos = [];
        //         for(const mp of midRailPos){
        //             let diff = this.config.topRailMaxWidth - (rails.top.Y1 - Y);
        //             newMidRailPos.push([{
        //                 ...mp,
        //                 value: mp.value - (diff*x)
        //             }]);
        //         }
        //         return [false, newMidRailPos]
        //     }
        //     if ((Y + H - rails.bottom.Y1 ) > this.config.bottomRailMaxWidth) {
        //         let newMidRailPos = [];
        //         for(const mp of midRailPos){
        //             let diff = this.config.bottomRailMaxWidth - (Y + H - rails.bottom.Y1);
        //             newMidRailPos.push([{
        //                 ...mp,
        //                 value: mp.value - (diff*x)
        //             }]);
        //         }
        //         return [false, newMidRailPos];
        //     }
        // }

        let mC = 1;
        if(midRailPos.length > 1) {
            for (const midRail of rails.mid) {
                let tl = louvers.filter(l => l.id === mC + 1).sort((a, b) => (a.Y1 > b.Y2) ? 1 : -1)[0];
                if ((midRail.Y + midRail.H) !== tl.Y1) {
                    let diff = tl.Y1 - (midRail.Y + midRail.H);
                    let newMidRailTmp = midRailPos.sort((a, b) => (a.value > b.value) ? 1 : -1).filter(p => !p.critical);
                    let newMidRailPos = [...midRailPos.filter(p => p.critical), ...newMidRailTmp.slice(1,newMidRailTmp.length), {
                        ...newMidRailTmp[0],
                        value: newMidRailTmp[0].value - (diff * x)
                    }];
                    return [false, newMidRailPos]
                }
                if (mC + 1 === rails.mid.length) {
                    break
                }
                mC += 1
            }
            return [true, midRailPos]
        }
        return [true, midRailPos]
    }

    designTiltRods(id, louvers, layout){
        let tiltRods = [];
        let louverZones = [...new Set(louvers.map(l => l.id))].sort((a, b) => (a > b) ? 1 : -1);
        let lzCount = 1;
        let zone = 1;
        let louverCount = [];
        for(const louverZone of louverZones){
            let lvrs = louvers.filter(l => l.id === louverZone);
            let split = lvrs.length;
            let lvr = 1;
            let lvrB = lvrs.length-1;
            let count = 1;
            if(lzCount > 1 && lzCount === louverZones.length){
                zone = 2;
            }
            else if (lzCount > 1){
                zone = 0;
            }
            if((this.splitTiltRod && this.midrails === 0) || (this.splitTiltRodZones.hasOwnProperty(`Z${zone}`) && this.splitTiltRodZones[`Z${zone}`]) || (lvrs.length >= 14)) {
                split = Math.ceil(lvrs.length / 2);
                lvr = 1;
                lvrB = lvrs.length - split - 1;
                count = 2
            }
            for (const l of [...Array(count).keys()]) {
                let topLouver = lvrs.sort((a, b) => (a.Y1 > b.Y2) ? 1 : -1)[lvr-1];
                let topSecondLouver = lvrs.sort((a, b) => (a.Y1 > b.Y2) ? 1 : -1)[lvr];
                let bottomLouver = lvrs.sort((a, b) => (a.Y1 > b.Y2) ? 1 : -1)[lvrB];
                tiltRods.push({
                    X1: (layout === 'L'? topLouver.X1 : topLouver.X2) + this.config.tiltRodDistance * (layout === 'L'? 1 : -1),
                    Y1: topLouver.top? topSecondLouver.Y1: topLouver.Y1,
                    X2: (layout === 'L'? topLouver.X1 : topLouver.X2) + this.config.tiltRodDistance * (layout === 'L'? 1 : -1),
                    Y2: (bottomLouver.top? bottomLouver.Y1 + this.config.louverDistance : bottomLouver.Y1) + this.config.tiltRodOverlap,
                    layout: layout,
                    type: 'line',
                    options: {
                        strokeDasharray: '5,5'
                    }
                });
                let lTmp = lvrs.sort((a, b) => (a.Y1 > b.Y2) ? 1 : -1)[(lvr-1) + parseInt((lvrB-(lvr-1)+1)/2)];
                louverCount.push({
                    X: lTmp.X1+(lTmp.X2 - lTmp.X1)/2,
                    Y: lTmp.centerY + 10,
                    text: (lvrB-(lvr-1)+1)
                });
                lvr += lvrs.length - split;
                lvrB = lvrs.length-1;

            }
            if(lvrs.length >= 14) {
                this.meta = {
                    ...this.meta,
                    splitTiltRod: true,
                    splitTiltRodZones: {
                        ...this.splitTiltRodZones,
                        ...(zone === 1 && {Z1: true}),
                        ...(zone === 2 && {Z2: true}),
                    }
                };
            }
            lzCount += 1
        }
        return [tiltRods, louverCount]
    }
    designLouver(panelId, id, no, X1, Y1, X2, Y2, layout, top, midRail){
        let x = 1;
        if(top){
            x = -1
        }
        let m = 1;
        if(!midRail){
            m = -1
        }
        if(!top){
            m = -1
        }
        let louvers = [];
        let louverCount = [];
        let tCount = 1;

        for (const l of [...Array(no).keys()]) {
            let lTmp = {
                id: id,
                X1: X1,
                Y1: Y1 + (this.config.louverDistance*(l + 1))*x,
                X2: X2,
                Y2: Y2 + (this.config.louverDistance*(l + 1))*x,
                centerY: Y1 + (this.config.louverDistance*(l + 1))*x + (this.config.louverDistance / 2)*m,
                top: top,
                midRail: midRail,
                type: 'line'
            };
            louvers.push(lTmp);
            if(tCount === parseInt(no/2)){
                louverCount.push({
                    X: lTmp.X1+(lTmp.X2 - lTmp.X1)/2,
                    Y: lTmp.centerY + 10 + (tCount === 1? this.config.louverDistance : 0),
                    text: no
                });
            }
            tCount += 1;
        }
        let controlScrews = [];
        let noOfScrews = no >= 10 ? 2 : 1;
        let r =parseInt(no/(noOfScrews+1));
        let screwPositions = [];
        if(noOfScrews > 1){
            screwPositions.push(r);
            screwPositions.push(no+1-r);
        }
        else {
            screwPositions.push(r+1);
        }
        let lCount = 1;
        for(const louver of louvers.sort((a, b) => (a.Y1 > b.Y2) ? 1 : -1)){
            if(screwPositions.includes(lCount)){
                controlScrews.push(
                    {
                        CX: layout === 'L'? louver.X1 : louver.X2,
                        CY: louver.centerY,
                        R: this.config.controlScrewRadius,
                        type: 'circle',
                       ...(panelId === 1 && {TX: (layout === 'L'? louver.X1 : louver.X2) + 20*(layout === 'L'? 1 : -3),TY: louver.centerY +10,text: `T${lCount}`})
                    }
                )
            }
            lCount += 1
        }


        return [louvers, louverCount, controlScrews]
    }
    computeHinges(X, Y, H, rails){
        let hinges = [];
        hinges.push(this.designHinge(1, X, Y+this.config.topHingeDistance));
        hinges.push(this.designHinge(2, X, Y+H-this.config.bottomHingeDistance-this.config.hingeLength));
        let hingeDistance =  (Y+H-this.config.bottomHingeDistance-(this.config.hingeLength/2))-(Y+this.config.topHingeDistance+(this.config.hingeLength/2));
        let extraHinges = Math.round(hingeDistance/this.config.maxHingeDistance);
        let hingeY = Y+this.config.topHingeDistance;
        for (const h of [...Array(extraHinges).keys()]) {
            let v = hingeDistance/(extraHinges+1);
            hingeY += v;
            hinges.push(this.designHinge(3+h, X, hingeY));
        }
        hinges = hinges.filter(h => ![1,2].includes(h.id));
        hinges.push(this.designHinge(1, X, Y+supplierOverrides(this.config.name.toLowerCase()).hingeDistanceOverrideFunction( rails.top.Y1 - this.framePanelCoordinates.top.Y)));
        hinges.push(this.designHinge(2, X, Y+H-supplierOverrides(this.config.name.toLowerCase()).hingeDistanceOverrideFunction(this.framePanelCoordinates.bottom.Y - rails.bottom.Y1)-this.config.hingeLength));
        return hinges
    }
    designHinge(id, X, Y){
        return {
            id: id,
            line: {
                X1: X,
                Y1: Y,
                X2: X,
                Y2: Y+this.config.hingeLength,
                type: 'line',
                options: {
                    strokeWidth: 4,
                }
            },
            type: 'hinge'
        }
    }
    designTPost(id, tId, pX, pY, pW, pH){
        return {
            id: id,
            outerRect: {
                X: pX,
                Y: pY,
                W: pW,
                H: pH,
                type: 'rect',
                ...(this.metaView.hasOwnProperty(`T${tId}`) && this.metaView[`T${tId}`] && {
                    options: {
                        highlight: true
                    }
                })
            },
            type: 'tPost'
        }
    }
    designDMold(id, pX, pY, pW, pH){
        return {
            id: id,
            line: {
                X1: pX,
                Y1: pY,
                X2: pX,
                Y2: pY+pH,
                type: 'line',
                options: {
                    strokeWidth:3
                }
            },
            type: 'dMold'
        }
    }

    designSideRailTop(rect){
        let path = [{
            m: {
                X: rect.X,
                Y: rect.Y,
            }
        },
            {
                h: rect.W
            },
            {
                v: (rect.H - this.config.topRailLip)
            },
            {
                h: this.config.topRailLip * -1
            },
            {
                v: this.config.topRailLip
            },
            {
                h: (rect.W - this.config.topRailLip)*-1
            },
            {
                v: rect.H*-1
            },
        ];
        return path
    }
    designSideRailBottom(rect){
        let path = [{
            m: {
                X: rect.X,
                Y: rect.Y + this.config.topRailLip,
            }
        },
            {
                h: this.config.topRailLip
            },
            {
                v: this.config.topRailLip*-1
            },
            {
                h: rect.W - this.config.topRailLip
            },
            {
                v: rect.H
            },
            {
                h: rect.W*-1
            },
            {
                v: (rect.H - this.config.topRailLip)*-1
            },
        ];
        return path
    }
    designSideMidRail(rect){
        let path = [{
            m: {
                X: rect.X,
                Y: rect.Y + this.config.topRailLip,
            }
        },
            {
                h: this.config.topRailLip
            },
            {
                v: this.config.topRailLip*-1
            },
            {
                h: rect.W - this.config.topRailLip
            },
            {
                v: rect.H - this.config.topRailLip
            },
            {
                h: this.config.topRailLip*-1
            },
            {
                v: this.config.topRailLip
            },
            {
                h: (rect.W-this.config.topRailLip)*-1
            },
            {
                v: (rect.H - this.config.topRailLip)*-1
            },
        ];
        return path
    }

    designSidePanel(outerFrame) {
        let panel = this.frontView.panels.filter(p => p.type === 'panel')[0];
        let midrails = [];
        let louvers = [];
        for(const midrail of panel.rails.mid){
            let mid = {
                X: outerFrame.X + outerFrame.W + this.config.panelLeftRightGap,
                Y: midrail.Y,
                W: outerFrame.W - this.config.panelLeftRightGap,
                H: midrail.H,
                type: 'rect'
            };
            midrails.push({
                rect: mid,
                path: this.designSideMidRail(mid),
                type: 'path',
                options: {
                    fill: true,
                    ...(midrail.options.hasOwnProperty('highlight') && midrail.options.highlight && {
                        highlight: true
                    })
                }
            })
        }
        for(const louver of panel.louvers){
            louvers.push({
                CX: outerFrame.X + outerFrame.W + this.config.panelLeftRightGap + (outerFrame.W - this.config.panelLeftRightGap)/2,
                CY: louver.centerY,
                RX: (outerFrame.W - this.config.panelLeftRightGap)/3,
                RY: this.config.louverWidth/2,
                type: 'ellipse'
            });
        }
        let top = {
            X: outerFrame.X + outerFrame.W + this.config.panelLeftRightGap,
            Y: panel.outerRect.Y,
            H: panel.rails.top.Y1 - panel.outerRect.Y,
            W: outerFrame.W - this.config.panelLeftRightGap
        };
        let bottom = {
            X: outerFrame.X + outerFrame.W + this.config.panelLeftRightGap,
            Y: panel.rails.bottom.Y1,
            H: (panel.outerRect.Y + panel.outerRect.H) - panel.rails.bottom.Y1,
            W: outerFrame.W - this.config.panelLeftRightGap
        };
        return {
            rails: {
                top: {
                    rect: top,
                    path: this.designSideRailTop(top),
                    type: 'path'
                },
                bottom: {
                    rect: bottom,
                    path: this.designSideRailBottom(bottom),
                    type: 'path'
                },
                mid: midrails
            },
            louvers: louvers
        }
    }
    designLFrameSideView(shutter, X, Y, W, H, invert){
        let x = 1;
        if(invert){
            x = -1;
        }
        let path = [{
            m: {
                X: X,
                Y: Y
            }
        },
            {
                v: ((shutter.frameWidth+10))*x
            },
            {
                h: (W + shutter.config.sideViewWidth)
            },
            {
                v: ((shutter.frameWidth - shutter.config.panelTopBottomGap)*-1)*x
            },
            {
                h: (shutter.config.sideViewWidth)*-1
            },
            {
                v: ((10 + shutter.config.panelTopBottomGap)*-1)*x
            },
            {
                h: (W*-1)
            },
        ];
        return path
    }
    designZFrameWithSillSideView(shutter, X, Y, W, H, invert){
        if(invert){
            return shutter.designZFrameSideView(shutter, X, Y, W, H, invert)
        }
        let x = 1;
        let path = [{
            m: {
                X: X,
                Y: Y
            }
        },
            {
                v: (((shutter.frameWidth-(x > 0 ? shutter.config.frame[shutter.frameStyle].frameBottomCut:0))))*x
            },
            {
                h: (W+20)
            },
            // {
            //     v: ((((shutter.frameWidth-(x > 0 ? shutter.config.frame[shutter.frameStyle].frameBottomCut:0))+10)*0.3))*x
            // },
            {
                h: (10)
            },
            {
                v: ((((shutter.frameWidth-(x > 0 ? shutter.config.frame[shutter.frameStyle].frameBottomCut:0))+10)*0.3)*-1)*x
            },
            {
                h: ((30)*-1)
            },
            {
                v: ((((shutter.frameWidth-(x > 0 ? shutter.config.frame[shutter.frameStyle].frameBottomCut:0))+20)*0.3)*-1)*x
            },
            {
                h: (W*-1)
            },
        ];
        return path
    }
    designSillFrameSideView(shutter, X, Y, W, H, invert){
        let x = 1;
        if(invert){
           x = -1
        }

        let path = [{
            m: {
                X: X,
                Y: Y
            }
        },
            {
                v: (shutter.config.frame['Sill Frame'].frameWidth+10 + shutter.config.panelTopBottomGap)*x
            },
            {
                h: (W+20)
            },
            {
                h: (10)
            },
            {
                v: ((((shutter.config.frame['Sill Frame'].frameWidth)))*-1)*x
            },
            {
                h: ((30)*-1)
            },
            {
                v: (((10 + shutter.config.panelTopBottomGap))*-1)*x
            },
            {
                h: (W*-1)
            },
        ];
        return path
    }
    designNoFrame(shutter, X, Y, W, H, invert){
        let x = 1;
        if(invert){
            x = -1
        }

        let path = [{
            m: {
                X: X,
                Y: Y
            }
        }
        ];
        return path
    }
    designZFrameSideView(shutter, X, Y, W, H, invert){
        let x = 1;
        if(invert){
            x = -1;
        }
        let path = [{
            m: {
                X: X,
                Y: Y
            }
        },
            {
                v: ((shutter.frameWidth+10) * 0.7)*x
            },
            {
                h: (W+20)
            },
            {
                v: (((shutter.frameWidth+10)*0.3))*x
            },
            {
                h: (10)
            },
            {
                v: (((shutter.frameWidth+10)*0.7)*-1)*x
            },
            {
                h: ((30)*-1)
            },
            {
                v: (((shutter.frameWidth+10)*0.3)*-1)*x
            },
            {
                h: (W*-1)
            },
        ];
        return path
    }
    drawSideView() {
        let panel = this.frontView.panels[0];
        let frameRectX = this.frontView.outerRect.X + this.frontView.outerRect.W + this.config.sideViewRelativePosition;
        let frameRectY = panel.outerRect.Y+10;
        let frameRectW = this.config.sideViewWidth;
        let frameRectH = panel.outerRect.H-20;
        let outerFrame =  {
            X: frameRectX,
            Y: frameRectY,
            W: frameRectW,
            H: frameRectH,
            type: 'rect',
            options: {
                ...(this.frameStyle === 'No Frame' && {noStroke: true})
            }
        };
        this.sideView = {
            outerFrame: outerFrame,
            panel: this.designSidePanel(outerFrame),
            frame: {
                top: {
                    path: this.drawFrame(this.frameFunctions[this.frameStyleDictionary.top].side, this, frameRectX, frameRectY, frameRectW, frameRectH, true),
                    type: 'path'
                },
                bottom: {
                    path: this.drawFrame(this.frameFunctions[this.frameStyleDictionary.bottom].side, this, frameRectX, frameRectY+frameRectH, frameRectW, frameRectH, false),
                    type: 'path'
                }
            }
        }
    }
    designLFrameTopView(shutter, X, Y, W, H, invert){
        let x = 1;
        if(invert){
            x = -1;
        }
        let path = [{
            m: {
                X: X,
                Y: Y
            }
        },
            {
                h: (shutter.frameWidth+10)*x
            },
            {
                v: (H + shutter.config.topViewWidth + shutter.config.panelLeftRightGap)
            },
            {
                h: (((shutter.frameWidth))*-1)*x
            },
            {
                v: (shutter.config.topViewWidth + shutter.config.panelLeftRightGap) *-1
            },
            {
                h: ((10)*-1)*x
            },
            {
                v: (H*-1)
            },
        ];
        return path
    }
    designZFrameTopView(shutter, X, Y, W, H, invert){
        let x = 1;
        if(invert){
            x = -1;
        }
        let path = [{
            m: {
                X: X,
                Y: Y
            }
        },
            {
                h: ((shutter.frameWidth - shutter.config.frame[shutter.frameStyle].lip +10))*x
            },
            {
                v: (H+20)
            },
            {
                h: (shutter.config.frame[shutter.frameStyle].lip)*x
            },
            {
                v: (10)
            },
            {
                h: ((shutter.frameWidth)*-1)*x
            },
            {
                v: ((30)*-1)
            },
            {
                h: (10*-1)*x
            },
            {
                v: (H*-1)
            },
        ];
        return path
    }
    designSillFrameTopView(shutter, X, Y, W, H, invert){
        let x = 1;
        if(invert){
            x = -1;
        }
        let path = [{
            m: {
                X: X,
                Y: Y
            }
        },
            {
                h: (shutter.config.frame['Sill Frame'].frameWidth+10 + shutter.config.panelLeftRightGap)*x
            },
            {
                v: (H+30)
            },
            {
                h: ((((shutter.config.frame['Sill Frame'].frameWidth)))*-1)*x
            },
            {
                v: ((30)*-1)
            },
            {
                h: (((10 + shutter.config.panelTopBottomGap))*-1)*x
            },
            {
                v: (H*-1)
            },
            // {
            //     h: (10*-1)*x
            // },
            // {
            //     v: (H*-1)
            // },
        ];
        return path
    }
    drawFrame (func, shutter, X, Y, W, H, invert){
        return func(shutter, X, Y, W, H, invert)
    }
    designTopPanel(outerFrame) {
        let panels = [];
        let paths = [];
        let previousType = null;
        let count = 0;
        for(const panel of this.frontView.panels){
            if(panel.type === 'panel') {
                if(previousType !== 'dMold') {
                    panels.push({
                        X: panel.outerRect.X,
                        Y: outerFrame.Y + outerFrame.H + this.config.panelTopBottomGap - this.config.topViewStileWidthExtension,
                        W: panel.stile.left.X1 - panel.outerRect.X,
                        H: this.config.topViewWidth + this.config.topViewStileWidthExtension,
                        strokeWidth: 2,
                        type: 'leftStile'
                    });
                }
                panels.push({
                    X: panel.outerRect.X + (panel.stile.left.X1 - panel.outerRect.X)/3,
                    Y: outerFrame.Y + outerFrame.H + this.config.panelTopBottomGap - this.config.topViewStileWidthExtension,
                    W: (panel.stile.left.X1 - panel.outerRect.X)/3,
                    H: this.config.topViewWidth + this.config.topViewStileWidthExtension,
                    strokeWidth: 3,
                    type: 'leftStile'
                });
                panels.push({
                    X: panel.stile.right.X1,
                    Y: outerFrame.Y + outerFrame.H + this.config.panelTopBottomGap - this.config.topViewStileWidthExtension,
                    W: panel.outerRect.X +  panel.outerRect.W - panel.stile.right.X1,
                    H: this.config.topViewWidth + this.config.topViewStileWidthExtension,
                    strokeWidth: 2,
                    type: 'rightStile'
                });
                panels.push({
                    X: panel.stile.right.X1 + (panel.outerRect.X +  panel.outerRect.W - panel.stile.right.X1)/3,
                    Y: outerFrame.Y + outerFrame.H + this.config.panelTopBottomGap - this.config.topViewStileWidthExtension,
                    W: (panel.outerRect.X +  panel.outerRect.W - panel.stile.right.X1)/3,
                    H: this.config.topViewWidth + this.config.topViewStileWidthExtension,
                    strokeWidth: 3,
                    type: 'rightStile'
                });
                panels.push({
                    X: panel.outerRect.X + (panel.stile.left.X1 - panel.outerRect.X)/3,
                    Y: outerFrame.Y + outerFrame.H + this.config.panelTopBottomGap,
                    W: panel.outerRect.W - (panel.stile.left.X1 - panel.outerRect.X)/3,
                    H: this.config.topViewWidth,
                    type: 'panel'
                });

            }
            if (panel.type === 'dMold') {
                paths.push({
                    path: [{
                    m: {
                        X: panel.line.X1 - this.config.topViewDMoldWidth,
                        Y: outerFrame.Y + outerFrame.H + this.config.panelTopBottomGap + this.config.topViewWidth,
                    }
                    },
                    {
                        v: this.config.topViewDMoldWidth
                    },
                    {
                        h: this.config.topViewDMoldWidth*2
                    },
                    {
                        v: this.config.topViewDMoldWidth*-1
                    },
                    {
                        h: (this.frontView.panels[count+1].stile.left.X1 - this.frontView.panels[count+1].outerRect.X)/3
                    }
                    ],
                    type: "path",
                    options:{
                        strokeWidth: 3,

                    }
                });
                paths.push({
                    path: [
                        {
                            m: {
                                X: this.frontView.panels[count+1].outerRect.X + (this.frontView.panels[count+1].stile.left.X1 - this.frontView.panels[count+1].outerRect.X)/3,
                                Y: outerFrame.Y + outerFrame.H + this.config.panelTopBottomGap- this.config.topViewStileWidthExtension,
                            },

                        },
                        {
                            h: (((this.frontView.panels[count+1].stile.left.X1 - this.frontView.panels[count+1].outerRect.X)/3)+(this.config.panelLeftRightGap*2))*-1
                        }
                    ],
                    type: "path",
                    options:{
                        strokeWidth: 3
                    }
            })
            }
            if (panel.type === 'tPost') {
                paths.push({
                    path: [{
                            m: {
                                X: panel.outerRect.X - this.config.tPostLip - this.config.panelLeftRightGap,
                                Y: outerFrame.Y
                            }
                            },
                            {
                                h: this.config.tPostLip*2+this.config.panelLeftRightGap*2+panel.outerRect.W
                            },
                            {
                                v: outerFrame.H
                            },
                            {
                                h: (this.config.tPostLip+this.config.panelLeftRightGap)*-1
                            },
                            {
                                v: this.config.topViewWidth + this.config.panelTopBottomGap
                            },
                            {
                                h: panel.outerRect.W *-1
                            },
                            {
                                v: (this.config.topViewWidth + this.config.panelTopBottomGap)*-1
                            },
                            {
                                h: (this.config.tPostLip +this.config.panelLeftRightGap)*-1
                            },
                            {
                                v: outerFrame.H*-1
                            },
                        ],
                    type: "path",
                    options:{
                        strokeWidth: 3,
                        ...(panel.outerRect.hasOwnProperty('options') && panel.outerRect.options.hasOwnProperty('highlight') && panel.outerRect.options.highlight && {
                            highlight: true
                        })
                    }
                });
            }
            previousType = panel.type;
            count += 1;
        }
        return {
            rects: panels,
            paths: paths
        };
    }

    drawTopView() {
        let frameRectX = this.frontView.innerRect.X+10;
        let frameRectY = this.frontView.outerRect.Y - this.config.topViewRelativePosition;
        let frameRectW = this.frontView.innerRect.W-20;
        let frameRectH = this.frameStyle === 'No Frame'? 0 : this.config.topViewWidth;
        let outerFrame = {
            X: frameRectX,
            Y: frameRectY,
            W: frameRectW,
            H: frameRectH,
            type: 'rect',
            options: {
                ...(this.frameStyle === 'No Frame' && {noStroke: true})
            }
        };
        this.topView = {
            outerFrame: outerFrame,
            panels: this.designTopPanel(outerFrame),
            frame: {
                left: {
                    path : this.drawFrame(this.frameFunctions[this.frameStyleDictionary.left].top, this, frameRectX, frameRectY, frameRectW, frameRectH, true),
                    type: 'path'
                },
                right: {
                    path: this.drawFrame(this.frameFunctions[this.frameStyleDictionary.right].top, this,frameRectX+frameRectW, frameRectY, frameRectW, frameRectH, false),
                    type: 'path'
                }
            }
        }
    }
    drawMeasurements(){
        this.measurements = {
            top: this.drawTopMeasurements(),
            side: this.drawSideMeasurements(),
            miscellaneous: this.drawMiscellaneousMeasurements()
        }
    }
    drawTopMeasurements(){
        let measurements = new Measurements();
        let measurementStart = 0;
        if(this.panelWidthOption === 'Custom') {
            measurementStart = 150;
        }
        //width

        // measurements.push({ name: "",
        //     X1: this.frontView.outerRect.X,
        //     Y1: this.topView.outerFrame.Y - 100,
        //     X2: this.frontView.outerRect.X + this.frontView.outerRect.W,
        //     Y2: this.topView.outerFrame.Y - 100,
        //     measurement: Math.abs(this.frontView.outerRect.X - (this.frontView.outerRect.X + this.frontView.outerRect.W))
        // });
        //
        measurements.add({
            name: "frame_width",
            line: {
                X1: this.frameOuterCoordinates.left.X,
                Y1: this.frameOuterCoordinates.top.Y - 50,
                X2: this.frameOuterCoordinates.right.X,
                Y2: this.frameOuterCoordinates.top.Y - 50,
            },
            display: {
                customer: false,
                factory: ['jht','viewscape']
            },
            vertical: false
        });


        measurements.add({
            name: "frame_inner_width",
            line: {
                X1: this.frameInnerCoordinates.left.X,
                Y1: this.frameOuterCoordinates.top.Y - 100,
                X2: this.frameInnerCoordinates.right.X,
                Y2: this.frameOuterCoordinates.top.Y - 100,
            },
            display: {
                customer: false,
                factory: ['viewscape']
            },
            vertical: false
        });

        for (const panel of this.frontView.panels.filter(panel => panel.type === 'panel').sort((a, b) => (a.outerRect.X > b.outerRect.X) ? 1 : -1)){
            measurements.add({
                id: panel.id,
                name: "",
                line: {
                    X1: panel.outerRect.X,
                    Y1: panel.outerRect.Y - 180,
                    X2: panel.outerRect.X + panel.outerRect.W,
                    Y2: panel.outerRect.Y - 180,
                },
                display: {
                    customer: false,
                    factory: ['jht','viewscape']
                },
                vertical: false
            });
            console.log(this.frameStyleDictionary.left);
            console.log(this.config.frame);
            measurements.add({
                id: panel.id,
                name: "top_rail_width",
                line: {
                    X1: panel.stile.left.X1 - (this.config.frame[this.frameStyleDictionary.left].hasOwnProperty('panelLeftRightGap')? this.config.frame[this.frameStyleDictionary.left].panelLeftRightGap : this.config.panelLeftRightGap), //don't know why
                    Y1: panel.outerRect.Y - 230,
                    X2: panel.stile.right.X1 + (this.config.frame[this.frameStyleDictionary.left].hasOwnProperty('panelLeftRightGap')? this.config.frame[this.frameStyleDictionary.left].panelLeftRightGap : this.config.panelLeftRightGap), //don't know why
                    Y2: panel.outerRect.Y - 230
                },
                display: {
                    customer: false,
                    factory: ['viewscape']
                },
                vertical: false
            });
            measurements.add({
                id: panel.id,
                name: "louver_width",
                line: {
                    X1: panel.stile.left.X1,
                    Y1: panel.rails.top.Y1 + 40,
                    X2: panel.stile.right.X1,
                    Y2: panel.rails.top.Y1 + 40,
                },
                display: {
                    customer: true,
                    factory: ['jht','viewscape']
                },
                vertical: false
            });
        }
        let cnt = 1;
        for(const c of this.customConfiguration.filter(c => ['T'].includes(c.layout)).sort((a, b) => (a.location > b.location) ? 1 : -1)){
            measurements.add({
                id: c.id,
                name: "tPost",
                line: {
                    X1: this.frameWithoutLipCoordinates.left.X,
                    Y1: this.topView.outerFrame.Y - (50 * cnt),
                    X2: this.frameWithoutLipCoordinates.left.X + c.location,
                    Y2: this.topView.outerFrame.Y - (50 * cnt),
                },
                display: {
                    customer: true,
                    factory: ['jht','viewscape']
                },
                vertical: false
            });
            cnt +=1
        }
        let mts = measurements.measurements;
        let topMostMeasurement = mts.sort((a, b) => (a.Y1 > b.Y1) ? 1 : -1)[0].Y1 < this.topView.outerFrame.Y ? mts.sort((a, b) => (a.Y1 > b.Y1) ? 1 : -1)[0].Y1 - 50 : this.topView.outerFrame.Y -50;
        measurements.add({
            name: "width",
            line: {
                X1: this.frameWithoutLipCoordinates.left.X,
                Y1: topMostMeasurement,
                X2: this.frameWithoutLipCoordinates.right.X,
                Y2: topMostMeasurement
            },
            display: {
                customer: true,
                factory: ['jht','viewscape']
            },
            vertical: false
        });
        return measurements.measurements
    }

    drawSideMeasurements(){
        let measurements = new Measurements();
        // height

        //top rail without lip
        measurements.add({
            name: "",
            line: {
                X1: this.sideView.outerFrame.X + this.sideView.outerFrame.W + this.config.sideViewWidth + 20,
                Y1: this.sideView.panel.rails.top.rect.Y,
                X2: this.sideView.outerFrame.X + this.sideView.outerFrame.W + this.config.sideViewWidth + 20,
                Y2: this.sideView.panel.rails.top.rect.Y + this.sideView.panel.rails.top.rect.H - this.config.topRailLip,
            },
            display: {
                customer: false,
                factory: ['viewscape']
            },
            vertical: true
        });
        //bottom rail without lip
        measurements.add({
            name: "",
            line: {
                X1: this.sideView.outerFrame.X + this.sideView.outerFrame.W + this.config.sideViewWidth + 20,
                Y1: this.sideView.panel.rails.bottom.rect.Y + this.config.bottomRailLip,
                X2: this.sideView.outerFrame.X + this.sideView.outerFrame.W + this.config.sideViewWidth + 20,
                Y2: this.sideView.panel.rails.bottom.rect.Y + this.sideView.panel.rails.bottom.rect.H,
            },
            display: {
                customer: false,
                factory: ['viewscape']
            },
            vertical: true
        });

        //top rail with lip
        measurements.add({
            name: "top_rail_height",
            line: {
                X1: this.sideView.outerFrame.X + this.sideView.outerFrame.W + this.config.sideViewWidth + 70,
                Y1: this.sideView.panel.rails.top.rect.Y,
                X2: this.sideView.outerFrame.X + this.sideView.outerFrame.W + this.config.sideViewWidth + 70,
                Y2: this.sideView.panel.rails.top.rect.Y + this.sideView.panel.rails.top.rect.H,
            },
            display: {
                customer: false,
                factory: ['jht','viewscape']
            },
            vertical: true
        });
        //bottom rail with lip
        measurements.add({
            name: "bottom_rail_height",
            line: {
                X1: this.sideView.outerFrame.X + this.sideView.outerFrame.W + this.config.sideViewWidth + 70,
                Y1: this.sideView.panel.rails.bottom.rect.Y,
                X2: this.sideView.outerFrame.X + this.sideView.outerFrame.W + this.config.sideViewWidth + 70,
                Y2: this.sideView.panel.rails.bottom.rect.Y + this.sideView.panel.rails.bottom.rect.H,
            },
            display: {
                customer: false,
                factory: ['jht','viewscape']
            },
            vertical: true
        });

        //top rail to pin
        measurements.add({
            name: "top_rail_to_pin",
            line: {
                X1: this.sideView.outerFrame.X + this.sideView.outerFrame.W + this.config.sideViewWidth + 110,
                Y1: this.sideView.panel.rails.top.rect.Y,
                X2: this.sideView.outerFrame.X + this.sideView.outerFrame.W + this.config.sideViewWidth + 110,
                Y2: this.sideView.panel.rails.top.rect.Y + this.sideView.panel.rails.top.rect.H + this.config.louverDistance / 2,
            },
            display: {
                customer: false,
                factory: ['jht','viewscape']
            },
            vertical: true
        });
        //bottom rail to pin
        measurements.add({
            name: "bottom_rail_to_pin",
            line: {
                X1: this.sideView.outerFrame.X + this.sideView.outerFrame.W + this.config.sideViewWidth + 110,
                Y1: this.sideView.panel.rails.bottom.rect.Y - this.config.louverDistance / 2,
                X2: this.sideView.outerFrame.X + this.sideView.outerFrame.W + this.config.sideViewWidth + 110,
                Y2: this.sideView.panel.rails.bottom.rect.Y + this.sideView.panel.rails.bottom.rect.H,
            },
            display: {
                customer: false,
                factory: ['jht','viewscape']
            },
            vertical: true
        });

        //louver distance
        measurements.add({
            name: "louver_distance",
            line: {
                X1: this.sideView.outerFrame.X + this.sideView.outerFrame.W + this.config.sideViewWidth + 20,
                Y1: this.sideView.panel.rails.top.rect.Y + this.sideView.panel.rails.top.rect.H + this.config.louverDistance / 2,
                X2: this.sideView.outerFrame.X + this.sideView.outerFrame.W + this.config.sideViewWidth + 20,
                Y2: this.sideView.panel.rails.top.rect.Y + this.sideView.panel.rails.top.rect.H + this.config.louverDistance / 2 + this.config.louverDistance,
            },
            display: {
                customer: false,
                factory: ['viewscape']
            },
            vertical: true
        });
        let mCnt = 0;
        for(const midRail of this.sideView.panel.rails.mid.sort((a, b) => (a.Y > b.Y) ? 1 : -1)){
            measurements.add({
                name: "",
                line: {
                    X1: this.sideView.outerFrame.X + this.sideView.outerFrame.W + this.config.sideViewWidth + 20,
                    Y1: midRail.rect.Y,
                    X2: this.sideView.outerFrame.X + this.sideView.outerFrame.W + this.config.sideViewWidth + 20,
                    Y2: midRail.rect.Y + midRail.rect.H - this.config.midRailLip,
                },
                display: {
                    customer: false,
                    factory: ['jht','viewscape'] //todo: no impact on 238121
                },
                vertical: true
            });
            measurements.add({
                name: "",
                line: {
                    X1: this.sideView.outerFrame.X + this.sideView.outerFrame.W + this.config.sideViewWidth + 60,
                    Y1: midRail.rect.Y - this.config.louverDistance / 2,
                    X2: this.sideView.outerFrame.X + this.sideView.outerFrame.W + this.config.sideViewWidth + 60,
                    Y2: midRail.rect.Y + midRail.rect.H + this.config.louverDistance / 2,
                },
                display: {
                    customer: false,
                    factory: ['jht','viewscape'] //todo: no impact on 238121
                },
                vertical: true
            });
            measurements.add({
                name: "",
                line: {
                    X1: this.sideView.outerFrame.X + this.sideView.outerFrame.W + this.config.sideViewWidth + (140 + mCnt * 30),
                    Y1: midRail.rect.Y + midRail.rect.H - midRail.rect.H / 2,
                    X2: this.sideView.outerFrame.X + this.sideView.outerFrame.W + this.config.sideViewWidth + (140 + mCnt * 30),
                    Y2: this.frontView.innerRect.Y + this.frontView.innerRect.H - this.config.panelTopBottomGap,
                },
                display: {
                    customer: false,
                    factory: ['jht','viewscape'] //todo: no impact on 238121
                },
                vertical: true
            });

            measurements.add({
                name: "",
                line: {
                    X1: this.sideView.outerFrame.X + this.sideView.outerFrame.W + this.config.sideViewWidth + (170 + mCnt * 30),
                    Y1: midRail.rect.Y + midRail.rect.H - midRail.rect.H / 2,
                    X2: this.sideView.outerFrame.X + this.sideView.outerFrame.W + this.config.sideViewWidth + (170 + mCnt * 30),
                    Y2: this.frameOuterCoordinates.bottom.Y,
                },
                display: {
                    customer: true,
                    factory: ['jht','viewscape'] //todo: no impact on 238121
                },
                vertical: true
            });

            // measurements.push({ name: "",
            //     X1: this.sideView.outerFrame.X + this.sideView.outerFrame.W + this.config.sideViewWidth + (40+mCnt*30),
            //     Y1: midRail.rect.Y + midRail.rect.H - midRail.rect.H/2,
            //     X2: this.sideView.outerFrame.X + this.sideView.outerFrame.W + this.config.sideViewWidth + (40+mCnt*30),
            //     Y2: this.frontView.outerRect.Y + this.frontView.outerRect.H - (this.config.frame[this.frameStyle].frameBottomCut === 0 ? this.config.frame[this.frameStyle].lip : 0),
            //     measurement: Math.abs(this.frontView.outerRect.Y + this.frontView.outerRect.H - (this.config.frame[this.frameStyle].frameBottomCut === 0 ? this.config.frame[this.frameStyle].lip : 0) - (midRail.rect.Y + midRail.rect.H - midRail.rect.H/2)),
            //     display: {
            //         customer: true
            //     }
            // });


            mCnt += 2
        }
        let mts = measurements.measurements;
        let filtered = mts.filter(m => determineVisibility(m, this.view, this.config.name.toLowerCase()));
        let rightMostMeasurement = filtered.length > 0 ? filtered.sort((a, b) => (a.X1 > b.X1) ? -1 : 1)[0].X1 : this.sideView.outerFrame.X + 50;
        //panel height inner #TODO confirm top bottom panel gap
        measurements.add({
            name: "panel_height",
            line: {
                X1: rightMostMeasurement + 50,
                Y1: this.frontView.panels.filter(p => p.type === 'panel')[0].outerRect.Y,
                X2: rightMostMeasurement + 50,
                Y2: this.frontView.panels.filter(p => p.type === 'panel')[0].outerRect.Y + this.frontView.panels.filter(p => p.type === 'panel')[0].outerRect.H,
            },
            display: {
                customer: false,
                factory: ['jht','viewscape']
            },
            vertical: true
        });
        mts = measurements.measurements;
        let t = mts.filter(m => determineVisibility(m, this.view, this.config.name.toLowerCase())).sort((a, b) => (a.X1 > b.X1) ? -1 : 1)
        rightMostMeasurement = t.length > 0 ? t[0].X1: 0;
        //panel height outer
        measurements.add({
            name: "panel_outer_height",
            line: {
                X1: rightMostMeasurement + 50,
                Y1: this.frameInnerCoordinates.top.Y,
                X2: rightMostMeasurement + 50,
                Y2: this.frameInnerCoordinates.bottom.Y,
            },
            display: {
                customer: false,
                factory: ['viewscape']
            },
            vertical: true
        });

        measurements.add({
            name: "",
            line: {
                X1: this.sideView.outerFrame.X - 70,
                Y1: this.frameOuterCoordinates.top.Y,
                X2: this.sideView.outerFrame.X - 70,
                Y2: this.frameOuterCoordinates.bottom.Y,
            },
            display: {
                customer: false,
                factory: ['jht','viewscape'] // highlighting conflicts, side measurements
            },
            vertical: true
        });
        if(this.config.frame[this.frameStyle].lip > 0 || true){ // this will always be true?
            measurements.add({ name: "height",
                line: {
                    X1: this.sideView.outerFrame.X - 30,
                    Y1: this.frameWithoutLipCoordinates.top.Y,
                    X2: this.sideView.outerFrame.X - 30,
                    Y2: this.frameWithoutLipCoordinates.bottom.Y,
                },
                display: {
                    customer: true,
                    factory: ['jht','viewscape'] // highlighting conflicts, side measurements
                },
                vertical: true
            });
        }

        let hCount = 1;
        for(const hinge of this.frontView.panels.filter(p => p.type === 'panel')[0].hinges.sort((a, b) => (a.line.Y1 > b.line.Y1) ? 1 : -1)) {
            measurements.add({
                name: "",
                line: {
                    X1: this.frameOuterCoordinates.left.X - 50 * hCount,
                    Y1: this.framePanelCoordinates.top.Y,
                    X2: this.frameOuterCoordinates.left.X - 50 * hCount,
                    Y2: hinge.line.Y1,
                },
                display: {
                    customer: false,
                    factory: ['jht','viewscape']
                },
                vertical: true
            });
            hCount += 1
        }
        let cSCount = 1;
        for(const controlScrew of this.frontView.panels.filter(p => p.type === 'panel')[0].controlScrews.sort((a, b) => (a.CY > b.CY) ? -1 : 1)) {
            measurements.add({
                name: "",
                line: {
                    X1: this.frameOuterCoordinates.right.X + 30 * cSCount,
                    Y2: this.framePanelCoordinates.bottom.Y,
                    X2: this.frameOuterCoordinates.right.X + 30 * cSCount,
                    Y1: controlScrew.CY,
                },
                display: {
                    customer: false,
                    factory: ['jht','viewscape'] // not highlighted, bottom right heights
                },
                vertical: true
            });
            cSCount += 1
        }
        for(const tiltRod of this.frontView.panels.filter(p => p.type === 'panel')[0].tiltRods) {
            measurements.add({
                name: "",
                line: {
                    X1: tiltRod.X1 + (50 * (tiltRod.layout === 'R' ? -1 : 1)),
                    Y1: tiltRod.Y1,
                    X2: tiltRod.X2 + (50 * (tiltRod.layout === 'R' ? -1 : 1)),
                    Y2: tiltRod.Y2,
                },
                display: {
                    customer: false,
                    factory: ['viewscape']
                },
                vertical: true
            });
            cSCount += 1
        }


        return measurements.measurements
    }
    drawMiscellaneousMeasurements() {
        let measurements = [];
        measurements.push({ name: "",
            X1: this.frameInnerCoordinates.left.X,
            Y1: this.frontView.innerRect.Y + this.frontView.innerRect.H,
            X2:  this.frameInnerCoordinates.left.X,
            Y2: this.frontView.innerRect.Y + this.frontView.innerRect.H + 60,
            type: 'line',
            display: {
                customer: false,
                factory: ['viewscape']  //todo: no impact on 238121
            }
        });
        measurements.push({ name: "",
            X: this.frameInnerCoordinates.left.X -20,
            Y: this.frontView.innerRect.Y + this.frontView.innerRect.H + 80,
            text: this.config.frame[this.frameStyleDictionary.left].hasOwnProperty('panelLeftRightGap')? this.config.frame[this.frameStyleDictionary.left].panelLeftRightGap.toFixed(1) : this.config.panelLeftRightGap.toFixed(1),
            type: 'text',
            options: {
                cls: 'red-large'
            },
            display: {
                customer: false,
                factory: ['viewscape']
            }
        });
        measurements.push({ name: "",
            X1: this.frameInnerCoordinates.right.X,
            Y1: this.frameInnerCoordinates.bottom.Y,
            X2: this.frameInnerCoordinates.right.X,
            Y2: this.frameInnerCoordinates.bottom.Y + 60,
            type: 'line',
            display: {
                customer: false,
                factory: ['viewscape'] //todo: no impact on 238121
            }
        });
        measurements.push({ name: "",
            X: this.frameInnerCoordinates.right.X - 20,
            Y: this.frameInnerCoordinates.bottom.Y + 80,
            text: this.config.frame[this.frameStyleDictionary.right].hasOwnProperty('panelLeftRightGap')? this.config.frame[this.frameStyleDictionary.right].panelLeftRightGap.toFixed(1) : this.config.panelLeftRightGap.toFixed(1),
            type: 'text',
            options: {
                cls: 'red-large'
            },
            display: {
                customer: false,
                factory: ['viewscape']
            }
        });

        for(const panel of this.frontView.panels.filter(p => p.type === 'panel')){
            measurements.push({ name: "",
                X: panel.outerRect.X + (panel.outerRect.W)/2,
                Y: this.frameInnerCoordinates.bottom.Y + 80,
                text: panel.layout,
                type: 'text',
                options: {
                    cls: 'red-large'
                },
                display: {
                    customer: false,
                    factory: ['jht','viewscape'] // not highlighted, L and Rs
                }
            })
        }
        let louverM = [];
        let lCount = 1;
        for(const louver of this.sideView.panel.louvers.sort((a, b) => (a.Y1 > b.Y2) ? -1 : 1)){
            louverM.push({
                line: {
                    X1: louver.CX,
                    Y1: louver.CY,
                    X2: louver.CX - 50,
                    Y2: louver.CY,
                    type: 'line',
                    options: {
                        strokeWidth: 2
                    },
                    display: {
                        customer: false,
                        factory: ['jht','viewscape'] // not highlighted, side measurement pointing to lover centre
                    }
                },
                text: {
                    X: louver.CX - 150,
                    Y: louver.CY + 8,
                    text: `${this.config.louverWidth}mm`,
                    type: 'text',
                    options: {
                        cls: 'red'
                    },
                    display: {
                        customer: false,
                        factory: ['jht','viewscape'] // not highlighted, side measurement pointing to lover centre
                    }
                }
            });
            if(lCount === 2){
                break
            }
            lCount += 1
        }
        measurements.push(louverM[louverM.length-1].line);
        measurements.push(louverM[louverM.length-1].text);
        return measurements
    }
    roundMeasurement = (measurement) => {
        return parseFloat(Math.round(measurement * 100) / 100);
    };
    measureCutListFrameLength(location, frame){
        // console.log(location, frame);
        let measurement = ['left', 'right'].includes(location) ? this.measurements.side.find(m => m.name === "height").measurement : this.measurements.top.find(m => m.name === "width").measurement;
        // console.log(measurement);
        // console.log(measurement)
        measurement += frame.cutListLip;
        if(["Sill Frame", "No Frame"].includes(frame.name)) {
            if (['top', 'bottom'].includes(location)) {
                measurement = this.measurements.top.find(m => m.name === "frame_inner_width").measurement;
                // if(["Sill Frame", "No Frame"].includes(this.frameStyleDictionary.left)){
                //     measurement += this.config.frameStyles.find(f => f.name === this.frameStyleDictionary.left).width;
                // }
                // if(["Sill Frame", "No Frame"].includes(this.frameStyleDictionary.right)){
                //     measurement += this.config.frameStyles.find(f => f.name === this.frameStyleDictionary.right).width;
                // }
            }
            if (['left', 'right'].includes(location)) {
                measurement = this.measurements.side.find(m => m.name === "panel_outer_height").measurement;
                if(["Sill Frame", "No Frame"].includes(this.frameStyleDictionary.top)){
                    measurement += this.config.frameStyles.find(f => f.name === this.frameStyleDictionary.top).width;
                }
                if(["Sill Frame", "No Frame"].includes(this.frameStyleDictionary.bottom)){
                    measurement += this.config.frameStyles.find(f => f.name === this.frameStyleDictionary.bottom).width;
                }
            }
        }
        else {
            if (['top', 'bottom'].includes(location) && (["Sill Frame", "No Frame"].includes(this.frameStyleDictionary.left) || ["Sill Frame", "No Frame"].includes(this.frameStyleDictionary.right))) {
                measurement = this.measurements.top.find(m => m.name === "width").measurement;
                if(!["Sill Frame", "No Frame"].includes(this.frameStyleDictionary.left)){
                    measurement += (this.config.frameStyles.find(f => f.name === this.frameStyleDictionary.left).cutListLip/2);
                }
                if(!["Sill Frame", "No Frame"].includes(this.frameStyleDictionary.right)){
                    measurement += (this.config.frameStyles.find(f => f.name === this.frameStyleDictionary.right).cutListLip/2);
                }
            }
            if (['left', 'right'].includes(location) && (["Sill Frame", "No Frame"].includes(this.frameStyleDictionary.top) || ["Sill Frame", "No Frame"].includes(this.frameStyleDictionary.bottom))) {
                measurement = this.measurements.side.find(m => m.name === "height").measurement;
                if(!["Sill Frame", "No Frame"].includes(this.frameStyleDictionary.top)){
                    measurement += (this.config.frameStyles.find(f => f.name === this.frameStyleDictionary.top).cutListLip/2);
                }
                if(!["Sill Frame", "No Frame"].includes(this.frameStyleDictionary.bottom)){
                    measurement += (this.config.frameStyles.find(f => f.name === this.frameStyleDictionary.bottom).cutListLip/2);
                }
            }
        }
        if(["No Frame"].includes(frame.name)){
            return 0
        }
        // console.log(measurement)
        return measurement;
    }
    generateCutList() {
        let components = [];
        const addComponent = (component, group, groupKey) => {
          // if(component.name !== 'Bottom Rail') return;
          let cmp = {
            ...component,
            length: this.roundMeasurement(component.length),
            width: this.roundMeasurement(component.width * 10) / 10,
            thickness: this.roundMeasurement(component.thickness * 10) / 10,
          };
          if(!group){
              components = [...components, cmp];
          }
          else {
              let c = components.filter(c => c.name === cmp.name && c[groupKey] === cmp[groupKey]);
              if(c.length > 0){
                  let idx = components.findIndex(c => c.name === cmp.name && c[groupKey] === cmp[groupKey]);
                  components[idx] = {
                      ...cmp,
                      qty: c[0].qty + cmp.qty
                  };
              }
              else {
                  components = [...components, cmp];
              }
          }
        };
        let ord = 0;
        for (const [key, value] of Object.entries(this.frameStyleDictionary)){
            let frame = this.config.frameStyles.find(f => f.name === value);
            addComponent({
                name: `${frame.factoryName} (${key})`,
                qty: 1,
                length: this.measureCutListFrameLength(key, frame),
                width: frame.width,
                thickness: frame.thickness,
                note: '',
                order: ord
            }, false, null);
            ord += 1
        }
        // ord += frames.length;
        let configuration = this.configuration.replaceAll('-').split("");
        configuration.map((layout, idx) => {
           if(['L', 'R'].includes(layout)){
               addComponent({
                   name: `Hidden Stile`,
                   qty: 1,
                   length: this.measurements.side.find(m => m.name === "panel_height").measurement,
                   width: this.config.hiddenStile.width,
                   thickness: this.config.hiddenStile.thickness,
                   note: '',
                   order: ord+1
               }, true, 'length');
               if(idx > 1 && configuration[idx-1] !== 'D') {
                   addComponent({
                       name: `PVC Square Stile`,
                       qty: 1,
                       length: this.measurements.side.find(m => m.name === "panel_height").measurement,
                       width: this.config.hiddenStile.width,
                       thickness: this.config.hiddenStile.thickness,
                       note: '',
                       order: ord + 2
                   }, true, 'length');
               }
               else if(idx === 0) {
                   addComponent({
                       name: `PVC Square Stile`,
                       qty: 1,
                       length: this.measurements.side.find(m => m.name === "panel_height").measurement,
                       width: this.config.hiddenStile.width,
                       thickness: this.config.hiddenStile.thickness,
                       note: '',
                       order: ord + 2
                   }, true, 'length');
               }
           }
            if(layout === 'D'){
                addComponent({
                    name: `PVC Astragal Stile`,
                    qty: 1,
                    length: this.measurements.side.find(m => m.name === "panel_height").measurement,
                    width: this.config.hiddenStile.width,
                    thickness: this.config.hiddenStile.thickness,
                    note: '',
                    order: ord+3
                }, true, 'length');
            }
            if(layout === 'T'){
                addComponent({
                    name: `T-Post`,
                    qty: 1,
                    length: this.measurements.side.find(m => m.name === "panel_outer_height").measurement,
                    width: this.config.tpost.width,
                    thickness: this.config.tpost.thickness,
                    note: '',
                    order: ord+4
                }, true, 'length');
            }
        });
        for (const panel of this.frontView.panels.filter(panel => panel.type === 'panel').sort((a, b) => (a.outerRect.X > b.outerRect.X) ? 1 : -1)) {
            addComponent({
                name: `Top Rail`,
                qty: 1,
                length: this.measurements.top.find(m => m.id === panel.id && m.name === "top_rail_width").measurement,
                width: this.measurements.side.find(m => m.name === "top_rail_height").measurement,
                thickness: this.config.rails.thickness,
                note: '',
                order: ord + 5
            },true, 'length');
            addComponent({
                name: `Bottom Rail`,
                qty: 1,
                length: this.measurements.top.find(m => m.id === panel.id && m.name === "top_rail_width").measurement,
                width: this.measurements.side.find(m => m.name === "bottom_rail_height").measurement,
                thickness: this.config.rails.thickness,
                note: '',
                order: ord + 6
            }, true, 'length');
            for(const midRail of panel.rails.mid) {
                addComponent({
                    name: `Divider Rail`,
                    qty: 1,
                    length: this.measurements.top.find(m => m.id === panel.id && m.name === "top_rail_width").measurement,
                    width: this.config.rails.mid.width,
                    thickness: this.config.rails.thickness,
                    note: '',
                    order: ord + 7
                }, true, 'length');
            }
            addComponent({
                name: `Louvers`,
                qty: panel.louvers.length,
                length: this.measurements.top.find(m => m.id === panel.id && m.name === "louver_width").measurement,
                width: this.config.louvers.width,
                thickness: this.config.louvers.thickness,
                note: '',
                order: ord + 8
            }, false, '');
        }
        let topRailWidth = this.roundMeasurement(this.measurements.side.find(m => m.name === "top_rail_height").measurement);
        let bottomRailWidth = this.roundMeasurement(this.measurements.side.find(m => m.name === "bottom_rail_height").measurement);
        this.cutList = {
            components: components.sort((a, b) => (a.order > b.order) ? 1 : -1),
            profistop: {
              top: this.roundMeasurement(1000 - (topRailWidth + 38.1 + 4.5)),
              topWidth: topRailWidth,
              bottom: this.roundMeasurement(1000 - (bottomRailWidth + 38.1 + 5)),
              bottomWidth: bottomRailWidth
            },
            usePreDrillStiles: (topRailWidth >= 109 && topRailWidth <= 118.5) || (bottomRailWidth >= 109 && bottomRailWidth <= 118.5)? 'Yes' : 'No'
        }
    }
    generateLabels(){
        let labels = [];
        const addLabel = (label) => {
            let lb = {
                ...label,
                length: this.roundMeasurement(label.length),
                width: this.roundMeasurement(label.width * 10) / 10,
                thickness: this.roundMeasurement(label.thickness * 10) / 10,
            };
            labels.push(lb)
        };
        let ord = 0;
        for (const [key, value] of Object.entries(this.frameStyleDictionary)){
            let frame = this.config.frameStyles.find(f => f.name === value);
            addLabel({
                name: `${frame.factoryName} (${key})`,
                qty: 1,
                length: this.measureCutListFrameLength(key, frame),
                width: frame.width,
                thickness: frame.thickness,
                layout: '',
                panel: '',
                note: '',
                order: ord
            }, false, null);
            ord += 1
        }
        let panelNo = 1;
        let configuration = this.configuration.replaceAll('-').split("");
        configuration.map((layout, idx) => {
            if(['L', 'R'].includes(layout)){
                addLabel({
                    name: `Hidden Stile`,
                    qty: 1,
                    length: this.measurements.side.find(m => m.name === "panel_height").measurement,
                    width: this.config.hiddenStile.width,
                    thickness: this.config.hiddenStile.thickness,
                    layout: layout,
                    panel: panelNo,
                    note: '',
                    order: ord+1
                }, true, 'length');
                if(idx > 1 && configuration[idx-1] !== 'D') {
                    addLabel({
                        name: `PVC Square Stile`,
                        qty: 1,
                        length: this.measurements.side.find(m => m.name === "panel_height").measurement,
                        width: this.config.hiddenStile.width,
                        thickness: this.config.hiddenStile.thickness,
                        layout: layout,
                        panel: panelNo,
                        note: '',
                        order: ord + 2
                    }, true, 'length');
                }
                else if(idx === 0) {
                    addLabel({
                        name: `PVC Square Stile`,
                        qty: 1,
                        length: this.measurements.side.find(m => m.name === "panel_height").measurement,
                        width: this.config.hiddenStile.width,
                        thickness: this.config.hiddenStile.thickness,
                        layout: layout,
                        panel: panelNo,
                        note: '',
                        order: ord + 2
                    }, true, 'length');
                }
                panelNo += 1
            }
            if(layout === 'D'){
                addLabel({
                    name: `PVC Astragal Stile`,
                    qty: 1,
                    length: this.measurements.side.find(m => m.name === "panel_height").measurement,
                    width: this.config.hiddenStile.width,
                    thickness: this.config.hiddenStile.thickness,
                    layout: configuration[idx+1],
                    panel: panelNo,
                    note: '',
                    order: ord+3
                }, true, 'length');
            }
            if(layout === 'T'){
                addLabel({
                    name: `T-Post`,
                    qty: 1,
                    length: this.measurements.side.find(m => m.name === "panel_outer_height").measurement,
                    width: this.config.tpost.width,
                    thickness: this.config.tpost.thickness,
                    layout: '',
                    panel: '',
                    note: '',
                    order: ord+4
                }, true, 'length');
            }
        });
        panelNo = 1;
        for (const panel of this.frontView.panels.filter(panel => panel.type === 'panel').sort((a, b) => (a.outerRect.X > b.outerRect.X) ? 1 : -1)) {
            addLabel({
                name: `Top Rail`,
                qty: 1,
                length: this.measurements.top.find(m => m.id === panel.id && m.name === "top_rail_width").measurement,
                width: this.measurements.side.find(m => m.name === "top_rail_height").measurement,
                thickness: this.config.rails.thickness,
                layout: panel.layout,
                panel: panelNo,
                note: '',
                order: ord + 5
            },true, 'length');
            addLabel({
                name: `Bottom Rail`,
                qty: 1,
                length: this.measurements.top.find(m => m.id === panel.id && m.name === "top_rail_width").measurement,
                width: this.measurements.side.find(m => m.name === "bottom_rail_height").measurement,
                thickness: this.config.rails.thickness,
                layout: panel.layout,
                panel: panelNo,
                note: '',
                order: ord + 6
            }, true, 'length');
            for(const midRail of panel.rails.mid) {
                addLabel({
                    name: `Divider Rail`,
                    qty: 1,
                    length: this.measurements.top.find(m => m.id === panel.id && m.name === "top_rail_width").measurement,
                    width: this.config.rails.mid.width,
                    thickness: this.config.rails.thickness,
                    layout: panel.layout,
                    panel: panelNo,
                    note: '',
                    order: ord + 7
                }, true, 'length');
            }
            addLabel({
                name: `Louvers`,
                qty: panel.louvers.length,
                length: this.measurements.top.find(m => m.id === panel.id && m.name === "louver_width").measurement,
                width: this.config.louvers.width,
                thickness: this.config.louvers.thickness,
                layout: panel.layout,
                panel: panelNo,
                note: '',
                order: ord + 8
            }, true, 'length');
            panelNo += 1;
        }
        this.labels = arraySort(labels, ['order', 'panel']);
    }
}

export {
    Shutter
}