import React from 'react';
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import {makeStyles} from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import EditForm from "../editForm";
import {draw} from "../drawSVG";
import {Shutter} from "../shutter";
import ConfigForm from "./form/configForm";
import OptionsMenus from "./optionsMenu";
import ToggleView from "./toggleView";
import Tooltip from '@material-ui/core/Tooltip';
import {viewConfigOverrides} from "./utils";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import {svgCrop} from "../svgCrop";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    containerPaper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(2),

    },
    advancedSettingsDiv: {
        marginTop: 30
    },
    containerDrawing: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: theme.spacing(2),
        height: '78vh',
        color: theme.palette.text.secondary,
        textAlign: 'center'
        // marginTop: theme.spacing(2),

    },
    gridDrawing: {
        marginTop : theme.spacing(2),
    },
    cardHeader: {
        paddingBottom: 0
    },
    card: {
        background: 'white',
        position: "sticky",
        top : theme.spacing(14),
    },
    img: {
        maxWidth: '100%',
        margin: 'auto'
    },
    action: {
        textAlign: 'right',
        marginRight: -20,
        marginTop: -20
    }
}));



export default function ShutterDrawing(props) {
    const classes = useStyles();
    const [svgUrl, setSvgUrl] = React.useState(null);
    const [drawing, setDrawing] = React.useState(null);
    const [meta, setMeta] = React.useState({});
    const [advanced, setAdvanced] = React.useState(false);
    const [view, setView] = React.useState('factory');
    const [drawingError, setDrawingError] = React.useState(null);
    const [drawingWarnings, setDrawingWarnings] = React.useState([]);
    const [editMode, setEditMode] = React.useState(false);
    React.useEffect(() => {
        setEditMode(false)
    }, [props.page]);
    React.useEffect(() => {
        if(props.shutter){
            try {
                const shutter = new Shutter({
                    ...props.shutter,
                    config: viewConfigOverrides(view, props.shutter.config),
                    view: view,
                    meta: meta
                });
                const d = shutter.draw();
                setDrawing(d);
                let svg = draw(props.shutter, d, viewConfigOverrides(view, props.shutter.config), view, false);
                // svg = svgCrop(svg, drawing);
                let url = buildURI(svg.outerHTML);
                setSvgUrl(url);
                // console.log(d);
                // console.log(props.shutter)
                // console.log(shutter.qualityCheck());
                setDrawingWarnings(shutter.qualityCheck());
                setDrawingError(null);
            }
            catch (e) {
               console.log(e)
                setDrawingError(e.toString())
            }
            // console.log(props.shutter)
        }
    }, [props.shutter, view, meta]);

    const buildURI = (svg) => {
        const blob = new Blob([svg], {type: 'image/svg+xml'});
        return URL.createObjectURL(blob);
    };

    const handleAdvancedClick = () => {
        setAdvanced(!advanced)
    };

    const handleViewChange = (name) => {
        setView(name)
    };

    const handleMetaChange = (name, value) => {
            setMeta({
                ...meta,
                [name]: value
            })

    };
    const handleMetaChangeBulk = (m) => {
        setMeta({
            ...meta,
            ...m
        })
    };
    const handleEditClick = () => {
      setEditMode(!editMode)
    };

    const DisplayAlerts = () => {
      return (
          <React.Fragment>
              {drawingError ?
              <Tooltip title={drawingError} placement="right">
                  <ReportProblemIcon color="secondary"/>
              </Tooltip>
              : null}
              {drawingWarnings.length > 0 ?
                  <Tooltip title={drawingWarnings.map( ({component, message}) => `${component}: ${message}`).join('\n')} placement="right">
                      <ReportProblemIcon style={{color: '#f19c01'}}/>
                  </Tooltip>
              :null}
          </React.Fragment>
      )
    };
    return (
        <React.Fragment>
            <Grid container spacing={3}>
            <Grid item xs={3} sm={3} lg={3} xl={2}>
                <Paper className={classes.containerPaper}>
                    <div className={classes.action}>
                        <IconButton aria-label="delete" className={classes.margin} onClick={handleEditClick}>
                            {editMode ? <LockOpenIcon fontSize="small" /> : <LockIcon fontSize="small" />}
                        </IconButton>
                    </div>
                    <EditForm editMode={editMode} formType="Edit" handleMetaChangeBulk={handleMetaChangeBulk} handleMetaChange={handleMetaChange} shutter={props.shutter} drawing={drawing} handleCurrentShutterChange={props.handleCurrentShutterChange}/>
                    <div className={classes.advancedSettingsDiv}>
                        <OptionsMenus {...props} handleEditClick={handleEditClick} handleAdvancedClick={handleAdvancedClick} advanced={advanced}/>
                        {advanced && <ConfigForm editMode={editMode} shutter={props.shutter} handleCurrentShutterChange={props.handleCurrentShutterChange}/>}
                    </div>
                </Paper>
            </Grid>
            <Grid item xs={9} sm={9} lg={9} xl={10} className={classes.gridDrawing}>
                    <Card className={classes.card}>
                        <TransformWrapper contentStyle={{width: '100%', height: '100%'}}>
                            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                            <React.Fragment>
                                <CardHeader
                                    title={
                                           <DisplayAlerts/>
                                    }
                                    action={
                                        <React.Fragment>
                                            <Tooltip title="Zoom In">
                                                <IconButton onClick={() => zoomIn()} aria-label="zoom-in">
                                                    <ZoomInIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Zoom Out">
                                                <IconButton onClick={() => zoomOut()} aria-label="zoom-out">
                                                    <ZoomOutIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Reset">
                                                <IconButton onClick={() => resetTransform()} aria-label="zoom-reset">
                                                    <ZoomOutMapIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <ToggleView view={view} handleViewChange={handleViewChange} />
                                        </React.Fragment>
                                    }
                                    className={classes.cardHeader}
                                />
                                <CardContent className={classes.containerDrawing}>
                                    <TransformComponent>
                                        {svgUrl && <img src={svgUrl} height="100%" className={classes.img}/>      }
                                    </TransformComponent>
                                </CardContent>
                            </React.Fragment>
                            )}
                        </TransformWrapper>
                    </Card>
            </Grid>
            </Grid>
        </React.Fragment>
    );
}