import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import AddShutterDialog from "./addShutterDialog";


const useStyles = makeStyles((theme) => ({
    strikeThrough: {
        textDecoration: 'line-through'
    }
}));


export const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    }
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

export const StyledMenuItem = withStyles((theme) => ({
    root: {
    },
}))(MenuItem);

export default function NavSelect(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleShutterSelect = (id) => {
        handleClose();
        props.handlePageChange(id);
    };


    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} style={{color: '#fff', fontSize: '18px'}} >
              <span className={props.currentShutter.deleted? classes.strikeThrough : null}>{props.currentShutter.name}</span> <ArrowDropDownIcon/>
            </Button>
            <br/>
            <span>{props.currentShutter._id}/{props.shutters.length}</span>
            <StyledMenu
                id="nav-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {props.shutters.filter(s => s._id !== props.currentShutter._id).map((shutter) =>
                    <StyledMenuItem onClick={()=>handleShutterSelect(shutter._id)} key={`menu-item-${shutter._id}`} className={shutter.deleted? classes.strikeThrough : null}>{shutter.name}</StyledMenuItem>
                )}
                <AddShutterDialog {...props} handleMenuClose={handleClose}/>
            </StyledMenu>
        </div>
    );
}
