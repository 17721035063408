import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
const options = [
    {
        name: 'factory',
        display: 'Factory'
    },
    // {
    //     name: 'detailed_customer',
    //     display: 'Detailed Customer'
    // },
    {
        name: 'customer',
        display: 'Customer'
    },

];
const ITEM_HEIGHT = 48;

export default function ToggleView(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleViewChange = (view) => {
        handleClose();
        props.handleViewChange(view);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Tooltip title="View">
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <VisibilityIcon />
                </IconButton>
            </Tooltip>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {options.filter(o => o.name !== props.view).map((option) => (
                    <MenuItem key={option.name} onClick={() => handleViewChange(option.name)}>
                        {option.display} View
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    );
}