import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        width: '100%',
        maxWidth: 500,
    },
    orderTitle: {
        fontSize: '14px'
    },
    orderEmail: {
        fontSize: '12px'
    }
});

export default function NavTitle(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant="body" component="p" className={classes.orderTitle}>
               ORDER: {props.order.orderId}
            </Typography>
            <Typography variant="body" component="p" className={classes.orderEmail}>
                {props.order.email}
            </Typography>
        </div>
    );
}