import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import NavSelect from "./navSelect";
import {DownloadDrawingButton} from "./downloadButton";
import NavTitle from "./navTitle";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,

    },
    appName: {
        marginRight: theme.spacing(4),
        alignItems: 'center'
    },
    menuButton: {
        // marginRight: theme.spacing(4),
        paddingTop: "17px",
        paddingBottom: "17px",
        textTransform: "none",
        fontWeight: 700,
    },
    title: {
        flexGrow: 1,
        alignItems: 'center',
        verticalAlign: 'center',
        paddingTop: "17px",
        paddingBottom: "17px",
    },
    link: {
        textDecoration: 'none',
        color: '#ffffff',
    },
    grow: {
        flexGrow: 1,
    },
    toolbar: {
      justifyContent: 'center'
    },
    heading: {
        textAlign: 'center',
        display: 'flex',
    },
    activeLink: {
        borderBottom: "3px solid rgba(255,255,255,0.75)"

    },
    menuIcon: {
        marginRight: theme.spacing(2),
    }
}));

function NavBar(props) {
    const classes = useStyles();

    return (
            <AppBar position="sticky" className={classes.appBar} >
                <Toolbar className={classes.toolbar}>
                    <div>
                        <NavTitle {...props} />
                    </div>
                    <div className={classes.grow} />
                    <div className={classes.heading}>
                    {props.shutters.length > 1?
                        <React.Fragment>
                            <IconButton
                                edge="start"
                                className={classes.menuButton}
                                color="inherit"
                                aria-label="open drawer"
                                onClick={props.handleBack}
                                disabled={(props.page-1) === 0}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                        </React.Fragment>
                        : null}

                            <div className={classes.title}>
                                <NavSelect {...props}/>
                            </div>
                        {props.shutters.length > 1?
                            <React.Fragment>
                                <IconButton
                                    edge="start"
                                    className={classes.menuButton}
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={props.handleForward}
                                    disabled={(props.page+1) >= props.shutters.length+1}
                                >
                                    <ArrowForwardIcon />
                                </IconButton>
                            </React.Fragment>
                    : null}
                    </div>
                    <div className={classes.grow} />
                    <DownloadDrawingButton {...props} />
                </Toolbar>
            </AppBar>
    );
}

export default withRouter(NavBar);