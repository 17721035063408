import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import SelectField from "./selectField";
import Grid from "@material-ui/core/Grid";
import {Form} from "formik";
import {FormControlLabel} from "@material-ui/core";
import Switch from "@material-ui/core/Switch/Switch";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    }

}));


export default function TiltRodSystem(props) {
    const classes = useStyles();

    const handleSplitTiltRodChange = (event) => {
        props.onChange("splitTiltRod", event.target.checked);
        // if(props.midrails === 0){
        //     props.onChange("splitTiltRodZones", {
        //         ...props.splitTiltRodZones,
        //         Z1: true
        //     })
        // }
    };
    const handleSplitTiltRodZoneChange = (e) => {
        props.onChange("splitTiltRodZones", {
              ...props.splitTiltRodZones,
              [e.target.name] : e.target.checked
        });
    };

    const handleMouseEnterEvent = (name) => {
      if(name === 'Z1'){
          props.handleMetaChangeBulk({
              Z1: true,
              Z2: false
          })
      }
        if(name === 'Z2'){
            props.handleMetaChangeBulk({
                Z1: false,
                Z2: true
            })
        }
    };
    const handleMouseLeaveEvent = () => {
        props.handleMetaChangeBulk({
            Z1: false,
            Z2: false
        })
    };

    return (
        <Grid container spacing={0}>
            {props.midrails === 0 &&
                <Grid container item xs={12}>
                    <FormControlLabel
                        name="split-tilt-rod"
                        value="bottom"
                        onMouseEnter={() => props.midrails === 0 ? handleMouseEnterEvent(`Z1`) : null}
                        onMouseLeave={() => props.midrails === 0 ? handleMouseLeaveEvent() : null}
                        control={<Switch
                            disabled={props.disabled}
                            color="primary"
                            name="Z1"
                            checked={props.splitTiltRod || (props.drawing && props.drawing.meta.hasOwnProperty('splitTiltRod') && props.drawing.meta.splitTiltRod)}
                            onChange={handleSplitTiltRodChange}/>}
                        label={<Typography className={classes.formControlLabel}>Split Tilt Rod System</Typography>}
                        labelPlacement="start"
                    />
                </Grid>
            }
            {props.midrails > 0 && (props.midrails > 0 || (props.drawing && props.drawing.meta.hasOwnProperty('splitTiltRod') && props.drawing.meta.splitTiltRod))?
                    <React.Fragment>
                    {[...Array(2).keys()].map(n =>
                <Grid container item xs={12}>
                        <FormControlLabel
                            key={`split-tilt-rod-zone-${n}`}
                            id={`split-tilt-rod-zone-${n}`}
                            value="bottom"
                            onMouseEnter={() => handleMouseEnterEvent(`Z${n+1}`, true)}
                            onMouseLeave={() => handleMouseLeaveEvent()}
                            control={<Switch
                                disabled={props.disabled}
                                name={`Z${n+1}`}
                                color="primary"
                                checked={props.splitTiltRodZones[`Z${n+1}`] || ((props.drawing && props.drawing.meta.hasOwnProperty('splitTiltRodZones') && props.drawing.meta.splitTiltRodZones[`Z${n+1}`]))}
                                onChange={handleSplitTiltRodZoneChange}
                            />}
                            label={<Typography className={classes.formControlLabel}>Split {n === 0? 'Top' : 'Bottom'}</Typography>}
                            labelPlacement="start"
                        />
                </Grid>
                    )}
                    </React.Fragment>

            :null}
        </Grid>
    )
}
