import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import OrderNavBar from "../components/order/nav";
import ShutterDrawing from "../components/order/shutterDrawing";
import {findRecord, saveRecord} from "../api/orders"
import {
    useParams
} from "react-router-dom";
import LoadingPage from "../components/order/spinner"
import { fetchSupplierConfig, fetchSupplierConfigList, ShutterConfig } from "../components/config";
import { useSnackbar } from 'notistack';
import useInterval from 'react-useinterval';
import { useHistory } from "react-router-dom";
import SupplierPopup from "../components/supplierPopup";

const useStyles = makeStyles((theme) => ({
    container: {
        // marginTop: 30
    },
}));



export default function OrderView() {
    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const parentRef = React.useRef(null);
    const [loading, setLoading] = React.useState(true);
    const [order, setOrder] = React.useState(null);
    const [additionalOrderData, setAdditionalOrderData] = React.useState({});
    const [updatedOrder, setUpdatedOrder] = React.useState(null);
    const [orderId, setOrderId] = React.useState(null);
    const [supplierConfig, setSupplierConfig] = React.useState(null);
    const [isSupplierPopupOpen, setIsSupplierPopupOpen] = React.useState(false);
    const [supplierConfigList, setSupplierConfigList] = React.useState(null);
    const [shuttersOrderId, setShuttersOrderId] = React.useState(null);
    const [originalShutters, setOriginalShutters] = React.useState([]);
    const [shutters, setShutters] = React.useState([]);
    const [currentShutter, setCurrentShutter] = React.useState(null);
    const [pdfContent, setPdfContent] = React.useState([]);
    const [page, setPage] = React.useState(1);
    let { id } = useParams();

    const [saveQueue, setSaveQueue] = React.useState([]);

    const toggleSupplierPopup = () => {
        if(!isSupplierPopupOpen){
            fetchSupplierConfigList().then((out) => {
                setSupplierConfigList(out);
            })
        }
        setIsSupplierPopupOpen(!isSupplierPopupOpen);
    }

    const getSaveQueue = () => {
      return saveQueue
    };
    const saveShutter = async (data) => {
        try {
            let response = await saveRecord('object_78', orderId, {field_1262: JSON.stringify(data)});
            enqueueSnackbar('Saved', {variant: "success"});
        }
        catch (e) {
            enqueueSnackbar('Error Saving', {variant: "error"});
        }
    };

    const saveOrder = async () => {
        //saveQueue avoids multiple saves due to typing
        if(saveQueue.length > 0 && process.env.REACT_APP_AUTO_SAVE === 'enabled') { //only save in production
            let toSave = saveQueue[saveQueue.length - 1];
            setSaveQueue([]);
            await saveShutter(toSave);
        }
    };

    useInterval(saveOrder, 5000); //can be better


    React.useEffect(() => {
        const fetchData = async () => {
           await findRecord('object_78', {"match":"and","rules":[{"field":"field_1259","operator":"is","value": id}]}).then((response) => {
               let ord = {...JSON.parse(response.data.records[0].field_1257_raw), _record: 'new'};
               let originalOrder = response.data.records[0].hasOwnProperty("field_1262_raw") && response.data.records[0].field_1262_raw !== "" ? {
                   ...JSON.parse(response.data.records[0].field_1262_raw),
                   _record: 'update'
               } : null;

               setOrder(ord);
               setUpdatedOrder(originalOrder);
               setOrderId(response.data.records[0].id);
               setShuttersOrderId(response.data.records[0].field_1259);
               setLoading(false);
           }).catch((e) => {
                console.log(e);
                setLoading(false);
                history.push('/error');
           });

        };
        fetchData();
    }, []);
    React.useEffect(() => {
        setSaveQueue([...saveQueue, {...{...order, ...additionalOrderData}, order_details_array: shutters}]);
    }, [shutters, additionalOrderData]);
    const cleanPanelWidth = (panelWidth, panelWidthOption, configuration) => {
      if(panelWidth) {
          let cfg = configuration.replaceAll('-', '').replaceAll('L', '').replaceAll('R', '').split('');
          let pw = panelWidth.split(",");
          let newPw = [];
          if (cfg.length === pw.length) {
              cfg.map((c, idx) => {
                  if (c === 'T') {
                      newPw.push(pw[idx].trim())
                  }
              })
          }
          return newPw.join(",")
      }
      return ""
    };
    const cleanMidrailPositions = (midrails, midrailPositions) => {
        if(midrailPositions !== 'Evenly Spaced' && midrailPositions !== "" && midrails > 0){
            let mp = midrailPositions.split(',');
            return mp.map((p, idx) => ({name: `M${idx+1}`, value: parseFloat(p), critical: idx === 0}))
        }
        else if (midrails > 0 && midrailPositions === ""){
            return 'Evenly Spaced'
        }
        return midrailPositions
    };
    const getFrameStyle = (sConfig, frameStyle) => {
        let fs = frameStyle.includes('Sill')? 'Z Frame' : frameStyle;
        return {
            frameStyle:  fs,
            frameStyleIsCustom: !!frameStyle.includes('Sill'),
            customFrameStyle: {
                top: fs,
                bottom: frameStyle.includes('Sill')? 'Sill Frame': frameStyle, //TODO generalise
                left: fs,
                right: fs
            },
        }
    }

    React.useEffect(() => {
        if(order){
            fetchSupplierConfig(order.product).then((out) => {
                setSupplierConfig(out);
            })
        }
    }, [order])

    React.useEffect(() => {
        if(order && supplierConfig){
            let s = order.order_details_array.map((s, idx) => (
                {
                    ...s,
                    midrails: parseInt(s.midrails),
                    midrailPositions: cleanMidrailPositions(s.midrails, s.midrailPositions),
                    panelWidthOption: s.panelWidthOption || 'Equal',
                    panelWidth: cleanPanelWidth(s.panelWidth, s.panelWidthOption, s.configuration),
                    hingeColour: 'To Match',
                    standardDeduction: true,
                    config: {...ShutterConfig, ...supplierConfig},
                    _id: idx + 1,
                    shuttersOrderId: order.orderId,
                    original:true,
                    deleted: false,
                    splitTiltRod: false,
                    splitTiltRodZones: {
                        Z1: false,
                        Z2: false
                    },
                    ...(getFrameStyle(supplierConfig, s.frameStyle)),
                    notes: '', //TODO Default notes?
                }
            ));
            setOriginalShutters(s);
            if(!updatedOrder){
                setShutters(s);
                setCurrentShutter(s[0]);
            }
        }
    }, [order, updatedOrder, supplierConfig]);

    React.useEffect(() => {
        if(updatedOrder){
            setAdditionalOrderData({
                internalOrderId: updatedOrder.hasOwnProperty("internalOrderId") ? updatedOrder.internalOrderId : ""
            })
            setShutters(updatedOrder.order_details_array);
            setCurrentShutter(updatedOrder.order_details_array[0]);

        }
    }, [updatedOrder]);

    const handlePageChange = (page) => {
        setPage(page)
    };
    const handleForward = () => {
        setPage(page+1)
    };
    const handleBack = () => {
        setPage(page-1)
    };
    React.useEffect(() => {
        if(shutters[page-1] !== undefined){
            setCurrentShutter({...shutters[page-1]})
        }
    }, [page]);


    const handleCurrentShutterChange = (values) => {
        let newShutter = {
            ...currentShutter,
            ...values
        };
        setCurrentShutter(newShutter);
        let newShutters = [...shutters.filter(s => s._id !==currentShutter._id), newShutter].sort((a, b) => (a._id > b._id) ? 1 : -1);
        setShutters(newShutters);
    };

    const handleAdditionalOrderDataChange = (values) => {
        let newOrder = {
            ...additionalOrderData,
            ...values
        };
        setAdditionalOrderData(newOrder);
    };

    const handleAddShutter = (s) => {
        setShutters([...shutters, {...s, deleted: false, original: false, shuttersOrderId: order.orderId}]);
        setCurrentShutter({...s,  deleted: false, originadditionalOrderDataal: false});
        setPage(s._id);
    };

    const handlePdfContent= (content) => {
        setPdfContent([...pdfContent.filter(p => p._id !== content._id), content ])
    };

    const handleResetShutter = () => {
        setShutters([...shutters.filter(s => s._id !== currentShutter._id), ...originalShutters.filter(s => s._id === currentShutter._id)].sort((a, b) => (a._id > b._id) ? 1 : -1));
        setCurrentShutter(originalShutters.find(s => s._id === currentShutter._id));
    };

    const handleRefreshSupplierConfig = () => {
        toggleSupplierPopup();
    }

    const handleSupplierSelection = (value) => {
        fetchSupplierConfig(value).then((cfg) => {
            setCurrentShutter({...currentShutter, config: {...ShutterConfig, ...cfg}})
        })

        toggleSupplierPopup();
    }
    const handleCancel = () => {
        toggleSupplierPopup();
    }


    const handleDeleteShutter = () => {
        handleCurrentShutterChange({
            deleted: !currentShutter.deleted
        })
    };

    return (
        <React.Fragment>
            {!loading && orderId && shuttersOrderId && order && shutters && currentShutter ?
                <React.Fragment>
                <CssBaseline />
                <OrderNavBar page={page} order={order} orderId={orderId} additionalOrderData={additionalOrderData} shuttersOrderId={shuttersOrderId} handleForward={handleForward} handleBack={handleBack} handlePageChange={handlePageChange} shutters={shutters} currentShutter={currentShutter} handleAddShutter={handleAddShutter} pdfContent={pdfContent} handleAdditionalOrderDataChange={handleAdditionalOrderDataChange}/>
                <Container className={classes.container} maxWidth="xl">
                    <ShutterDrawing page={page} handleDeleteShutter={handleDeleteShutter} handleRefreshSupplierConfig={handleRefreshSupplierConfig} handleResetShutter={handleResetShutter} handlePdfContent={handlePdfContent} shutter={currentShutter} handleCurrentShutterChange={handleCurrentShutterChange}/>
                </Container>
                </React.Fragment>
            : <LoadingPage/>}

            {supplierConfigList &&
                <SupplierPopup
                    supplierList={supplierConfigList}
                    open={isSupplierPopupOpen}
                    onClose={handleSupplierSelection}
                    onCancel={handleCancel}
                />
            }
        </React.Fragment>
    );
}