import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {withStyles} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import GetAppIcon from '@material-ui/icons/GetApp';
import FactoryForm from "../factoryForm";
import { CSVLink, CSVDownload } from "react-csv";
import {makeStyles} from "@material-ui/core";
import {supplierColour} from "./utils";


const useStyles = makeStyles((theme) => ({
    csvDownloadButton: {
        textDecoration: 'none !important'
    }
}));


const StyledMenuItem = withStyles((theme) => ({
    root: {
    },

}))(MenuItem);

export default function LabelCSV(props) {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const [data, setData] = React.useState([]);
    const headers = [
        { label: "jobId", key: "jobId" },
        { label: "itemNo", key: "itemNo" },
        { label: "name", key: "name" },
        { label: "hingePosition", key: "layout" },
        { label: "panelNo", key: "panel" },
        { label: "size", key: "size" },
        { label: "colour", key: "colour" },
        { label: "length", key: "length" },
        { label: "width", key: "width" },
        { label: "thickness", key: "thickness" },
        { label: "qty", key: "qty" },
    ];
    React.useEffect(() => {
        if(open && props.shutters && props.additionalOrderData.internalOrderId){
            let shutterData = props.shutters.filter(shutter => !shutter.deleted).map(shutter => {return props.getShutterData(shutter, 'factory')});
            let labels = shutterData.map(sd => sd.labels.map(l => ({...l, itemNo: sd._id, jobId: props.additionalOrderData.internalOrderId, colour: supplierColour(sd.config, sd.colour), size: `${sd.width}X${sd.height}` })));
            let d = labels.flat();
            setData(d)
        }
    }, [open, props.shutters, props.additionalOrderData]);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleDownload = () => {
        handleClose();
        props.handleClose();
    };
    React.useEffect(() => {
        props.handleClose();
    }, [open]);

    return (
        <div>
            <StyledMenuItem onClick={handleClickOpen}>
                {props.buttonLabel}
            </StyledMenuItem>
            <Dialog
                open={open}
                scroll="body"
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{props.buttonLabel}</DialogTitle>
                <DialogContent>
                    <FactoryForm {...props} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={<GetAppIcon/>}
                        disabled={!props.additionalOrderData.internalOrderId || props.additionalOrderData.internalOrderId === ""}
                    >
                        <React.Fragment>
                            <CSVLink
                                className={classes.csvDownloadButton}
                                style={{
                                    color: !props.additionalOrderData.internalOrderId || props.additionalOrderData.internalOrderId === ""? 'rgba(0, 0, 0, 0.87)' : '#fff'
                                }}
                                data={data}
                                headers={headers}
                                filename={`${props.shuttersOrderId}-all-labels.csv`}
                                onClick={() => {
                                    handleDownload()
                                }}
                            >
                                Download
                            </CSVLink>
                        </React.Fragment>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}