import axios from 'axios';

const knackAPI = axios.create({
    baseURL: 'https://api.knack.com/v1',
    headers: {
        "X-Knack-Application-Id": process.env.REACT_APP_X_KNACK_APPLICATION_ID,
        "X-Knack-REST-API-Key": process.env.REACT_APP_X_KNACK_REST_API_KEY
    }
});

const pdfAPI = axios.create({
    //SDA pdf generator lambda function stage dev/prod
    baseURL: process.env.REACT_APP_PDF_SERVICE_URL,
    headers: {
        "X-API-KEY": process.env.REACT_APP_PDF_SERVICE_API_KEY
    }
});

export {
    knackAPI,
    pdfAPI
};
