export const calculateMidrailPositions = (config, midrails, height, frameStyle, standardDeduction) => {
    try {
        let mp = [];
        let sd = standardDeduction ? (config.frame[frameStyle].standardDeduction * 2) : 0;
        let drop = (parseFloat(height) - sd);
        let value = 0;
        // console.log(drop)
        for (const n of [...Array(midrails).keys()]) {
            value += drop / (midrails + 1);
            mp.push(
                {name: `M${n + 1}`, value: parseFloat(value) % 1 !== 0 ? parseFloat(value).toFixed(1): parseFloat(value), critical: n === 0}
            )
        }
        // console.log('lllllllllllllll', JSON.stringify(mp))
        return mp;
    }
    catch (e) {
        // console.log(e);
        return []
    }
};

export const viewConfigOverrides = (view, config) => {
    return {
        ...config,
        ...(view==='customer' && {
            sideViewRelativePosition: 200,
            topViewRelativePosition: 200,
            canvasWidthExtension: 800,
            canvasHeightExtension: 500
        })
    }
};

export const sortMulti = (a, b, sortBy) => {
    let i = 0, result = 0;
    while(i < sortBy.length && result === 0) {
        result = sortBy[i].direction*(a[ sortBy[i].prop ].toString() < b[ sortBy[i].prop ].toString() ? -1 : (a[ sortBy[i].prop ].toString() > b[ sortBy[i].prop ].toString() ? 1 : 0));
        i++;
    }
    return result;
};

export const supplierColour = (config, colour) => {
    let c = config.colours.find(c => c.name === colour);
    return c.factoryName;
};

export const determineVisibility = (element, view, factory=null) => {
    if (!element.display.hasOwnProperty(view)) {
        return true // display all by default
    } else if (view === 'factory' && factory !== null) {
        return element.display[view].includes(factory)
    } else {
        return element.display[view]
    }
}
