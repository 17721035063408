import {Shutter} from "./shutter";
import {SVGElement} from "./svgElement";
import {svgCrop} from "./svgCrop";
import {determineVisibility} from "./order/utils";

const SVGHTML = (download) => { return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block">
    <style>
        .small { font: italic 13px sans-serif; }
        .heavy { font: bold 30px sans-serif; }
        .red { font-size: ${!download ? 30 : 35}px; font-family: Roboto, Helvetica, sans-serif; fill: #dc0000; font-weight: 500; letter-spacing: 0.4mm !important; }
        .red-large { font-size: ${!download ? 35: 40}px; font-family: Roboto, Helvetica sans-serif; fill: #dc0000; font-weight: 500; letter-spacing: 0.4mm !important; }
        .highlight_rect {fill: #ffee33}
        .highlight_path {fill: #ffee33}
    </style>  

        <g id="Layer_1">
           
        </g>

        <g>
            <pattern id="diagonalHatch" width="5" height="10" patternTransform="rotate(45 0 0)" patternUnits="userSpaceOnUse" class="highlight_rect">
                <line x1="0" y1="0" x2="0" y2="10" style="stroke:black; stroke-width:1" />
            </pattern>
            <pattern id="diagonalHatchHighlight" width="5" height="10" patternTransform="rotate(45 0 0)" patternUnits="userSpaceOnUse" class="highlight_rect">
                <rect height="23" width="14" class="highlight_rect"/>
                <line x1="0" y1="0" x2="0" y2="10" style="stroke:black; stroke-width:1" />
            </pattern>
        </g>

        <g id="Layer_2">
        </g>

        <g id="Layer_3">
        </g>

        <g id="Layer_4">
        </g>

        <g id="measurements">
        </g>
</svg>`};


function createTopMeasurementLineElement(id, svgElement, line, view, download) {
    let elements = [];
    let measurement = view !== 'customer' ? parseFloat((Math.round(line.measurement * 100) / 100).toFixed(1)) % 1 !== 0 ? parseFloat(Math.round(line.measurement * 100) / 100).toFixed(1) : parseInt(Math.round(line.measurement * 100) / 100) : Math.round(line.measurement * 100) / 100
    let measurementLength = measurement.toString().length;
    let centerLeft = (line.X1 + (line.X2-line.X1)/2) - (measurementLength*11.6);
    let centerRight = (line.X1 + (line.X2-line.X1)/2) + (measurementLength*11.6);
    elements.push(svgElement.draw(id, {
        X1: line.X1,
        Y1: line.Y1,
        X2: centerLeft,
        Y2: line.Y2,
        type: 'line'
    }));
    elements.push(svgElement.draw(id, {
        X1: centerRight,
        Y1: line.Y1,
        X2: line.X2,
        Y2: line.Y2,
        type: 'line'
    }));
    elements.push(svgElement.draw(id, {
        X1 : line.X1,
        Y1 : line.Y1-20,
        X2 : line.X1,
        Y2 : line.Y1+20,
        type: 'line'
    }));
    elements.push(svgElement.draw(id, {
        X1 : line.X2,
        Y1 : line.Y1-20,
        X2 : line.X2,
        Y2 : line.Y1+20,
        type: 'line'
    }));
    elements.push(svgElement.draw(id, {
        X1 : line.X1-10,
        Y1 : line.Y1,
        X2 : line.X1+10,
        Y2 : line.Y1,
        type: 'line',
        options: {
            strokeWidth: 3,
            transform: `rotate(135, ${line.X1}, ${line.Y1})`
        }
    }));
    elements.push(svgElement.draw(id, {
        X1 : line.X2-10,
        Y1 : line.Y1,
        X2 : line.X2+10,
        Y2 : line.Y1,
        type: 'line',
        options: {
            strokeWidth: 3,
            transform: `rotate(135, ${line.X2}, ${line.Y1})`
        }
    }));
    elements.push(svgElement.draw(id, {
        X: centerLeft + (download? 10 : 20),
        Y: line.Y1 + 10,
        text:  measurement,
        type: 'text',
        options: {
            cls: 'red'
        }
    }));
    return elements;
}

function createSideMeasurementLineElement(id, svgElement, line, view, download) {
    let elements = [];
    let measurement = view !== 'customer' ? parseFloat((Math.round(line.measurement * 100) / 100).toFixed(1)) % 1 !== 0 ? parseFloat(Math.round(line.measurement * 100) / 100).toFixed(1) : parseInt(Math.round(line.measurement * 100) / 100) : Math.round(line.measurement * 100) / 100

    let measurementLength = measurement.toString().length;
    let centerTop = (line.Y1 + (line.Y2-line.Y1)/2) - (measurementLength*11.6);
    let centerBottom = (line.Y1 + (line.Y2-line.Y1)/2) + (measurementLength*11.6);
    elements.push(svgElement.draw(id, {
        X1: line.X1,
        Y1: line.Y1,
        X2: line.X2,
        Y2: centerTop,
        type: 'line'
    }));
    elements.push(svgElement.draw(id, {
        X1: line.X1,
        Y1: centerBottom,
        X2: line.X2,
        Y2: line.Y2,
        type: 'line'
    }));
    elements.push(svgElement.draw(id, {
        X1 : line.X1-20,
        Y1 : line.Y1,
        X2 : line.X1+20,
        Y2 : line.Y1,
        type: 'line'
    }));
    elements.push(svgElement.draw(id, {
        X1 : line.X1-20,
        Y1 : line.Y2,
        X2 : line.X2+20,
        Y2 : line.Y2,
        type: 'line'
    }));
    elements.push(svgElement.draw(id, {
        X1 : line.X1-10,
        Y1 : line.Y1,
        X2 : line.X1+10,
        Y2 : line.Y1,
        type: 'line',
        options: {
            strokeWidth: 3,
            transform: `rotate(135, ${line.X1}, ${line.Y1})`
        }
    }));
    elements.push(svgElement.draw(id, {
        X1 : line.X2-10,
        Y1 : line.Y2,
        X2 : line.X2+10,
        Y2 : line.Y2,
        type: 'line',
        options: {
            strokeWidth: 3,
            transform: `rotate(135, ${line.X2}, ${line.Y2})`
        }
    }));
    elements.push(svgElement.draw(id, {
        X: line.X1 + 20,
        Y: centerBottom+ (download? 10 : 10),
        text:  measurement,
        type: 'text',
        options: {
            cls: 'red',
            transform: `rotate(270, ${line.X1}, ${centerBottom})`
        }
    }));
    return elements;
}

function drawControlScrew(id, svgElement, config, panel, controlScrew, view) {
    let elements = [];
    elements.push(svgElement.draw(id, controlScrew));
    elements.push(svgElement.draw(id, {
        X1: controlScrew.CX,
        Y1: controlScrew.CY,
        X2: controlScrew.CX - config.controlScrewRadius,
        Y2: controlScrew.CY + config.controlScrewRadius,
        type: 'line'
    }));
    elements.push(svgElement.draw(id, {
        X1: controlScrew.CX,
        Y1: controlScrew.CY,
        X2: controlScrew.CX - config.controlScrewRadius,
        Y2: controlScrew.CY - config.controlScrewRadius,
        type: 'line'
    }));
    elements.push(svgElement.draw(id, {
        X1: controlScrew.CX,
        Y1: controlScrew.CY,
        X2: controlScrew.CX + config.controlScrewRadius,
        Y2: controlScrew.CY - config.controlScrewRadius,
        type: 'line'
    }));
    elements.push(svgElement.draw(id, {
        X1: controlScrew.CX,
        Y1: controlScrew.CY,
        X2: controlScrew.CX + config.controlScrewRadius,
        Y2: controlScrew.CY + config.controlScrewRadius,
        type: 'line'
    }));
    if(controlScrew.hasOwnProperty('text') && view !== 'customer') {
        elements.push(svgElement.draw(id, {
            X: controlScrew.TX,
            Y: controlScrew.TY,
            text: controlScrew.text,
            type: 'text',
            options: {
                cls: 'red-large'
            }
        }));
    }
    return elements

}
const draw = (shutter, drawing, config, view, download) => {
    let div = document.createElement("div");
    //console.log(div);
    div.innerHTML = SVGHTML(download);
    let svg = div.getElementsByTagName('svg')[0];
    svg.classList.add('svg-class-name');
    // svg.setAttribute('style', 'width: 100%');

    //console.log(parseInt(shutter.width)+config.canvasWidthExtension)
    // svg.setAttribute('width', parseInt(shutter.width)+config.canvasWidthExtension);
    // svg.setAttribute('height', parseInt(shutter.height)+config.canvasHeightExtension);
    //svg.setAttribute('viewBox', `${offsetLeft} ${offsetTop} ${offsetWidth+1000} ${offsetHeight+500}`);

    // drawSVG(parseFloat(w), parseFloat(d), configuration, midRailPositions);
    //console.log(drawing)

    let svgElement = new SVGElement();
    let layer1 = svg.getElementById('Layer_1');
    drawing.views.frontView.frames.map((frame, idx) => {
        layer1.appendChild(svgElement.draw(`frame-${idx}`, frame));
    });
    let layer2 = svg.getElementById('Layer_2');
    for(const panel of drawing.views.frontView.panels){
        if(panel.type === 'panel') {
            panel.zones.map((zone, idx) => {
                layer2.appendChild(svgElement.draw(`panel-${panel.id}-zone-${idx}`, zone));
            });
            layer2.appendChild(svgElement.draw(`panel-outer-${panel.id}`, panel.outerRect));
            layer2.appendChild(svgElement.draw(`panel-left-stile-${panel.id}`, panel.stile.left));
            layer2.appendChild(svgElement.draw(`panel-right-stile-${panel.id}`, panel.stile.right));
            layer2.appendChild(svgElement.draw(`panel-rails-top-${panel.id}`, panel.rails.top));
            layer2.appendChild(svgElement.draw(`panel-rails-bottom-${panel.id}`, panel.rails.bottom));
            panel.rails.mid.map((midRail, idx) => {
                layer2.appendChild(svgElement.draw(`panel-${panel.id}-rails-mid-${idx}`, midRail));
            });
            panel.louvers.map((louver, idx) => {
                layer2.appendChild(svgElement.draw(`panel-${panel.id}-louver-${idx}`, louver));
            });
            panel.hinges.map((hinge, idx) => {
                layer2.appendChild(svgElement.draw(`panel-${panel.id}-hinge-${idx}`, hinge.line));
            });
            panel.controlScrews.map((controlScrew, idx) => {
                for(const ele of drawControlScrew(`panel-${panel.id}-control-screw-${idx}`, svgElement, config, panel, controlScrew, view)){
                    layer2.appendChild(ele);
                }
            });
            panel.tiltRods.map((tiltRod, idx) => {
                layer2.appendChild(svgElement.draw(`panel-${panel.id}-tilt-rod-${idx}`, tiltRod));
            });

        }
        else if(panel.type === 'tPost'){
            layer2.appendChild(svgElement.draw(`panel-${panel.id}-t-post`, panel.outerRect));
        }
        else if(panel.type === 'dMold'){
            layer2.appendChild(svgElement.draw(`panel-${panel.id}-d-mold`, panel.line));
        }
    }
    //side view
    let layer3 = svg.getElementById('Layer_3');
    layer3.appendChild(svgElement.draw('side-view-panel-rect', drawing.views.sideView.outerFrame));
    layer3.appendChild(svgElement.draw('side-view-frame-path-right', drawing.views.sideView.frame.top));
    layer3.appendChild(svgElement.draw('side-view-frame-path-left', drawing.views.sideView.frame.bottom));
    layer3.appendChild(svgElement.draw('side-view-panel-top-rail', drawing.views.sideView.panel.rails.top));
    layer3.appendChild(svgElement.draw('side-view-panel-bottom-rail', drawing.views.sideView.panel.rails.bottom));
    drawing.views.sideView.panel.rails.mid.map((midRail, idx) => {
        layer3.appendChild(svgElement.draw(`side-view-panel-mid-rail-${idx}`, midRail));
    });
    drawing.views.sideView.panel.louvers.map((louver, idx) => {
        let louverEllipse = svgElement.draw(`side-view-panel-louver-${idx}`, louver);
        let louverCenter = svgElement.draw(`side-view-panel-louver-${idx}`, {
            ...louver,
            R: 1,
            type: 'circle'
        });
        layer3.appendChild(louverEllipse);
        layer3.appendChild(louverCenter);
    });

    //top view
    let layer4 = svg.getElementById('Layer_4');
    layer4.appendChild(svgElement.draw('top-view-panel-rect', drawing.views.topView.outerFrame));
    layer4.appendChild(svgElement.draw('top-view-frame-path-right', drawing.views.topView.frame.right));
    layer4.appendChild(svgElement.draw('top-view-frame-path-left', drawing.views.topView.frame.left));
    drawing.views.topView.panels.rects.map((panel, idx) => {
        layer4.appendChild(svgElement.drawShape(`top-view-panel-rect-${idx}`, panel,  'rect'))
    });
    drawing.views.topView.panels.paths.map((path, idx) => {
        layer4.appendChild(svgElement.draw(`top-view-panel-path-${idx}`, path))
    });


    let measurements = svg.getElementById('measurements');
    drawing.measurements.top.filter(m => determineVisibility(m, view, config.name.toLowerCase())).map((measurement, idx) => {
        let lines = createTopMeasurementLineElement(`top-measurement-${idx}`, svgElement, measurement, view, download);
        lines.map(line => measurements.appendChild(line));
    });
    drawing.measurements.side.filter(m => determineVisibility(m, view, config.name.toLowerCase())).map((measurement, idx) => {
        let lines = createSideMeasurementLineElement(`side-measurement-${idx}`, svgElement, measurement, view, download);
        lines.map(line => measurements.appendChild(line));
    });

    drawing.views.frontView.panels.filter(p => p.type === 'panel')[0].louverCounts.map((louverCount, idx) => {
        measurements.appendChild(svgElement.draw(`louverCount-measurement-${idx}`, {...louverCount, type: 'text', options: {cls: 'red-large'}}));
    });

    drawing.measurements.miscellaneous.filter(m => determineVisibility(m, view, config.name.toLowerCase())).map((measurement, idx) => {
        measurements.appendChild(svgElement.draw(`miscellaneous-measurement-${idx}`, measurement));
    });
    //console.log('final')
    //console.log(svg)
    svg = svgCrop(svg, drawing);
    return svg

};


// toOfMidrail + Topofshutter - louver/2 - minimum_rail height/76.2 how many differenc added to top rail
// total shutter height - 2*minimum_rail - 2*44.5 - midrail height/76.2 total
// Sum midrail
// User oninput
//louver pin spacing





export {
    draw
}

