import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import HistoryIcon from '@material-ui/icons/History';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import UndoIcon from '@material-ui/icons/Undo';
import RemoveIcon from '@material-ui/icons/Remove';
import EditIcon from '@material-ui/icons/Edit';


const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
    },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
    },
    submitButton: {
        margin: theme.spacing(1),
    },
    selectLabel: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top left'
    },
    select: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),

    },
    buttonDiv: {
        textAlign: 'right'
    },
    configHeading: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
        fontWeight: 600,
        fontSize: '1.0625rem',
        lineHeight: 1.6,
        color: 'rgb(23, 43, 77)'
    }

}));


export default function OptionsMenus(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (callback) => {
        setAnchorEl(null);
        callback();
    };

    return (
        <div>
            <div className={classes.buttonDiv}>
                {!props.advanced ?
                    <Button
                        aria-controls="options-menu"
                        aria-haspopup="true"
                        color="primary"
                        size="small"
                        onClick={handleClick}
                    >
                        Options <ArrowDropDownIcon/>
                    </Button> :
                    <Button
                        color="primary"
                        size="small"
                        onClick={props.handleAdvancedClick}
                    >
                        <RemoveIcon/> Advanced
                    </Button>
                }
            </div>
            <StyledMenu
                id="options-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {/*<StyledMenuItem onClick={() => handleMenuClick(props.handleEditClick)}>*/}
                {/*    <ListItemIcon>*/}
                {/*        <EditIcon fontSize="small" />*/}
                {/*    </ListItemIcon>*/}
                {/*    <ListItemText primary="Edit" />*/}
                {/*</StyledMenuItem>*/}
                <StyledMenuItem onClick={() => handleMenuClick(props.handleAdvancedClick)}>
                    <ListItemIcon>
                        <SettingsIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Advanced" />
                </StyledMenuItem>
                <StyledMenuItem disabled={!props.shutter.original} onClick={() => handleMenuClick(props.handleResetShutter)}>
                    <ListItemIcon>
                        <UndoIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Reset" />
                </StyledMenuItem>
                <StyledMenuItem disabled={!props.shutter.original} onClick={() => handleMenuClick(props.handleRefreshSupplierConfig)}>
                    <ListItemIcon>
                        <UndoIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Refresh Supplier Configuration" />
                </StyledMenuItem>
                <StyledMenuItem onClick={() => handleMenuClick(props.handleDeleteShutter)}>
                    <ListItemIcon>
                        {props.shutter.deleted? <HistoryIcon fontSize="small" /> : <DeleteIcon fontSize="small" />}
                    </ListItemIcon>
                    <ListItemText primary={props.shutter.deleted? 'Undo Delete' : 'Delete'} />
                </StyledMenuItem>
            </StyledMenu>
        </div>
    );
}