import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import { FormControl, FormControlLabel  } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import RadioField from "./radioField";
import {calculateMidrailPositions} from "../utils";
const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    formControlLabel: {
        fontSize: '16px',
        transform: 'scale(0.75)'
    }

}));

export default function MidRailFields(props) {
    const classes = useStyles();
    const [midrails, setMidrails] =  React.useState(props.midrails);
    const [midRailPositions, setMidRailPositions] =  React.useState(props.midrailPositions);
    const [newMidRailPositions, setNewMidRailPositions] = React.useState(props.midrailPositions === 'Evenly Spaced' ? [] : props.midrailPositions);
    const [drawingPositions, setDrawingPositions] =  React.useState(null);
    const handleMidrailsChange = (event) => {
        setMidrails(parseInt(event.target.value));
    };
    const [errorList, setErrorList] = React.useState([]);

    const handleMidRailPositionsChange = (event) => {
        let current = newMidRailPositions.filter(n => n.name === event.target.name);
        let n = {
            name: event.target.name,
            value:  parseFloat(event.target.value),
            critical: false
        };
        if(current.length > 0){
            n = {
                ...current[0],
                value: parseFloat(event.target.value)
            }
        }
        let newP = [...newMidRailPositions.filter(n => n.name !== event.target.name), n].sort((a, b) => (a.name > b.name) ? 1 : -1);
        let prev = null;
        newP.map(function (p) {
            if(prev && parseFloat(prev.value) >= parseFloat(p.value)){
                setErrorList([...errorList.filter(n => n.name !== p.name), {name: p.name, message: `Position cannot be less than ${prev.name}`}])
            }
            else if(prev){
                setErrorList([...errorList.filter(n => n.message !== `Position cannot be less than ${prev.name}`)])
            }
            prev = p
        });
        newP.map(function (p) {
           if(!p.value){
               setErrorList([...errorList.filter(n => n.name !== p.name), {name: p.name, message: `Required`}])
           }
           else{
               setErrorList([...errorList.filter(n => n.name === p.name && n.message !== `Required`)])
           }
        });
        setNewMidRailPositions(newP)
    };
    const handleCriticalChange = (event) => {
        let current = newMidRailPositions.filter(n => n.name === event.target.name);
        let n = {
            name: event.target.name,
            value:  0,
            critical: event.target.checked
        };
        if(current.length > 0){
            n = {
                ...current[0],
                critical: event.target.checked
            }
        }
        setNewMidRailPositions([...newMidRailPositions.filter(n => n.name !== event.target.name).map(n => ({...n, critical:false})), n])
    };
    React.useEffect(() => {
        setMidrails(props.midrails);
        setMidRailPositions("Evenly Spaced");
    }, [props.midrails]);

    React.useEffect(() => {
        setMidRailPositions(props.midrailPositions);
    }, [props.midrailPositions]);

    React.useEffect( () => {
        setNewMidRailPositions(props.midrailPositions=== 'Evenly Spaced' ? props.drawing ? props.drawing.meta.midRailPos : props.midrailPositions : props.midrailPositions);
    }, [props.drawing]);

    React.useEffect( () => {
        //console.log(props)
        if(props.drawing === null && props.formType === 'Add') {
            let t = calculateMidrailPositions(props.config, props.midrails, props.height, props.frameStyle, props.standardDeduction);
            setNewMidRailPositions(t);
        }
    }, [props.drawing, props.config, props.midrails, props.height, props.frameStyle, props.standardDeduction]);

    React.useEffect(() => {
        setMidRailPositions(Array.isArray(newMidRailPositions) && newMidRailPositions.length > 0 ? newMidRailPositions.sort((a, b) => (a.name > b.name) ? 1 : -1): midRailPositions);
    }, [newMidRailPositions]);

    React.useEffect(() => {
        if(midrails !== props.midrails){
            setErrorList([])
            props.onChange('midrails', midrails);
        }
        if(Array.isArray(midRailPositions) && JSON.stringify(midRailPositions) !== JSON.stringify(props.midrailPositions)){
            props.onChange('midrailPositions', midRailPositions);
        }
        if(!Array.isArray(midRailPositions) && midRailPositions !== props.midrailPositions){
            props.onChange('midrailPositions', midRailPositions);
        }
    }, [midRailPositions, midrails]);

    const midRailAdjustedPosition = (p) => {
        if(props.drawing && Array.isArray(newMidRailPositions) && midRailPositions !== 'Evenly Spaced') {
            let input = newMidRailPositions.filter(pw => pw.name === p.name).length > 0 ? newMidRailPositions.filter(pw => pw.name === p.name)[0].value : null;
            let drawingInput = props.drawing.meta.midRailPos.filter(pw => pw.name === p.name).length > 0 ? props.drawing.meta.midRailPos.filter(pw => pw.name === p.name)[0].value : null;
            if (drawingInput && parseFloat(input) !== parseFloat(drawingInput)) {
               return parseFloat(drawingInput)
            }
        }
        return false
    };
    React.useEffect( () => {
        if(props.drawing) {
           setDrawingPositions(props.drawing.meta.midRailPos)
        }
    }, [props.drawing]);

    const HelperText = (p) => {
        let drawingInput = midRailAdjustedPosition(p);
        if(drawingInput) {
            return (
                <React.Fragment>
                    <Typography variant="caption" paragraph={false}>Midrail cannot be critical at this position. Adjusted to:
                        <Typography variant="caption" paragraph={false} color="secondary"> {drawingInput}</Typography>
                    </Typography>
                </React.Fragment>
            )
        }
        return null
    };

    return (
            <Grid container spacing={0}>
                <Grid container item xs={12}>
                    <RadioField disabled={props.disabled} options={[0,1,2]} name="midrails" value={midrails} onChange={handleMidrailsChange}/>
                </Grid>
                {midrails && midrails > 0?
                    <React.Fragment>
                        {[...Array(parseInt(midrails)).keys()].map(n =>
                            <Grid container item xs={12} key={`mid-rail-pos-${n}`}>
                                <Grid container item xs={8}>
                                    <TextField
                                        key={`mid-rail-pos-${n}`}
                                        id={`mid-rail-pos-${n}`}
                                        label={`M${n+1}`}
                                        name={`M${n+1}`}
                                        variant="outlined"
                                        type="number"
                                        value={Array.isArray(newMidRailPositions) && newMidRailPositions.filter(pw => pw.name === `M${n+1}`).length > 0 ? newMidRailPositions.filter(pw => pw.name === `M${n+1}`)[0].value : ""}
                                        error={errorList.filter(pw => pw.name === `M${n+1}`).length > 0}
                                        onFocus={() => props.handleMetaChange(`M${n+1}`, true)}
                                        onBlur={() => props.handleMetaChange(`M${n+1}`, false)}
                                        fullWidth
                                        disabled={props.disabled}
                                        InputProps={{ inputProps: { min: 0, max: 6000 } }}
                                        onChange={handleMidRailPositionsChange}
                                        helperText={errorList.filter(pw => pw.name === `M${n+1}`).length === 0 ? <HelperText name={`M${n+1}`} />:errorList.find(pw => pw.name === `M${n+1}`).message}
                                    />
                                </Grid>
                                <Grid container item xs={4} justify="center">
                                    <FormControlLabel
                                        name="mid-rail-position-options"
                                        value="bottom"
                                        control={<Checkbox
                                            color="primary"
                                            name={`M${n+1}`}
                                            checked={Array.isArray(newMidRailPositions) && newMidRailPositions.filter(pw => pw.name === `M${n+1}`).length > 0 && !(Array.isArray(drawingPositions) && drawingPositions.filter(pw => pw.name === `M${n+1}`).length > 0 && Array.isArray(newMidRailPositions) && newMidRailPositions.length === 1 && newMidRailPositions.filter(pw => pw.name === `M${n+1}`).length > 0 && parseFloat(drawingPositions.find(pw => pw.name === `M${n+1}`).value) !==  parseFloat(newMidRailPositions.find(pw => pw.name === `M${n+1}`).value)) ? newMidRailPositions.find(pw => pw.name === `M${n+1}`).critical : false}
                                            disabled={props.disabled? true : (Array.isArray(drawingPositions) && drawingPositions.filter(pw => pw.name === `M${n+1}`).length > 0 && Array.isArray(newMidRailPositions) && newMidRailPositions.length === 1 && newMidRailPositions.filter(pw => pw.name === `M${n+1}`).length > 0 && parseFloat(drawingPositions.find(pw => pw.name === `M${n+1}`).value) !==  parseFloat(newMidRailPositions.find(pw => pw.name === `M${n+1}`).value))}
                                            onChange={handleCriticalChange}
                                        />}
                                        label={<Typography className={classes.formControlLabel}>Critical</Typography>}
                                        labelPlacement="bottom"
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </React.Fragment>
                :null}
            </Grid>
    )
}
