import * as React from 'react';
import { Formik, Form, Field} from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
    },
    submitButton: {
        margin: theme.spacing(1),
    },
    selectLabel: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top left'
    },
    select: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),

    },
    buttonDiv: {
        textAlign: 'right'
    },
    configHeading: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
        fontWeight: 600,
        fontSize: '1.0625rem',
        lineHeight: 1.6,
        color: 'rgb(23, 43, 77)'
    }

}));


const Schema = Yup.object().shape({
    louverWidth: Yup.number().positive().required('Required'),
    louverDistance: Yup.number().positive().required('Required'),
    topRailMinWidth: Yup.number().positive().required('Required'),
    topRailMaxWidth: Yup.number().positive().required('Required'),
    bottomRailMinWidth: Yup.number().positive().required('Required'),
    bottomRailMaxWidth: Yup.number().positive().required('Required')
});

export default function ConfigForm(props) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Formik
                enableReinitialize
                initialValues={{
                    louverWidth: props.shutter.config.louverWidth,
                    louverDistance: props.shutter.config.louverDistance,
                    topRailMinWidth: props.shutter.config.topRailMinWidth,
                    topRailMaxWidth: props.shutter.config.topRailMaxWidth,
                    bottomRailMinWidth: props.shutter.config.bottomRailMinWidth,
                    bottomRailMaxWidth: props.shutter.config.bottomRailMaxWidth,
                }}
                validationSchema={Schema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                }}
            >
                {({ values, handleChange, setFieldValue, setTouched, errors, submitForm, isSubmitting }) => (
                    <Form className={classes.root} autoComplete="off">
                        {errors.name ? (
                            <div>{errors}</div>
                        ) : null}
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.configHeading}>Louver</Typography>
                            </Grid>
                            <Grid container item xs={6}>
                                <Field
                                    component={TextField}
                                    name="louverWidth"
                                    type="number"
                                    label="Width"
                                    variant="outlined"
                                    margin="none"
                                    size="small"
                                    className={classes.textField}
                                    disabled={!props.editMode}
                                    inputProps={{
                                        step: "0.1"
                                    }}
                                    fullWidth
                                    onChange={e => {
                                        handleChange(e);
                                        props.handleCurrentShutterChange({
                                            ...props.shutter,
                                            config: {
                                                ...props.shutter.config,
                                                louverWidth: parseFloat(e.target.value)
                                            }
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid container item xs={6}>
                                <Field
                                    component={TextField}
                                    name="louverDistance"
                                    type="number"
                                    step="0.1"
                                    label="Distance"
                                    margin="none"
                                    variant="outlined"
                                    size="small"
                                    disabled={!props.editMode}
                                    inputProps={{
                                        step: "0.1"
                                    }}
                                    fullWidth
                                    onChange={e => {
                                        handleChange(e);
                                        props.handleCurrentShutterChange({
                                            ...props.shutter,
                                            config: {
                                                ...props.shutter.config,
                                                louverDistance: parseFloat(e.target.value)
                                            }
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.configHeading}>Top Rail</Typography>
                            </Grid>
                            <Grid container item xs={6}>
                                <Field
                                    component={TextField}
                                    name="topRailMinWidth"
                                    type="number"
                                    label="Min"
                                    variant="outlined"
                                    margin="none"
                                    size="small"
                                    className={classes.textField}
                                    disabled={!props.editMode}
                                    inputProps={{
                                        step: "0.1"
                                    }}
                                    fullWidth
                                    onChange={e => {
                                        handleChange(e);
                                        props.handleCurrentShutterChange({
                                            ...props.shutter,
                                            config: {
                                                ...props.shutter.config,
                                                topRailMinWidth: parseFloat(e.target.value)
                                            }
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid container item xs={6}>
                                <Field
                                    component={TextField}
                                    name="topRailMaxWidth"
                                    type="number"
                                    step="0.1"
                                    label="Max"
                                    margin="none"
                                    variant="outlined"
                                    size="small"
                                    inputProps={{
                                        step: "0.1"
                                    }}
                                    fullWidth
                                    disabled={!props.editMode}
                                    onChange={e => {
                                        handleChange(e);
                                        props.handleCurrentShutterChange({
                                            ...props.shutter,
                                            config: {
                                                ...props.shutter.config,
                                                topRailMaxWidth: parseFloat(e.target.value)
                                            }
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.configHeading}>Bottom Rail</Typography>
                            </Grid>
                            <Grid container item xs={6}>
                                <Field
                                    component={TextField}
                                    name="bottomRailMinWidth"
                                    type="number"
                                    label="Min"
                                    variant="outlined"
                                    margin="none"
                                    size="small"
                                    className={classes.textField}
                                    disabled={!props.editMode}
                                    inputProps={{
                                        step: "0.1"
                                    }}
                                    fullWidth
                                    onChange={e => {
                                        handleChange(e);
                                        props.handleCurrentShutterChange({
                                            ...props.shutter,
                                            config: {
                                                ...props.shutter.config,
                                                bottomRailMinWidth: parseFloat(e.target.value)
                                            }
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid container item xs={6}>
                                <Field
                                    component={TextField}
                                    name="bottomRailMaxWidth"
                                    type="number"
                                    step="0.1"
                                    label="Max"
                                    margin="none"
                                    variant="outlined"
                                    size="small"
                                    disabled={!props.editMode}
                                    inputProps={{
                                        step: "0.1"
                                    }}
                                    fullWidth
                                    onChange={e => {
                                        handleChange(e);
                                        props.handleCurrentShutterChange({
                                            ...props.shutter,
                                            config: {
                                                ...props.shutter.config,
                                                bottomRailMaxWidth: parseFloat(e.target.value)
                                            }
                                        });
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    );
}