import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    formControl:{
        margin: theme.spacing(1),
    },

}));

export default function RadioField(props) {
    const classes = useStyles();

    const handleChange = (event) => {
        props.onChange(event)
    };

    return (
        <div>
            <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Midrails</FormLabel>
                <RadioGroup row aria-label="gender" name={props.name} value={props.value} onChange={handleChange}>
                    {props.options.map(option =>
                        <FormControlLabel key={option} value={option} control={<Radio disabled={props.disabled} color="primary"/>} label={option} />
                    )}
                </RadioGroup>
            </FormControl>
        </div>

    );
}
